@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.checkout-product {
	+ .checkout-product {
		margin-top: 1em;
	}


	.column.column-thumbnail {
		@include breakpoint(desktop) {
			min-width: toBase(80);
			max-width: toBase(80);
		}
	}

	dl {
		margin: 0;

		dt {
			display: inline-block;
			font-weight: bold;
			margin-right: 0.5em;
		}

		dd {
			margin-left: 0;
			display: inline;

			&:after {
				content: "";
				display: block;
			}
		}
	}

	.column.column-price {
		font-weight: bold;

		@include breakpoint(desktop) {
			min-width: toBase(100);
			max-width: toBase(100);
			@include leftright(text-align, text-align, right, left);
		}
	}
	>.column.balanced.column-text {
		overflow: hidden;
	}
}

.checkout-summary-expanded {
	margin-top: 1em;
	cursor: auto;
	.collapsable-simple-text {
		display: flex;
		justify-content: flex-end;
		color: $shaded-blue;
		
	}
	.collapsable-simple-close {
		display: none;
	}
	&.toggle-on {
		.collapsable-simple-text {
			color: $orange;
		}
		.collapsable-simple-open {
			display: none
		}
		.collapsable-simple-close {
			display: flex;
		}
	}
}