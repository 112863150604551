@import 'style/config';
@import "node_modules/sensible/mediaqueries";
.panel-burger,
.panel {
	border: 1px solid $light-grey-blue;

	@include breakpoint(desktop) {
		background-color: $white;
	}

	.panel-trigger {
		padding: 1em * $mobile-desktop-factor;

		@include breakpoint(desktop) {
			padding: 1em;
		}

		h1,
		h2,
		h3 {
			margin: 0;
		}

		svg {
			fill: $base-font-color;
			width: 2em * $mobile-desktop-factor;
			height: 2em * $mobile-desktop-factor;

			@include breakpoint(desktop) {
				width: 2em;
				height: 2em;
			}
		}
		
		@include breakpoint(desktop) {
			&:hover {
				svg {
					fill: $orange;
				}
			}
		}
	}

	.panel-content {
		display: none;
		position: absolute;
		background-color: $white;
	}

	&.toggle-on {
		.with-overlay & {
			z-index: 1;
		}

		> .panel-content {
			display: block;
		}
	}

	&.two-line-title {
		> .panel-trigger {
			display: flex;
			align-items: center;
			> span {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				> span {
					font-size: 1em;
					line-height: normal;
					width: 100%;
				}
				> svg {
					position: static !important;
					width: auto;
					@include breakpoint(desktop) {
						margin-right: 10px;
						width: 2em;
					}
				}
			}
		}
	}
}

.with-overlay .panel-burger.toggle-on {
	z-index: 10001;
}