@import 'style/config';

.product-downloads {

  .collapsible2 {

    &:first-of-type{
      padding-top: 0.0em;
    }

    &:not(:last-of-type){
      border-bottom: 1px dashed $mid-grey;
    }
    &:not(:first-of-type) {
      .collapsible2-title {
        h3 {
          margin-top: 0em;
        }
      }
    }
    .collapsible2-title {
      h3 {
        margin-bottom: 0em;
      }
    }
    .collapsible2-content {
    }
    .documents-list {
      padding-top: 1em;
    }
  }

  .pdhi-document {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.2em 0;

    @include breakpoint(mobile){
      padding: 0.4em 0;
    }

    &:not(:last-of-type) {
      border-bottom: 1px dashed lighten($mid-grey, 15%);
    }

    .pdhi-document-info {
      display: flex;
      align-items: flex-start;
      overflow: hidden;

      .form-input-checkbox {
        padding: 1px 0 0 0;
        cursor: pointer;
        label span {
          cursor: pointer;
        }
      }
      .pdhi-document-icon-text {
        display: flex;
        align-items: flex-start;
        overflow: hidden;
        label {
          overflow: hidden;
          div {
            overflow: hidden;
            text-overflow: ellipsis;
            a {
              color: $base-font-color;
            }
          }
        }
      }

      .pdhi-document-checkbox-hide {
        visibility: hidden;
      }

      .pdhi-document-type {
        display: flex;
        align-items: center;
        padding-bottom: 0.15em;
        padding-left: 1em;
        padding-right: 1em;
        @include breakpoint(tablet) {
          padding-left: 0.6em;
          padding-right: 0.6em;
        }
        @include breakpoint(mobile) {
          padding-left: 0.3em;
          padding-right: 0.5em;
        }
        svg {
          width: 1.05em;
          height: 1.3em;
        }
      }
    }
    .pdhi-document-download {
      display: flex;
      font-weight: bold;
      padding-left: 1em;
      white-space: nowrap;
    }

  }

}