@use 'sass:math';
@import 'style/config';
@import 'style/base/10.mixins';
@import "node_modules/sensible/mediaqueries";

.teaser-intro {
	
	a {
		text-decoration: none;	
		color: $base-font-color;
	}

	.teaser-intro-title {

		margin: #{toMobile(20) - 0.1em - math.div(($base-line-height - 1em),2)} 0 0;

		@include breakpoint(desktop) {
			margin: #{toBase(20) - 0.1em - math.div(($base-line-height - 1em), 2)} 0 0;
		}
	}

	p {
		margin: 0;
	}
}