.joblist-filters__list {
  margin-bottom: 1rem;
  border: 2px solid $dark-grey;

  &-filterbox {
    border-bottom: 2px solid $dark-grey;

    &:last-child {
      border-bottom: none;
    }

    &.dropdown {
      .dropdown__trigger {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        cursor: pointer;

        &-name {
          font-size: 20px;
          font-weight: bold;
          color: $orange;
        }

        &-arrow {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 15px 10px 0 10px;
          border-color: $orange transparent transparent transparent;
          transition: .5s transform ease-out;
        }

        &.active {
          .dropdown__trigger-arrow {
            transform: rotate(-180deg);
          }
        }
      }

      .dropdown__content {
        margin: 0;
        padding: 0 10px 0 20px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-out;
      }
    }
  }

  &-filter {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 1.7;

    &:last-child {
      padding-bottom: 1rem;
    }

    .checkbox {
      display: flex;
      align-items: center;
      cursor: pointer;

      input[type="checkbox"] {
        display: none;
      }

      &__box {
        width: 20px;
        height: 20px;
        border: 2px solid $orange;
        display: inline-block;
        position: relative;
        transition: border-color 0.1s;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 12px;
          height: 12px;
          background-color: $orange;
          opacity: 0;
          transition: opacity 0.1s;
          transform: translate(-50%, -50%);
        }
      }

      input[type="checkbox"]:checked + .checkbox__box::after {
        opacity: 1;
      }

      &__label {
        margin-left: 8px;
        font-size: 16px;
      }
    }
  }
}

.joblist-filters {
  &__toggle {
    position: relative;

    button {
      width: 100%;
      height: 3.75rem;
      text-align: left;
    }

    &-icon {
      display: block;
      position: absolute;
      top: 1.37rem;
      width: 1rem;
      height: 1rem;
      left: auto;
      right: 1rem;
      svg {
        fill: white;
      }
    }
  }
}
