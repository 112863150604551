@import 'style/config';
@import 'style/base/10.mixins';

.product-video-wrap {
  padding-bottom: 1em;
  &:not(:first-child) {
    padding-top: 1em;
    border-top: 1px solid #bcbdc0;
  }
}

.product-video {
  display: flex;
  width: 100%;

  @include breakpoint(tablet) {
    flex-direction: column;
  }

  .product-video-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding-top: 0.5em;

    @include breakpoint(desktop) {
      padding-left: 1em;
      justify-content: space-between;
    }

    .product-video-labels {
      p {
        margin-top: 0;
      }
      h3 {
        margin-bottom: 0;
      }
    }

    .product-video-nav {
      display: flex;
      justify-content: flex-end;
      margin-top: 0.5em;
      @include breakpoint(tablet) {
        flex-direction: column;
        justify-content: flex-start;
      }

      a {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: bold;

        &:not(:first-child) {

          @include breakpoint(desktop) {
            margin-left: 10em;
          }
          @include breakpoint(tablet) {
            margin-top: 0.2em;
          }
        }

        svg {
          margin-right: 0.5em;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
