@import 'style/config';
@import "node_modules/sensible/mediaqueries";

.collapsable {
	.collapsable-trigger {

		h1,
		h2,
		h3 {
			margin: 0;
		}

		svg {
			fill: $base-font-color;
			width: 1em;
			height: 1em;
		}
	}

	.collapsable-content {
		max-height: 0;
		transition: max-height .5s;
		overflow: hidden;
	}

	&.toggle-on {

		.collapsable-trigger svg {
			transform: rotate(180deg);
			fill: $orange;
		}
		
		.collapsable-content {
			max-height: 100000px;
		}
	}
}