@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.cart-actions {
	.grid {
		justify-content: flex-end;
		flex-pack: end;
	}

	.cart-action-group {
		margin-top: 1.25em;
		>* {
			margin-top: 0.65em;
			>div {
				margin-top: 0.65em;
			}
		}
		>:nth-child(2) {
			margin-top: 0.35em;
		}
		>:nth-child(1) {
			font-weight: bold;
		}

		.save-and-close,
		.checkout {
			button, a {
				width: 100%;
				text-align: center;
			}
		}
		.checkout {
			a {
				background-color: $orange;
			}
		}
		&.download-folder .error {
			color: $red;
		}
	}

	.cart-actions-grid > .column {
		margin-top: 0;
	}

	.cart-actions-grid.grid {
		padding: 0;
	}

	.cart-actions-column {
		padding: 2 * $column-base-padding;
		
		.column-add button {
			margin-top:2px;
		}
		
		@include breakpoint(desktop) {
			padding: $grid-base-padding + $column-base-padding;
		}
	}

	.save-and-close-form {
		margin-top: 0.75em;
	}
}