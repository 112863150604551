@import 'style/config';
@import 'style/base/10.mixins';
@import "node_modules/sensible/mediaqueries";

.product-overview {
	@include margins(toMobile($mobile));

	@include breakpoint(desktop) {
		@include margins(toBase($base));
	}
	
	.backbutton {
		margin-top: -15px;
		margin-bottom: 15px;
		
		svg {
			width:20px;
			vertical-align: bottom;
			height: 20px;
			
			path {
				fill:#437fa3;
			}
		}
	}

	.image-gallery {
		width: 100%;
		img {
			width: 100%;
		}
	}

	h1 {
		margin: 0.5em 0 0.25em;

		@include breakpoint(desktop) {
			margin-top: 0;
		}
	}

	dl {
		margin: 0;
	}

	dt {
		font-weight: bold;
		display: inline-block;

		&:after {
			content: " ";
		}

	}

	dd {
		margin: 0;
		display: inline;

		&:after {
			content: "";
			display: block;
		}
	}

	.product-cart {
		margin-top: 1em;
	}

	.share-links {
		display: block;
		margin-top: 1em;

		.product-cad-link {
			display: inline-block;
			@include leftright(margin-right, margin-left, 2em);

			&.not-available {
				color: $mid-grey;

				svg {
					fill: $mid-grey;
				}
			}

			a {
				font-weight: bold;
			}

			svg {
				display: inline-block;
				width: 1.5em;
				height: 1.5em;
				fill: $shaded-blue;
				vertical-align: bottom;
			}
		}

		.bookmark-link {
			@include leftright(margin-right, margin-left, 2em);
		}
	}

	.pdp-product-note {
		border: 1px solid $orange;
		padding: 0.5em;
		margin-top: 0.5em;
	}

}
div.with-design-data {
	> :not(:first-of-type) {
		margin-top: 0.2em !important;
	}
	> :not(:last-of-type) {
		margin-bottom: 0 !important;
	}
}