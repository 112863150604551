@import 'style/config';

.cta-button	{
	display: flex;
	.fake-button {
		@include fontsize(1.5em);
		padding: 0.75em;
		&.belimo-blue {
			background-color: $shaded-blue;
		}
		&.belimo-orange {
			background-color: $orange;
			display: flex;
			align-items: center;
		}			
	}
	&.hpos-left {
		justify-content: flex-start;
	}
	&.hpos-center {
		justify-content: center;
	}
	&.hpos-right {
		justify-content: flex-end;
	}
}
