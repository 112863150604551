.jobdetails {
  &__container {
    padding-top: 3rem;
  }

  &__main {
    @include breakpoint(not-mobile) {
      display: flex;
    }
  }

  &__title {
    color: $orange;
  }

  &__content {
    flex: 1;
    max-width: 100%;
    padding-bottom: 2rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $orange;
    }
  }

  &__aside {
    padding-bottom: 2rem;

    @include breakpoint(not-mobile) {
      width: 14rem;
      margin-left: 2rem;
    }

    a {
      color: $orange;
    }
  }

  &__meta {
    background-color: $testimonial-grey;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;

    dl {
      margin: 0;
    }

    dt,
    dd {
      font-size: 0.75rem;
    }

    dd {
      margin: 0 0 1rem 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__social-title {
    margin-bottom: 0;
  }

  &__social-icons {
    display: flex;
    align-items: center;

    a {
      display: block;
      margin-right: 0.5rem;

      &:hover svg {
        fill: $orange;
      }
    }

    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}

#jobdetails-application-success-message,
#jobdetails-application-error-message {
  color: $orange;
}

footer .footer-content .grid.container {
  justify-content: flex-start;
}

#loadingElement {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(105, 105, 105, 0.3);
  backdrop-filter: blur(3px);
  z-index: 1000;

  .spinner {
    display: inline-block;
    width: 70px;
    height: 70px;
    border: 7px solid #f3f3f3;
    border-top: 7px solid #ff6600;
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
    position: absolute;
    top: calc(50% - 70px);
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
