@import 'style/config';
@import 'node_modules/sensible/mediaqueries';

.form {

	form {

	}
		
	fieldset {
		border: 0px;	
	}

	.required label:before {
		content: "*";
		display: inline-block;
		margin-right: 0.1em;
		font-weight: bold;
		vertical-align: bottom;
	}

	.form-row + .form-row {
		margin-top: 1em;
	}

	.form-column + .form-column {
		margin-top: 1em;

		@include breakpoint(desktop) {
			margin-top: 0;
		}
	}

	.form-input.error {
		label {
			color: $red;
		}

		input,
		textarea,
		.select2-selection {
			border-color: $red;
			outline-color: $red;
		}
	}

	.form-toggled {
		display: none;

		&.toggle-on {
			display: block;
		}
	}

	.one-time-delivery-address-checkbox {
		background-color: $shaded-blue;
		margin-top: 1em;
		padding: 0.55em 0;
		> label {
			color: $white;
			> span {
				display: flex;
				align-items: center;
				justify-content: center;
				&::before {
					z-index: 1;
					transform: translateX(13px);
					left: 1em;
					@include breakpoint(tablet) {
						transform: translateX(16px);
						top: 7px;
					}
				}
				&::after {
					background-color: white;
					left: 1em !important;
					@include breakpoint(tablet) {
						top: 3px;
					}
				}
			}
		}
	}

}