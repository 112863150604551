.anp-main-view {
  .anp-input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 1em;
    margin-bottom: 1em;

    input {
      border-color: $mid-grey;
      width: 100%;
    }
  }
  .anp-confirm-content {
    display: none;
    max-width: 500px;
  }
  .anp-input-submitted-invalid {
    visibility: hidden;
    color: red;
  }
}

