@import 'style/config';
@import 'style/base/10.mixins';
@import "node_modules/sensible/mediaqueries";

.teaser-article {
	
	a {
		text-decoration: none;	
	}
	
	p,
	h3 {
		color: $base-font-color;
	}

	.teaser-article-title {

		em {
			font-style: normal;
			display: block;
			font-size: toEm($mobile, $h3);
			line-height: $base-line-height;
			color: $orange;

			@include breakpoint(desktop) {
				font-size: toEm($base, $h3);
			}
		}

		margin: factorLineHeight(toMobile(20), $h3-line-height) 0 0;

		@include breakpoint(desktop) {
			margin: 0.5em 0 0;
		}
	}

	p {
		margin: 0;
	}
}