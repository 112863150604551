@import 'style/config';
@import 'style/base/10.mixins';


$defaultPaddingD: 3.07692em;
$defaultPaddingM: 1.53846rem;
$titleBgColorM: lighten($shaded-blue, 5%);

.account-section {

	.backbutton {
		margin-top: -15px;
		margin-bottom: 15px;
		
		svg {
			width:20px;
			vertical-align: bottom;
			height: 20px;
			
			path {
				fill:#437fa3;
			}
		}
	}
}

.rma-requests {

	.rma-table-titles,
	.rma-table-content .rma-entry {
		with: 100%;
		display: flex;
		text-decoration: none;
		div {
			width:60%;
			padding: 1em 1em 1em 2rem;
			display: flex;
			justify-content: flex-start;
			&:last-child {
				width: 40%;
			}
		}
	}

	.rma-table-titles {
		font-weight: bold;
		color: white;
		div {
			background-color: $shaded-blue;
			&:last-child {
				background-color: lighten($shaded-blue, 5%);
			}
		}
	}

	.rma-table-content {
		.rma-entry {
			background-color: lighten($light-grey-blue, 4%);
			div {
				&:last-child{
					background-color: lighten($light-grey-blue, 5%);
				}
			}
			&:nth-child(2n){
				background-color: lighten($light-grey-blue, 1%);
				div {
					&:last-child{
						background-color: lighten($light-grey-blue, 2%);
					}
				}
			}
		}
	}
	.rma-entry:hover {
		div {
			background-color: darken($light-grey-blue-t, 10%) !important;
		}
	}
}
@include breakpoint(desktop) {
	.rma-requests {
		margin-left: -3.07692em;
		margin-right: -3.07692em;
	}
}

@include breakpoint(mobile-portrait) {
	.rma-requests {

		margin-left: -1.53846em;
		margin-right: -1.53846em;

		.rma-table-titles,
		.rma-table-content .rma-entry {
			flex-wrap: wrap;
			div {
				width: 100%;
				&:last-child {
					width: 100%;
				}
			}
		}

		.rma-table-titles {
			background-color: $titleBgColorM;
			div {
				padding: 0.3em $defaultPaddingM 0 $defaultPaddingM;
				background-color: $titleBgColorM;
				&:last-child{
					background-color: $titleBgColorM;
					font-size: 0.8em;
					font-weight: normal;
					padding-top: 0;
				}
			}
		}

		.rma-table-content {
			.rma-entry {
				padding: 0.4em $defaultPaddingM;
				div {
					padding: 0;
					&:last-child {
						font-size: 0.8em;
					}
				}
				&:nth-child(2n) {
					background-color: lighten($light-grey-blue, 1%);
					div {
						&:last-child{
							background-color: lighten($light-grey-blue, 1%);
						}
					}
				}
			}
		}
	}
}

.account-section-content {
	.project-list-table {
		.js-saved-cart-date {
			white-space: nowrap;
		}
		.js-saved-cart-total {
			white-space: nowrap;
		}
		a.restore-cart {
			font-weight: bold;
		}
		.action-link-group {
			padding-right: 1.5em !important;
		}
		.action-link-group div{
			white-space: nowrap;
			display: flex;
			justify-content: flex-end;
			@include breakpoint(tablet) {
				justify-content: center;
			}
		}
		.action-links {
			cursor: pointer;
		}
		&.disabled {
			.action-links {
				pointer-events: none;
				opacity: 0.5;
			}
		}
		td.project-name {
			max-width: #{1.5 * (toBase(80) + $column-base-padding + $grid-base-padding)};
			form {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

	}
	.address-book-row {
		cursor: pointer;
	}
	.address-edit-button {
		background-color: rgba(0,0,0,0);
		height: 0px;
		padding:0px;
	}
}
