@import 'style/config';

.multi-image-wrapper {
  .multi-image-container {
    display:flex;
    flex-direction: column;
    .multi-image-image-wrapper {
      .image {
        display: flex;
        img {
          margin: 0; 
        }
      }
    }
  }
}
