@import 'style/config';

.product {
	.tab-title > em {
		font-style: normal;
		color: $orange;
	}

	.tab-details {
		.teaser {
			padding: 0;
		}
	}
}

#dynamicConfigContent {
	padding-bottom: toBase(20);
		
	.cpq-form {
		clear: both;
		padding-top: toBase(20);
	}

	.cpq-button-bar {
		clear: both;
		
		.cpq-prev-navigation {
			float: left;	
		}
	
		.cpq-next-navigation {
			float: left;
			margin-left: 20px;
		}
	}
}