@import 'style/config';
@import "node_modules/sensible/mediaqueries";

.checkout-wizard {

	.checkout-wizard-heading {
		svg {
			display: inline-block;
			vertical-align: baseline;
			fill: $mid-grey;
			line-height: $h2-line-height;
			height: 1em;
			width: 1em;
		}
	}

	.checkout-wizard-step {
		display: block;

		+ .checkout-wizard-step {
			border-top: 1px dashed $mid-grey;
		}

		&.done {
			text-decoration: none;

			&:hover .checkout-wizard-step-heading {
				color: $orange;

				.checkout-wizard-step-edit svg {
					fill: $orange;
				}
			}

			.checkout-wizard-step-status svg {
				fill: $green;
			}
		}
	}

	.checkout-wizard-step-heading {
		padding: 0.5em 0;
		margin: 0;
		position: relative;
		color: $base-font-color;
	}

	.checkout-wizard-step-edit svg {
		height: 1em;
		width: 1em;
		fill: $base-font-color;
		margin: 0.4em 0;
		float: right;
	}

	.checkout-wizard-step-status svg {
		fill: $light-grey;
		height: 0.8em;
		width: 0.8em;
	}

	.checkout-wizard-step-content {
		padding: 0 0 1em;
	}
}