@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.cart {

	.fake-button {
		height: auto;
	}

	.column.column-thumbnail,
	.column.column-title {
		@include breakpoint(desktop) {
			max-width: toBase(120);
			min-width: toBase(120);
			width: toBase(120);
			&.cart-title-summary {
				max-width: inherit;
				width: auto;
				flex-shrink: 0;
			}
		}
	}
	.column.column-details {
		p {
			margin-bottom: .875em;
		}
	}
	.column.column-import {
	}
	.column.column-export {
	}
	.column.column-price {
		@include breakpoint(desktop) {
			min-width: toBase(210);
			max-width: toBase(210);
			width: toBase(210);
		}
		
		.data-list {
			@include breakpoint(mobile) {
			    margin-bottom: .875em;
			}
			>dl {
				white-space: normal;
			}
		}
	}
	.column.column-total {
		@include breakpoint(desktop) {
			min-width: toBase(100);
			max-width: toBase(100); 
			width: toBase(100);
			@include leftright(text-align, text-align, right, left);
		}
		@include breakpoint(mobile) {
		    float: right;
    		width: auto;
    		padding-top: 8px;
    		padding-left: 0;
    	}
	}
	.column.column-quantity {
		@include breakpoint(desktop) {
			min-width: toBase(120);
			max-width: toBase(120);
			width: toBase(120);
		}
		@include breakpoint(mobile) {
		    float: left;
    		width: auto;
    		padding-right: 10px;
    	}
	}
	.column.column-remove {
		@include breakpoint(desktop) {
			@include leftright(text-align, text-align, right, left);
			min-width: toBase(50);
			max-width: toBase(50); 
			width: toBase(50); 
		}
		@include breakpoint(mobile) {
			padding-top: 8px;
		}
	}
	.column.column-actions {
		@include leftright(text-align, text-align, right, left);
		
		@include breakpoint(desktop) {
			min-width: toBase(280);
			max-width: toBase(280);
			width: toBase(280);
		}
	}
	.cart-total > .grid {
		justify-content: flex-end;
	}
}