@import 'style/config';
@import 'style/base/10.mixins';

.content {
	@include content();
}

.content-top {
	@include contentTop();
}

.content-bottom {
	@include contentBottom();
}

.content-no-padding-bottom {
	@include content();
	padding-bottom: 0;
}