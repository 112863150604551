@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.message {
	padding: 0.5em;

	span {
		display: inline;
	}

	svg {
		display: inline-block;
		height: 1.25em;
		width: 1.25em;
		line-height: $base-line-height;
		vertical-align: top;
		margin-right: 0.25em;
	}
}