@import 'style/config';

.image {
	overflow: hidden;

	img {
		display: block;
		margin: 0;
		max-width: 100%;
	}

	&.cover {
		width: 100%;
		height: 100%;

		img {
			position: relative;
			top: 50%;
			@include leftright(left, right, 50%);
			max-width: 1000%;
			min-width: 100%;
			min-height: 100%;

			@include leftright(transform, transform, translateX(-50%) translateY(-50%), translateX(50%) translateY(-50%));

			.no-csstransforms3d & {
				@include leftright(transform, transform, translate(-50%, -50%), translate(50%, -50%));
			}
		}
	}
}