
.cart-name-current {
  display: flex;
  align-items: center;
  padding-bottom: 0.2em;
  @include breakpoint(mobile) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.5em;
  }

  .cart-name-current-title {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .cart-name-current-edit {
    display: flex;
    align-items: center;
    padding-left: 1em;
    @include breakpoint(mobile) {
      padding-left: 0;
      width: 100%;
    }

    input {
      padding-left: 0.3em;
      padding-top: 0;
      padding-bottom: 0;
      height: 1.6em;
      line-height: 1.6em;
      background-color: transparent;

      @include breakpoint(mobile) {
        width: 100%;
      }
    }
  }

}