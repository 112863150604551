@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.search-result-group-products {
	.search-result-product {
		.column.column-thumbnail {
			@include breakpoint(desktop) {
				width: toBase(90);
			}
		}
	}
}

.search.search-autosuggest,
.search.search-widget {
	.search-result-group {
		padding:0px;
		
		a {
			padding: 10px;
			border-bottom: 1px solid $light-grey;
			border-left: 1px solid $light-grey;
			border-right: 1px solid $light-grey;
			margin: 0px;
			display: block;
			cursor: pointer;
			color:#000;
			
			&:first-child {
				border-top: 1px solid $light-grey;
			}
			
			&:focus,
			&:hover {
				background: $orange;
				color:#fff;
				text-decoration:none;
			}

			@include breakpoint(tablet) {
				&:nth-of-type(1n+5) {
					display: none;
				}
			}
			outline: 0;
		}
		.discontinued-products {
			padding-left: 10px;
			padding-right: 10px;
			padding-bottom: 10px;
			margin-bottom: 0;
			background-color: $light-grey-blue;
			display: none;
			.discontinued-products-title {
				padding-top: 0.8em;
				padding-bottom: 0.8em;
				color: $orange;
				font-size: 1.1em;
				font-weight: bold;
			}
		
			a {
				background-color: $white;
				&:first-of-type {
					border-top: 1px solid $light-grey;
				}
				&:focus,
				&:hover {
					background: $orange;
					color:#fff;
					text-decoration:none;
				}
				@include breakpoint(tablet) {
					&:nth-of-type(1n+3) {
						display: none;
					}
				}
				@include breakpoint(desktop) {
					&:nth-of-type(1n+7) {
						display: none;
					}
				}
			}	
		}
	}	
}