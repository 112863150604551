@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.downloadTeaser {
	.add-to-downloadfolder-link {
	
		&:hover:before {
			text-decoration:none;
		}
	
		&:before {
			content:'|';
			color: #000;
			margin:0 5px;
			display: inline-block;
		}
	}
	
	.add-to-downloadfolder-link.orange {
		color: $orange;
	}
}

.teaser {
	@include content();

	&.teasers-intro,
	&.teasers-application {
		padding: 0;

		@include breakpoint(desktop) {
			padding: 0;
		}

		.grid.teaser-row {
			@include breakpoint(desktop) {
				margin-left: toBase(-40);
				margin-right: toBase(-40);
				width: 100vw;
				max-width: $grid-max-width;
			}
		}
	}

	&.teasers-intro {
		padding-bottom: toMobile(25);

		@include breakpoint(desktop) {
			padding-bottom: toBase(25);
		}

		.teaser-column {
			overflow: hidden;
			border-top: 1px solid $white;
			@include leftright(border-left, border-right, 1px solid $white);
			margin-left: -1px;
			margin-left: -1px;

			@include breakpoint(desktop) {
				@include flex(1);

				&:first-child .teaser-intro {
					@include leftright(padding-left, padding-right, toBase(30));

					.image {
						@include leftright(margin-left, margin-right, toBase(-40));
					}
				}

				&:last-child .teaser-intro {
					@include leftright(padding-right, padding-left, toBase(30));

					.image {
						@include leftright(margin-right, margin-left, toBase(-40));
					}
				}
			}

			+ .teaser-column {
				margin-left: 0;
			}
		}

		.image {
			margin: 0 toMobile(-25);

			@include breakpoint(desktop) {
				margin: 0 toBase(-10);
			}

			img {
				width: 100%;
			}
		}
	}

	&.teasers-application {
		.teaser-column {
			@include breakpoint(desktop) {
				@include flex(1);

				&:first-child {
					@include leftright(padding-left, padding-right, toBase(40));
				}

				&:last-child {
					@include leftright(padding-right, padding-left, toBase(40));
				}
			}
		}
	}

	.teaser-title {
		margin-bottom: toEm(40, $h2);
	}

	.teaser-more {
		margin-top: toMobile(-40);
		margin-bottom: toMobile(40);

		@include breakpoint(desktop) {
			margin-top: toBase(-40);
			margin-bottom: toBase(40);
		}

		a {
			@include fontsize($h3-font-size);
			$line-height: $h3-line-height;
			font-weight: bold;
		}
	}

	@include breakpoint(tablet) {
		.horizontal-scroll {
			.teaser-row {
				width: 240vw;
			}

			.teaser-column {
				width: 80vw;
			}
		}
	}
	
	.link-list ul {
		margin-bottom: 0;
		list-style-image: url(data:0);

		li {
			list-style-image: url(data:0);
		}
	}
}