@import 'style/config';



.bel-accordion-wrapper {
  @include content();

  ul,li {
    list-style-type: none;
    list-style-position: inside;
    margin: 0;
    padding: 0;
  }
  
  .bel-accordion {
    .bel-accordion-elems {
      list-style-type: none;
      list-style-position: inside;
      
      .bel-accordion-elem {
        display: block;
        
        .bel-accordion-elem-bar {
          background-color: $mid-grey;
          color: $black;
          height: auto;
          min-height: 2em;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 1em;
          padding-right: 1em;
          border-radius: 0.75em;
          cursor: pointer;
          margin-bottom: 1em;
          transition: border-radius 600ms 0ms;
          &.open {
            border-radius: 0.75em 0.75em 0.2em 0.2em;
            transition: border-radius 600ms 0ms;
            background-color: $orange;
            color: $white;
            .bel-accordion-elem-bar-handle {
              fill: $white;
            }
          }
          &:hover {
            box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
          }
          .bel-accordion-elem-bar-handle {
            width: 1em;
            height: 1em;
            fill: $black;
          }
          > h5 {
            margin: 0;
          }
        }
        .bel-accordion-elem-content {  
          height: 0;
          opacity: 0.8;
          transition: height 600ms 0ms, opacity 400ms 0ms;
          overflow: hidden;

          &.visible {
            height: auto;
            opacity: 1;
            transition: height 600ms 0ms, opacity 400ms 0ms;
            margin-bottom: 1em;
          }
          .bel-accordion-elem-components {

            display: flex;
            width: 100%;
            justify-content: space-between;
            overflow: hidden;
            flex-wrap: wrap;

            > .mgnlEditorBar.area{
              flex-basis: 100%;
            }

            .bel-accordion-elem-component {
              padding-top: 0;
              padding-bottom: 1em;

              ul {
                list-style-position: outside;
                padding-left: 20px;

                li {
                  list-style-position: outside;
                }
              }
            }

          }
          .link-list li {
            list-style-position: initial
          }
        }
      }
    }
    .bel-accordion-elems {
      li:last-child {
        .bel-accordion-elem-bar {
          margin-bottom: 0;
          &.open {
            margin-bottom: 1em;
          }
        }
        
      }
    }
  }
}