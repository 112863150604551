@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.search-result-download {
	
	border-top:1px dashed $mid-grey;
	padding-top:toBase(20);
	margin-top:toBase(20);

	.column-text {
		overflow: hidden;
		h3 {
			overflow: hidden;
			text-overflow: ellipsis;
			margin-top: 0;
			line-height: 27px;
			margin-bottom: .25em;
			a {
				color: #3d3d3d;
			}
		}
	}

	p {
		margin: 0;
	}
	
	&:first-of-type {
		border-top:0px;	
		padding-top:0px;
	}
	
	.column.column-thumbnail {
		max-width: 120px;
		padding-bottom: 12px;
		
		svg {
			width: 75px;
			height: 75px;
		}
		
		@include breakpoint(desktop) {
			min-width: toBase(100);
			max-width: toBase(100);
			width: toBase(100);
			padding:0px;
			
			svg {
				margin: 0 auto;
				display: block;
			}
		}
	}

	span.badge {
		display: inline-block;
		padding: 0px;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 12px;
		line-height: 20px;
		vertical-align: top;

		svg {
			width: 23px;
			height: 23px;
		}
	}
	.column.column-details {
		span.badge {
			margin-top: 7px;
		}
	}
	
	.column.column-price {
		font-weight: bold;

		@include breakpoint(desktop) {
			min-width: toBase(200);
			max-width: toBase(200);
			width: toBase(200);
			@include leftright(text-align, text-align, right, left);
			padding-top: $base-line-height;
		}
	
	}
	
	.link {
		margin-top:toBase(10);
		font-size: 1em;
	}
	
	.secondLink {
		padding-bottom: 10px !important;
		display: block;
		@include breakpoint(desktop) {
			display: inline-block;
		}
	}
	
	.firstLink {
		display: inline-flex;
	}
	
	.link + .secondLink {
		display: inline-flex;	
	}
	
	@include breakpoint(desktop) {
	
		.firstLink {
			min-width: 200px;
		}
	
		.link + .secondLink {
			padding: 0 0 0 10px !important;
		}
	}
	
	a.add-to-cart {
		color: $shaded-blue;
	}
	
	.add-to-downloadfolder-form .btn {
		height: auto;
	}
}