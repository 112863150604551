@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

footer {
	background-color: $light-grey;
	padding: 1em 0;
	display: flex;
	flex-direction: column;

	@include breakpoint(desktop) {
		width: 100%;
		//height: toBase(85);
	}

	.footer-content {

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.grid {
			background-color: transparent;
			flex-wrap: wrap;
			display: flex;
			justify-content: space-between;
			padding: 0;
			align-items: center;

			@include breakpoint(tablet) {
				flex-direction: column;
				display: flex;
				align-items: flex-start;
				
			}

		}

		.column-legal {
			width: auto;
			margin-top: 1em;

			font-size: 0.75em;
			@include breakpoint(desktop) {
				font-size: 1em;
			}

			.legal-links {

				@include breakpoint(tablet) {
					flex-direction: column;
					display: flex;
					align-items: flex-start;
				}

				a {
					display: inline-block;
					color: $dark-grey;
					
					height: 1.5em;

					@include breakpoint(desktop) {

						+ a {
							&:before {
								margin-left: 0.5em;
								margin-right: 0.5em;
								content: "|"; 
								display: inline-block;
							}
						}
					}
					@include breakpoint(tablet) {

						+ a {
							margin-top: 0.5em;
						}
					}					
				}
			}


		}

		.column-social {
			> div {
				display: flex;
				align-items: center;
				height: 100%;
				margin-right: 1em;
				margin-top: 1em;
				&:last-child {
					margin-right: 0;
				}
			}

			width: auto;
			
			
			display: flex;
			align-items: center;
			height: 100%;
			flex-wrap: wrap;
			
			font-size: 0.75em;
			@include breakpoint(desktop) {
				font-size: 1em;
			}
			@include breakpoint(tablet) {
				margin-top: 1.5em;
				margin-bottom: 0.5em;
			}

			a {
				color: $dark-grey;
				display: flex;
				align-items: center;
				height: 1.5em;


				&:not(:first-child) {
					margin-left: 0.5em;
				}
				&:not(:last-child) {
					margin-right: 0.5em;
				}

				&[href^="http"] {
					text-decoration: none;
					font-weight: bold;
				}
				svg {
					height: 1.5em;
					fill: $dark-grey;
					vertical-align: text-bottom;
					margin-right: 0.5em;
					margin-bottom: 0.3em;
				}
				&:last-child > svg {
					margin-right: 0;
				}
			}

		}
		.column-copyright {
			display: flex;
			justify-content: flex-end;

			font-size: 0.75em;
			@include breakpoint(desktop) {
				font-size: 1em;
			}

			@include breakpoint(tablet) {
				justify-content: flex-start;
			}

			.legal-copyright {
				color: $dark-grey;
				font-size: 0.95em;

				margin-top: 2em;
				@include breakpoint(desktop) {
					font-size: 0.95em;
				}
			}
		}
		.footer-button-list {
			font-size: 0.75em;
			> div {
				margin-top: 1.0em;
				&:not(:first-child) {
					margin-top: 0.5em;
				}
			}
			display: flex;
			flex-direction: column;
			.footer-button {
				display: flex;
				.fake-button {
					font-size: 1.25em;
					align-items: center;
					padding-top: 0.5em;
					padding-bottom: 0.5em;
					display: flex;
				}
			}

		}
	}
}

#topButton {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 20px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background-color: $orange; /* Set a background color */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 20px 16px; /* Some padding */
    border-radius: 30px; /* Rounded corners */
	width: 50px;
    height: 50px;
    
    i {
	  border: solid white;
	  border-width: 0 5px 5px 0;
	  display: inline-block;
	  padding: 6px;
	}
	
	.up {
	    transform: rotate(-135deg);
	    -webkit-transform: rotate(-135deg);
	}
}

#topButton:hover {
    background-color: $dark-grey; /* Add a dark-grey background on hover */
}

html[dir=rtl] footer .footer-content .column-social {
	a svg {
    	margin-left: 0.5em;
    	margin-right: 0; 
	}
	> div {
		margin-right: 0;
		& > a {
			&:last-child {
				margin-left: 0.0;
				svg {
					margin-left: 0.0;
				}
			}	
		}		
	}
}
