$defaultSpacingXS: 0.25em;
$sliderHandleRadius: 0.8em;

$colorScale: #437fa3;
$colorScaleMarks: #437fa3;
$colorScaleMarksLarge: #437fa3;
$scaleLineWidth: 2px;
$scaleLineWidthRange: 3px;
$scaleMarksLineWidth: 2px;

.range-slider-nouislider {
  border: none;
  border-top: $scaleLineWidth solid $colorScale;

  &.noUi-target {
    box-shadow: none;
  }
  .noUi-base > .noUi-connects {
    height: 2*$sliderHandleRadius;
    transform: translateY(-$sliderHandleRadius);

    .noUi-connect {
      background: $orange;
      height: $scaleLineWidthRange;
      top: calc(#{$sliderHandleRadius} - #{$scaleLineWidthRange} / 2 - 1px);
    }
  }

  &.noUi-horizontal {
    height: $scaleLineWidth;

    .noUi-handle {
      width: 2*$sliderHandleRadius;
      height: 2*$sliderHandleRadius;
      top: -$sliderHandleRadius;
      background: $orange;
      opacity: 0.934;
      box-shadow: none;
      border-radius: $sliderHandleRadius;
      border: none;

      &:after,
      &:before {
        display: none;
      }
    }

    .noUi-pips {
      color: $black;
      &.noUi-pips-horizontal {
        padding: 0;
        height: auto;
      }
      .noUi-marker-large {
        background: $colorScaleMarksLarge;
        width: $scaleMarksLineWidth;

      }
      .noUi-marker {
        background: $colorScaleMarks;
        width: $scaleMarksLineWidth;

      }

      .noUi-marker {
        height: 0.5 * $sliderHandleRadius;
        transform: translateY(calc(-0.5 * #{$sliderHandleRadius} / 2 - 1px));
      }
      .noUi-marker-large {
        height: $sliderHandleRadius;
        transform: translateY(calc(-#{$sliderHandleRadius} / 2 - 1px));
      }
      //.noUi-value {
      //  padding-top: $defaultSpacingXS;
      //}

      >div:first-child,
      >div:nth-last-child(2) {
        height: 1.5 * $sliderHandleRadius;
        transform: translateY(calc(-1.5 * #{$sliderHandleRadius} / 2 - 1px));
      }
      >div:nth-last-child(1).noUi-value {
        transform: translate(-100%, 75%);
      }
      >div:nth-last-child(3).noUi-value {
        transform: translate(0%, 75%);
        //padding-right: 0.5em;
      }
    }
  }
}
html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -$sliderHandleRadius;
}
