@import 'style/config';

@include breakpoint(tablet) {
	.horizontal-scroll {
		overflow-x: auto;
		overflow-y: show;
		@include flexbox();
		flex-wrap: nowrap;
		cursor: grab;
		&:active {
			cursor: grabbing;
		}

		.horizontal-scroll-item {
			@include flex(0, 0);
		}
	}
}