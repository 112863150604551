@import 'style/config';

.select2-container--default {
  @import "single";
  @import "multiple";

  outline-color: $shaded-blue;

  .select2-dropdown {
    border-color: $input-border-color;
    border-radius: 0;
  }

  .select2-search--dropdown {
    .select2-search__field {
      border-color: $input-border-color;
      border-width: 1px;
      border-style: solid;
    }
  }

  .select2-search--inline {
    .select2-search__field {
      background: transparent;
      border: none;
      outline: 0;
      box-shadow: none;
      appearance: textfield;
    }
  }

  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }

  .select2-results__option {
    &[role=group] {
      padding: 0;
    }

    &[aria-disabled=true] {
      color: #999;
    }

    &[aria-selected=true] {
      background-color: #ddd;
    }

    .select2-results__option {
      padding-left: 1em;

      .select2-results__group {
        padding-left: 0;
      }

      .select2-results__option {
        margin-left: -1em;
        padding-left: 2em;

        .select2-results__option {
          margin-left: -2em;
          padding-left: 3em;

          .select2-results__option {
            margin-left: -3em;
            padding-left: 4em;

            .select2-results__option {
              margin-left: -4em;
              padding-left: 5em;

              .select2-results__option {
                margin-left: -5em;
                padding-left: 6em;
              }
            }
          }
        }
      }
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $shaded-blue;
    color: white;
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }

  @include breakpoint(mobile) {
    #select2-selectedDeliveryMethod-results {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border: 2px solid #eee;
      }

      &::-webkit-scrollbar-track {
        background-color: #eee;
      }
    }
  }
}

