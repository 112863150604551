@import 'style/config';

.cpq-page {
  .column-cto {
    width: 100% !important;
    .cpq-add-to-cart-container {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      //padding-left: 0 !important;
      //padding-right: 1.53846em !important;
      >div:not(:first-of-type) {
        padding-left: 1em;
      }
      >a {
        padding-left: 2em;
      }
      @include breakpoint(mobile-portrait) {
        flex-direction: column;
        align-items: flex-start;
        >div, a, >div:not(:first-of-type) {
          padding: 0.5em 0;
        }
      }
    }

  }
}