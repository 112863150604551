
@import 'style/config';
@import 'style/base/10.mixins';

$popup-bg-color: $white;

.popup-id-wrapper {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100001;
}

.popup-id-backdrop {
  position: relative;
  display: flex;
}

.popup-id-popup {
  position: absolute;
  height: fit-content;
  margin: 0 10px;
  padding: 1.0em 1.0em 1.0em 1.0em;
  background-color: $popup-bg-color;
  pointer-events: auto;
  z-index: 3;
  @include breakpoint(mobile-portrait) {
    width: calc(100% - 20px)
  }
}

.popup-font-bold {
  font-weight: bold;
}

.popup-font-large {
  font-size: 1.2em;
}

.popup-overflow-hidden {
  overflow: hidden;
}

.popup-opaque {
  background-color: rgba(0,0,0,0.8);
  pointer-events: none;
}

.popup-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  margin-bottom: 1em;
}

.popup-button {
  height: 100%;
  margin-left: 1em;
}

.popup-icon-button {
  @extend .popup-button;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0;
  div {
    padding: 5px;
    width: 25px;
    height: 25px;

    svg {
      width: auto;
      height: auto;
    }
  }
}

.popup-icon-button-alt {
  @extend .popup-icon-button;
  background-color: $orange;
  div svg path {
    fill: $white;
  }
}

