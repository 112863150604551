@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.form-input-number {
	display: inline-block;
	vertical-align: top;
	padding: 0.5em 0;
	width: 100%;

	.form-control {
		@include flexbox();
	}
	
	.control {
		border: 1px solid #626262;
		text-align: center;
		display: inline-block;
		line-height: 2.5em;
		width: 2.5em;
		height: 2.5em;
		vertical-align: middle;
		cursor: pointer;
		@include flex(0, 0);
		user-select: none;
		background-color: $shaded-blue;

		&:hover,
		&:active {
			text-decoration: none;
			background-color: lighten($shaded-blue, 15%);
		}
	}

	.input {
		border-color: #626262;
		border-style: solid;
		border-width: 1px 0;
		height: 2.5em;
		vertical-align: middle;		
		@include flex(1, 1);
	}

	input {
		border-width: 0;
		display: inline-block;
		appearance: textfield;
		text-align: center;
		padding: 0.5em 0em;
		overflow: hidden;
		@include flex(0, 1);
		height: 100%;
		width: 100%;
		min-width: 44px;

		&::-webkit-inner-spin-button, 
		&::-webkit-outer-spin-button { 
			-webkit-appearance: none; 
			margin: 0; 
		}
	} 

	svg {
		width: 0.6em;
		height: 0.6em;
		fill: $white;
	}
}