@use 'sass:math';
.video {
    position: relative;
    padding-bottom: #{math.div(9, 16) * 100%};
    @include breakpoint(tablet) {
        margin-top: 1em;
    }

    .video-content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        
        .cookieconsent-optout-preferences {
            display:block;
            height: 100%;
            background: #f5f5f5;
            text-align: center;
            padding: 15%;
            @include breakpoint(desktop) {
                padding: 25% 20%;
            }
        }

        .video-moving-image-wrap {
            .mi-top-controlbar,
            .mi-controls {
                opacity: 0;
            }
            .mi-controls {
                height: 32px;            		
            }
            &:hover {
                .mi-controls,
                .mi-top-controlbar {
                    opacity: 1;	
                    transition: opacity 600ms;
                }
            }
        }
    }

    video
    {
        width: 100%;
        height: 100%;
    }
}


