@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.product-specification {
	dl {
		margin: 0;
		@include flexbox();
		flex-wrap: wrap;
		align-items: baseline;
	}

	dt,
	dd {
		display: block;

		// @include without-flexbox() {
		// 	float: left;
		// 	vertical-align: baseline;
		// }
	}

	dt {
		width: 45%;
		font-weight: bold;
		padding: 0.25em toMobile(10) 0.25em 0;

		@include breakpoint(desktop) {
			padding: 0.25em toBase(10) 0.25em 0;
		}

		&.product-specification-highlight + dd {
			color: $orange;
			font-weight: bold;
			font-size: toMobile(20);
			padding: 0.25em 0.5em;

			@include breakpoint(desktop) {
				font-size: toBase(20);
			}
		}
	}

	dd {
		width: 55%;
		margin: 0;
		padding: 0.25em toMobile(10);

		@include breakpoint(desktop) {
			padding: 0.25em toBase(10);
		}
	}

	dd.cpq-price-value {
		text-align: right;
	}

	dl.cpq-price-sum-sub {
		margin-top: 1em;
		padding-top: 1em;
	}

	dd.cpq-csticValue {
		.message {
			margin-bottom: 0.5em;	
		}
	
		label {
			margin-left: 0.5em;	
		}
	}
}

.product-classification-group-container {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	.product-classification-group {
		width: 100%;
		overflow: hidden;
		@include breakpoint(desktop) {
			width: 50%;
		}
		h4 {
			font-size: 1.1rem;
		}
		.product-specification {
			height: 100%;
			dd {
				overflow-wrap: break-word;
			}
		}
		&:nth-child(2n) {
			@include breakpoint(desktop) {
				padding-left: 1em;
			}
		}
	}
}