@import 'style/config';

.dot {
	display: inline-block;
	width: 0.7em;
	height: 0.7em;
	border-radius: 0.35em;
	margin: 0.15em 0;
	line-height: 1em;

	&.grey {
		background-color: $mid-grey;
	}

	&.green {
		background-color: $light-green;
	}

	&.red {
		background-color: $red;
	}
}