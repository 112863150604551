@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.list-footer {
	@include content();

	.list-prev,
	.list-next {
		display: inline-block;
		border: 1px solid $light-grey;

		svg {
			fill: $base-font-color;
			width: 2em;
			height: 2em;
			vertical-align: middle;
		}
	}

	.list-prev {
		margin-right: 1em;
	}

	.list-next {
		margin-left: 1em;
	}

	.list-pages {
		display: inline-block;
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			display: inline-block;
			vertical-align: middle;
			margin: 0px 3px;
			 
			&.list-pages-label {
				@include fontsize($pagination-font-size);
			}
			a {
				@include fontsize($pagination-font-size);
			}
		}
	}

	a {
		color: $base-font-color;

		&.active,
		&:hover {
			font-weight: bold;
			color: $orange;
		}
	}
}