@import 'style/config';

.link-list {
	ul {
		list-style: none;
		padding: 0;
	}

	li {
		padding: 0;
		.teaser-list & .link em {
			display:none;
		}

		+ li {
			padding-top: 0.25em;
		}

		&:nth-child(n + 2) {
			h1 {
				margin-top: factorLineHeight(toEm(40, $h1), $h1-line-height);
			}

			h2 {
				margin-top: factorLineHeight(toEm(20, $h2), $h2-line-height);
			}

			h3 {
				margin-top: toEm(10, $h3);
			}
		}
	}
}