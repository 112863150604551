@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.add-to-downloadfolder-form {
	#addToCartButton {
		span.addedtofolder {
			display:none;
		}

		span.addtofolder {
				display:inline;
		}
	}

	#addToCartButton:disabled {
		opacity: 1;
		background: #fff;
		color: $orange;
		padding-left: 5px;
		padding-right: 5px;

		span.addedtofolder {
			display:inline;
		}

		span.addtofolder {
				display:none;
		}
	}
}

.documents {

	.fake-button {
		height: auto;
	}

    // .grid.document-row,
    // .grid.document-details,
    // .grid.document-version {
    //     @include without-flexbox() {
    //         width: 100%;
    //     }
    // }

    .column.column-details,
    .column.column-name,
    .column.column-size,
    .column.column-versions {
        padding-left: $column-base-padding;
    }

    .column.column-select,
    .column.column-details,
    .column.column-name,
    .column.column-size {
        padding-right: $column-base-padding;
    }

    .column.column-select {
        max-width: toMobile(55);
        min-width: toMobile(55);
        width: toMobile(55);

        @include breakpoint(desktop) {
            max-width: (2 * $column-base-padding) + toBase(20);
            min-width: (2 * $column-base-padding) + toBase(20);
            width: (2 * $column-base-padding) + toBase(20);
            
            label {
            	float:left;
            }
            
            & > span {
            	float: left;
            	display: block;
            }
        }

        .form-input-checkbox {
            display: inline-block;
            padding: 0.25em 0;

            @include breakpoint(desktop) {
                padding: 0;
            }
        }
    }

    .column.column-details {
        .grid {
            margin-left: -1 * $column-base-padding;
            margin-right: -1 * $column-base-padding;
        }
    }

    .column.column-size {
        max-width: toMobile(150);
        min-width: toMobile(150);
        width: toMobile(150);
    }

    .column.column-versions {
        max-width: toMobile(150);
        min-width: toMobile(150);
        width: toMobile(150);

        @include breakpoint(desktop) {
            @include leftright(text-align, text-align, right, left);
        }

        svg {
			height: 1em;
        }
    }

    .column.column-download {
        @include leftright(text-align, text-align, right, left);

        @include breakpoint(desktop) {
            max-width: toBase(130);
            min-width: toBase(130);
            width: toBase(130);
        }

        a {
            font-weight: bold;
        }
    }

    .documents-group,
    .documents-footer {
        @include negateGrid();
    }

    .documents-group + .documents-group {
        border-top: 1px dashed $mid-grey;
        margin-top: 1.5em;
        padding-top: 1em;
    }

    .documents-footer {
        margin-top: 1.5em;
        padding-top: 1em;
        padding-bottom: 1em;
    }

    .documents-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .document + .document {
        margin-top: 1em;
    }

    .document-type {
        display: none;

        @include breakpoint(desktop) {
            display: inline-block;
            vertical-align: middle;
            margin-left: -0.5em;
            margin-right: 0.5em;
        }

        svg {
            display: inline-block;
            height: 1.25em;
            width: 1.25em;
            line-height: 1.25em;
        }
    }

    .versions-toggle {
        display: block;
        color: $base-font-color;
        text-decoration: none;

        svg {
            height: 1.5em;
            width: 1.5em;
        }

        &.toggle-on svg {
			transform: rotate(180deg);
        }
    }

    .document-versions {
        display: none;

        &.toggle-on {
            display: block;
        }

        ul {
            list-style-type: disc;
        }
        .grid.document-version {
            margin-left: -1 * $column-base-padding;
            margin-right: -1 * $column-base-padding;
        }

        @include leftright(padding-left, padding-right, toMobile(55));
        @include breakpoint(desktop) {
            @include leftright(padding-right, padding-left, toMobile(310));
        }
    }

    .documents-counter {
        @include breakpoint(desktop) {
            display: inline-block;
            @include leftright(padding-right, padding-left, 1.0em);
        }

        span {
            font-weight: bold;
            color: $orange;
            padding-right: 0.2em;
        }
    }

    .documents-actions .documents-link-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        > a,
        > div {
            display: flex;
            align-items: center;

            @include breakpoint(tablet) {
                min-width: 50%;
                padding-bottom: 0.3em;
                &.documents-counter {
                    padding-left: 0.25em;
                }
            }
            @include breakpoint(mobile) {
                min-width: 100%;
                padding-bottom: 0.3em;
                &.documents-counter {
                    padding-left: 0.25em;
                }
            }
        }
        @include breakpoint(desktop) {
            &.fix-flex-wrap {
                > a,
                > div {
                    min-width: 50%;
                    padding-bottom: 0.3em;
                    &.documents-counter {
                        padding-left: 0.25em;
                    }
                }
            }
        }


        > a {

            font-weight: bold;

            @include leftright(padding-right, padding-left, 1.0em);

            &.inactive {
                opacity: 0.5;
                cursor: default;
                text-decoration: none;
            }
            svg {
                margin-right: 0.5em;
                width: 1em;
                height: 1em;
                fill: $shaded-blue;
                stroke: $white;
            }
            &.share-by-email svg {
                stroke: $shaded-blue;
            }
            &.disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }

    }
}
