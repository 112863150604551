@import 'style/config';
@import "node_modules/sensible/mediaqueries";

.tab {
	background-color: $white;
	@include contentTop();

	.tab-labels {
		display: none;
		@include negateGrid();
	
		@include breakpoint(desktop) {
			display: block;
		}

		ul {
			@include flexbox();
			flex-wrap: nowrap;
			list-style: none;
			margin-top: 0;
			margin-bottom: 0;
			padding-left: ($grid-base-padding + $column-base-padding);
			padding-right: ($grid-base-padding + $column-base-padding);
		}
	
		.tab-label {
			display: inline-block;
			flex-basis: auto;
			white-space: nowrap;
			padding: 0.75em 1.5em;
				
			.count {
				font-style: italic;
			}

			a {
				padding: 0.75em 1.5em;
				margin: -0.75em -1.5em;
			}

			&:hover,
			&.toggle-on {
				color: $orange;
			}

			&.toggle-on {
				border-bottom: 5px solid $orange;
			}
		}
	}

	.tab-label {
		@include fontsize($nav-title-font-size);
		padding-top: 0.75em * $mobile-desktop-factor;
		padding-bottom: 0.75em * $mobile-desktop-factor;
		color: $base-font-color;
		line-height: $nav-title-line-height;

		@include breakpoint(desktop) {
			padding: 0.75em 1.5em;
		}
		@include breakpoint(tablet) {
			padding-right: 1.5em;
		}

		a {
			color: inherit;
			display: block;
			padding: 0.75em * $mobile-desktop-factor 0;
			margin: -0.75em * $mobile-desktop-factor 0;
			text-decoration: none;


			@include breakpoint(desktop) {
				padding: 0.75em 1.5em;
				margin: -0.75em -1.5em;
			}

		}

		&.toggle-on {
			color: $orange;

			svg {
				transform: rotate(180deg);
				fill: $orange;
			}
		}

		svg {
			float: right;
			height: 1em;
			width: 1em;
			fill: $base-font-color;
			@include leftright(margin-right, margin-left, -1.25em);
		}
	}

	> .tab-contents {
		@include negateGrid();
		
		> .tab-label {
			font-weight: bold;

			@include breakpoint(desktop) {
				display: none;
			}
		}

		* + .tab-label {
			border-top: 1px dashed $light-grey;
		}

		.content {
			@include breakpoint(tablet) {
				padding-top: 1em;
				padding-bottom: 1em;
			}
		}
	}

	@include breakpoint(desktop) {
		&.search-result-tab {
			.tab-labels > ul {
				display: flex;
				flex-wrap: wrap;
			}
			.tab-labels {
				.tab-label {
					padding-left: 1.0em;
					padding-right: 1.0em;
					a {
						padding-left: 1.0em;
						padding-right: 1.0em;
						margin-left: -1.0em;
						margin-right: -1.0em;
						display: flex;
					}
					.count {
						font-size: 0.7em;
						padding-left: 0.3em;
					}
					display: flex;
				}
			}
		}
	}

	.list-discontinued-products {
		@include breakpoint(tablet) {
			.panel-content {
				display: block !important;
			}
		}
		@include breakpoint(desktop) {
			.column.list-filter {
				display: none;
			}
		}
	}

}
