@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.cart-add {
	display: block;

	.form-column {
		@include flexbox();
	}

	.form-input {
		display: inline-block;
		max-width: 190px;
	}

	.form-control {
		display: inline-block;
		@include leftright(margin-left, margin-right, 0.25em);
		
		button {
			margin-top: 1.15em;
		}
	}
	.cart-add-row {
		display: flex;
		align-items: flex-end;
		button {
			display: flex;
			flex-direction: column;
			justify-content: center;
			line-height: 1.1em;
		}
	}
}