@import 'style/config';
@import 'style/base/10.mixins';
@import "node_modules/sensible/mediaqueries";

.checkout-summary {

	.checkout-summary-heading {
		padding: 0.5em 0;
		margin: 0;
		position: relative;
		color: $base-font-color;

		svg {
			fill: $base-font-color;
			height: 1em;
			margin: 0.4em 0;
			float: right;
		}
	}

	.checkout-summary {

		.collapsable-trigger {
			h2 {
				margin: factorLineHeight(0em, $h2-line-height) 0 factorLineHeight(toEm(20, $h2), $h2-line-height);
			}

			svg {
				float: right;
			}

			&:hover {
				color: $orange;

				svg {
					fill: $orange;
				}
			}
		}

		.collapsable-content {
			@include breakpoint(desktop) {
				overflow-y: auto;
			}
		}

		&.toggle-on {
			.collapsable-trigger {
				color: $orange;
			}

			.collapsable-content {
				@include breakpoint(desktop) {
					max-height: 50vh;
					min-height: 6em;
					overflow-y: auto;
				}
			}
		}
	}

	.checkout-summary-subtotal,
	.checkout-summary-total {
		margin-top: 1em;
		padding-top: 1em;

		.column-label {
		
		}

		.column-price {
			text-align: right;
		}
	}

	.checkout-summary-subtotal {
		.column-label span,
		.column-price span {
			font-size: toMobile(18);

			@include breakpoint(desktop) {
				font-size: toBase(18);
			}
		}
	}

	.checkout-summary-total {
		font-weight: bold;
		.column-label span,
		.column-price span {
			font-size: $h3-font-size;
		}
	}

	.checkout-order-summary-list {
		list-style-image: none;
		list-style-type: none;
		padding-left: 0;
		> .checkout-order-summary-list-heading {
			display: flex;
			flex-direction: column;
			> .title {
				@include fontsize($h3-font-size);
				font-weight: bold;
				margin-top: 0.5em;
				margin-bottom: 0.5em;

			}
			> .address {
				margin-top: 0.875em;
				margin-bottom: 0.875em;
			}
		}
	}

}