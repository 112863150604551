//@import 'config';


.register-form-wrapper {


  .privacy-consent {

    margin-top: 1em;
    margin-bottom: 1em;

    label div {
      margin-top: -1em;
    }

    .info-text {
      font-size: 0.9em;
      margin-top: 2em;
    }

    .form-input-checkbox {
      font-size: 1em;
      margin-top: 1em;
    }

    .personal-consent {
      label {
        display: flex;
        div {
          margin-top: 0;
        }
      }
      .consent-text {
        font-size: 0.9em;
        margin-top: 0.3em;
      }
      .consent-text-for-cb {
        font-size: 0.9em;
        margin-top: -0.2em;
      }
      .privacy-terms {
        font-size: 0.9em;
        margin-top: 0.4em;
      }
    }
  }
}
