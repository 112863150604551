@import 'style/config';
@import "node_modules/sensible/mediaqueries";

.tab-content {
	display: none;

	.tab-label.toggle-on ~ &.toggle-on {
		display: block;
	}

	@include breakpoint(desktop) {
		&.toggle-on {
			display: block;
		}
	}
}