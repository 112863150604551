@import 'style/config';
@import 'node_modules/sensible/mediaqueries';

.news {

	&:first-child {
		margin-top: toMobile($content-padding);
		@include breakpoint(desktop) {
			margin-top: toBase($content-padding);
		}
	}
	
	* + & {
		border-top: 1px solid $mid-grey;
		margin-top: toMobile($content-padding);
		padding-top: toMobile($content-padding);

		@include breakpoint(desktop) {
			margin-top: toBase($content-padding);
			padding-top: toBase($content-padding);
		}
	}

	.news-content {
		* + & {
			margin-top: 1em;
		}
	}

	.column.column-text {
		@include breakpoint(desktop) {
			margin-top: 0;
			flex: 0 toBase(830);
			width: toBase(830);
		}
	}

	.column.column-share {
		text-align: right;

		@include breakpoint(desktop) {
			flex: 0 toBase(190);
			width: toBase(190);
		}
	}

	h3 {
		@include breakpoint(desktop) {
			margin-top: -0.4em;
			margin-bottom: 0;
		}
	}

	ul.news-keywords {
		margin: 0;
		padding: 0;
		list-style: none;

		& + * {
			margin-top: 2em;
		}

		li {
			display: inline-block;
		}

		.news-keyword-location {
			font-weight: bold;
		}

		.news-keyword-date {
			&:before {
				vertical-align: middle;
				display: inline-block;
				content: "";
				width: 0;
				height: 0;
				margin: 0.5em 0.5em 0.5em 0.25em;
				background-color: $base-font-color;
				border: 0.2em solid $base-font-color;
				border-radius: 0.25em;
			}
		}
	}

	a.readmore {
		&:before {
			content: attr(data-readmore);
		}
	}
}