.address-add-edit {
  .address-add-edit-ctrls {
    display: flex;
    align-items: stretch;
    .address-edit-open {
      margin-left: 2em;
      cursor: pointer;
      display: flex;
      align-items: center;
      svg {
        padding-left: 0;
        fill: white;
        width: 1.5em;
      }
    }
  }
}

.ui-autocomplete.address-suggestions {
  background-color: white;
  border: 1px solid #626262;
  // adding scroll bar if there are long-list suggestions
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 99999999999;

  & .ui-state-active {
    background-color: $light-grey-blue;
  }
}
