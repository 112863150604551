@import 'style/config';


.custombanner-wrapper {
  margin-bottom: 1em; 
  .banner-texts {
    display: flex;
    flex-direction: column;
  }

  .banner-button-image-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include breakpoint(desktop) {
      flex-direction: row;
    }
    .banner-image-wrapper {
      margin-top: 1em;
      margin-bottom: 1em;
    }
    .banner-button {
      margin: 1em;
      a {
        background-color: $orange;
      }
    }
  }
}

