@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.order-file-list {
  ul {
    @include breakpoint(desktop) {
      column-count: 2;
    }

    .no-csscolumns & > li {
      @include breakpoint(desktop) {
        float: left;
        width: 50%;
      }
    }
  }
}
