@import 'style/config';
@import 'node_modules/sensible/mediaqueries';

.locations>#contact>.grid {
	align-items: baseline;
	flex-wrap: wrap;
}

.location {
	address {
		font-style: normal;
		margin: 0.75em 0;
	}

	dl {
		margin: 0.75em 0;
	}

	dt,
	dd {
		display: inline;
		margin: 0;
	}

	dd:after {
		content: "";
		width: 100%;
		display: block;
	}

	dt {
		font-weight: bold;
	}

	.office-title {

		em {
			font-style: normal;
			display: block;
			font-size: toEm($mobile, $h3);
			line-height: $base-line-height;
			color: $orange;

			@include breakpoint(desktop) {
				font-size: toEm($base, $h3);
			}
		}

		margin: factorLineHeight(toMobile(20), $h3-line-height) 0 0;

		@include breakpoint(desktop) {
			margin: 0.5em 0 0;
		}
	}
}