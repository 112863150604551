.info-popup-wrapper {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000000;
  > .info-popup-backdrop {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8);
    position: relative;
    > .info-popup {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: fit-content;
      background-color: $white;
      border: 3px solid $dark-grey;
      @include breakpoint(desktop) {
        max-width: 600px;
        min-width: 400px;
      }
      @include breakpoint(tablet) {
        max-width: 500px;
        min-width: 400px;
      }
      @include breakpoint(mobile) {
        max-width: 90vw;
        min-width: 90vw;
      }
      > .info-popup-titlebar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        > button.close-button {
          height: 100%;
          margin-left: 1em;
          padding-top: 0.5em;
          padding-bottom: 0.5em;
          background-color: initial;
          div {
            padding: 5px;
            width: 25px;
            height: 25px;
            background-color: $orange;
          
            svg {
              fill: $white;
              width: auto;
              height: auto;
            }
          }
        }
      }
      > .info-popup-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-x: hidden;
        padding: 1em;
        padding-top: 0;
        h3 {
          align-self: center;
          text-align: center;
        }
        .info-popup-text {
          overflow: hidden;
          display: flex;
          padding-top: 1em;
          &.info-popup-center-text > .text {
            text-align: center;
            width: 100%;
          }
        }
      }
      > .info-popup-actionbar {
        display: flex;
        justify-content: center;
        padding: 1em;
        align-items: center;
        > div > .info-popup-cta-button {
          background-color: $orange;
          margin-left: 1em;
          display: flex;
          align-items: center;
        }
        .info-popup-close-button {
          @include breakpoint(tablet) {
            height: 2.5rem;
          }
        }
      }
    }
  }
  &.info-popup-disabled {
    display: none;
  }
  &.edit-mode {
    display: block;
    overflow: hidden;
    position: initial;
    height: auto;
    > .info-popup-backdrop {
      width: 100%;
      height: 100%;
      background-color: initial;
      > .info-popup {
        position: initial;
        transform: initial;
        overflow: hidden;
        margin-top: 1em;
      }
    }
    &.info-popup-disabled {
      .info-popup {
        opacity: 0.5;
      }
    }
  }
}
