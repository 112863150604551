// sass config
@use 'sass:math';

// font sizes
$default: 16;
$base: 13;
$mobile: $default;

$headline: 52;
$h1: 39;
$h2: 26;
$h3: 20;
$pagination: 16;

$content-padding: 40;

$mobile-desktop-factor: math.div($base, $mobile);
$desktop-mobile-factor: math.div($mobile, $base);

$base-font-size: (math.div($base, $default) * 1em);
$base-line-height: (math.div(18, $base) * 1em);

$headline-font-size: toBase($headline);
$headline-line-height: (math.div(60, 52) * 1em);

$h1-font-size: toBase($h1);
$h1-line-height: (math.div(54, $h1) * 1em);

$h2-font-size: toBase($h2);
$h2-line-height: (math.div(36, $h2) * 1em);

$h3-font-size: toBase($h3);
$h3-line-height: (math.div(36, $h3) * 1em);

$nav-title-font-size: toBase($h3);
$nav-title-line-height: (math.div(36, $h3) * 1em);

$pagination-font-size: toBase($pagination);
$pagination-line-height: (math.div(54, $pagination) * 1em);

// sizing
$grid-max-width: toBase(1020);
$grid-width: toBase(960);
$grid-base-padding: toBase(30);
$column-base-padding: toBase(10);
$grid-sizes: (
  "full": 100%,
  "half": 50%,
  "third": (math.div(100%, 3)),
  "fourth": 25%
);

// colors
$base-font-color: #3d3d3d;
$page-color: #f5f5f5;
$background-color: #ffffff;

$light-grey: #e6ebed;

$red: rgb(235,35,35);
$green: rgb(35,135,35);
$light-green: rgb(0, 204, 0);

$orange: rgb(255,102,0);
$orange-light: rgb(240, 152, 77);
$dark-grey: rgb(120,120,120);
$mid-grey: rgb(188,189,192);
$white: rgb(255,255,255);
$black: rgb(0,0,0);
$shaded-blue: rgb(67,127,163);
$dark-grey-blue: rgb(104,111,120);
$mid-grey-blue: rgb(195,200,206);
$light-grey-blue: rgb(230,235,237);
$light-grey-blue-t: rgba(230,235,237,0.4);
$light-grey-blue-t-solid: #F5F7F8;

$testimonial-grey: rgb(238,241,234);
$input-border-color: rgb(98,98,98);

$shadow-color: rgba(18,29,39, 0.6);

$breakpoints: (
  "mobile-portrait"     : "only screen and (max-width:425px)",
  "mobile"              : "only screen and (max-width:740px)",
  "not-mobile"          : "only screen and (min-width:741px)",
  "not-mobile-portrait" : "only screen and (min-width:426px)",
  "tablet-portrait"     : "only screen and (max-width:850px)",
  "tablet"              : "only screen and (max-width:1019px)",
  "only-tablet"         : "only screen and (min-width:741px) and (max-width:1019px)",
  "desktop"             : "only screen and (min-width:1020px)"
  // "retina"              : "only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5)",
  // "highres"             : "print, (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)",
  // "print"               : "print"
);

