@import 'style/config';

.data-list {
	dl {
		white-space: nowrap;
	}

	dt {
		display: inline;

		&:after {
			content: " ";
		}

	}

	dd {
		margin: 0;
		display: inline;

		&:after {
			content: "";
			display: block;
			width: 100%;
		}
	}
}