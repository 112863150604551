@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.cart-total {
	.column-details {
		dl {
			margin: 0;
			@include leftright(text-align, text-align, right, left);
		}

		dt {
			display: inline-block;
			@include leftright(float, float, left, right);
		}

		dt.cart-details-total,
		dt.cart-details-total + dd {
			font-size: toMobile($h3);
			line-height: $h3-line-height;

			@include breakpoint(desktop) {
				font-size: toBase($h3);
			}
		}
	}

	.column-actions {
		div:nth-child(n+2) {
			margin-top: 0.5em;
		}
		
		svg {
			fill: $base-font-color;
			height: 1.5em;
		}
	}
}
