@import 'style/config';
@import 'node_modules/sensible/mediaqueries';

.main-navigation {
	.column.navigation-column {
		background: $light-grey;
		display: none;
		@include breakpoint(desktop) {
			display: block;
			width: calc(25% + #{toBase(30)});
			@include leftright(margin-left, margin-right, toBase(-30));
			@include leftright(padding-left, padding-right, toBase(40));
		}
				
		ul {
			list-style: none;
			padding: 0;
		}

		li {
			padding: 0;

			+ li {
				padding-top: 0.25em;
			}
		}
	}

	.column.content-column {
		@include breakpoint(desktop) {
			@include leftright(padding-left, padding-right, toBase(40));
		}
	}
}