@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.message-info {
	border: 1px solid $shaded-blue;
	background: rgba($shaded-blue, 0.15);

	svg {
		fill: $shaded-blue;
	}
}