@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.search-widget {

	.search-content {
		display:none;
	}

	@include breakpoint(desktop) {
		margin: 0 10px 0 20px;	
	}
		
	@include breakpoint(desktop) {			
		.search-content {
			position: absolute;
			z-index: 1000;
			background: #fff;
			margin-left: 0px;
			width: 500px;
			margin-top: -1px;
		}
	}
		
	.search-results {
		@include breakpoint(desktop) {
			overflow: auto;
			max-height: 40vh;
		}
	}
}

.search-autosuggest {
					
	.search-content {
		position: absolute;
		z-index: 10000;
		background: #fff;
		width:100%;
		left:0px;
		margin-top: 24px;
		
		@include breakpoint(desktop) {
			width: toBase(820);
			left:50%;
			margin-top: 19px;
			margin-left: toBase(-410);
		}
		text-align: left;
		display:none;
		
		.search-result-group {
			padding:0px;
		}
		a {
			outline: 0;
		}
	}
	
}
