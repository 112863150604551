@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

header .navigation li.level-1 .panel-download .panel-content {
	width: 625px;
}

.download-widget {
	@include leftright(text-align, text-align, left, right);
	background:#fff;

	.download-heading {
		padding: toEm(10, $h3) toEm(20, $h3);
		margin: 0;
	}

	.download-entries {
		padding: toMobile(15) toMobile(20);
		overflow-y: auto;
		max-height: 250px;
		overflow-x: hidden;
		&::-webkit-scrollbar {
		    -webkit-appearance: none;
		}
		
		&::-webkit-scrollbar:vertical {
		    width: 12px;
		}
		
		&::-webkit-scrollbar-thumb {
		    background-color: rgba(0, 0, 0, .5);
		    border-radius: 10px;
		    border: 2px solid #ffffff;
		}
		
		&::-webkit-scrollbar-track {
		    border-radius: 10px;  
		    background-color: #ffffff; 
		}
		

		@include breakpoint(desktop) {
			padding: toBase(15) toBase(20);
			overflow-y: auto;
			overflow-x: hidden;
			max-height: 424px;
			
			&::-webkit-scrollbar {
		    	-webkit-appearance: auto;
			}
		}
	}

	.download-actions {
		padding: toMobile(15) toMobile(20);

		@include breakpoint(desktop) {
			padding: toBase(15) toBase(20);
		}
		
		.grid {
			display: flex;
			@include breakpoint(mobile) {
			display: block;
				a {
					font-size: 13px;
				}
				
				.column:first-child {
					padding-right:0px;
				}
			
				.column:last-child {
					text-align: left;
					padding-left:0px;
				}
			}
		}
		button, a {
			font-weight: bold;
			&.link:hover {
				text-decoration: underline;
			}
		}
	}


	.download-entries,
	.download-actions {
		border-top: 1px dashed $light-grey;
	}

	.download-actions-import {
		@include leftright(text-align, text-align, right, left);
		
		@include breakpoint(mobile) {
			text-align: left;
		}
		
		a.fake-button {
			padding-right: 0px;
			display: block;
			text-align: left;
			padding: 20px 33px;
			font-size: 13px;
			
			@include breakpoint(desktop) {
				padding: 10px 0;				
				font-size: 13px;
				text-align: right;
			}
		}
	}

	.download-entry {
		display: flex;
		padding: 15px 0 0 0;
	
		.column.column-thumbnail {
			min-width: 73px;
			max-width: 60px;
			padding-right: 0px;
			
			@include breakpoint(desktop) {
				min-width: 65px;
				max-width: 60px;
			}
			
			svg {
				width: 55px;
				height: 55px;
			}			
			
		}
		
		.column.column-text {
			svg {
				width: 23px;
				height: 23px;
			}
			
			h3 {
				line-height: 1.3em;
			}
			
			p {
				margin:0px;
			}
		}

		.column.column-price {
			font-weight: bold;

			@include breakpoint(desktop) {
				min-width: toBase(165);
				max-width: toBase(165);
				@include leftright(text-align, text-align, right, left);
				padding-top: $base-line-height;
			}
		}

		em {
			font-style: normal;
			font-weight: bold;
			color: $orange;
		}

		h3 {
			margin: 0;

			a {
				color: $base-font-color;
			}
		}
		a h3 {
			color: $base-font-color;
		}

		dl {
			margin: 0;

			dt,
			dd {
				display: inline;
			}

			dt {
				font-weight: bold;
			}

			dd {
				margin-left: 0;

				&:after {
					content: "";
					display: block;
				}
			}
		}
	}

	.toggle-on & {
		@include breakpoint(desktop) {
			width: toBase(625);
		}
	}
}
