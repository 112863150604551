@import 'style/config';
@import 'style/base/10.mixins';

$colorBackground: #f2f2f2;
$colorEmphasizeLight: #ccccda;
$colorEmphasize: #437fa3;

////////////////////////////////////////
// popup container
////////////////////////////////////////

.ppl-id-restore-cart .popup-id-popup,
.ppl-id-size-and-selection .popup-id-popup,
.ppl-id-main .popup-id-popup {
  @include breakpoint(not-mobile-portrait) {
    width: 350px;
  }
}
.ppl-id-cfrm .popup-id-popup {
  @include breakpoint(desktop) {
    width: 800px;
  }
}
.ppl-font-bold {
  font-weight: bold;
}

.ppl-font-size-default {
  font-size: 1.0em;
}

.ppl-color-emphasize {
  color: $colorEmphasize;
}

.ppl-id-link-open {
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
}

.ppl-items-min-container {
  padding-top: 1em;
}

.ppl-project-item {
  display: flex;
  align-items: normal;
  position: relative;
  margin-bottom: 0.2em;
  background-color: $colorBackground;
  &:hover {
    background-color: $colorEmphasizeLight;
  }
  .ppl-project-item-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2.5em;
    min-height: 2.5em;
    cursor: pointer;
    .checkbox-icon {
      width: 1.0em;
      height: 1.0em;
      svg {
        rect {
          stroke: $colorEmphasize;
          stroke-width: 4;
          fill: $colorBackground;
        }
        polyline {
          stroke-width: 4;
          fill: $colorBackground;
        }
        circle {
          stroke: $colorEmphasize;
          stroke-width: 5;
          fill: $colorBackground;
        }
      }
    }
  }
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 1em;
    font-weight: bold;
    width: 100%;
    word-break: break-all;
  }

  input {
    position: absolute;
    visibility:hidden;
    &:checked + .ppl-project-item-checkbox  {
      cursor: default;
      background-color: $colorEmphasize;
      .checkbox-icon svg {
        rect {
          //cursor: default;
          stroke-width: 4;
          stroke: $colorEmphasize;
          fill: $colorBackground;
        }
        polyline {
          stroke: $colorEmphasize;
        }
        circle {
          stroke-width: 2;
          fill: $colorBackground;
        }
      }
    }
    &:checked ~ label {
      background-color: $colorEmphasize;
      cursor: default;
    }
  }
}

.ppl-project-item-default {
  display: flex;
  visibility: hidden;
  display: none;
}

.ppl-accordion {
  padding-bottom: 1em;
}
.ppl-items-all-container {
  overflow-y: auto;
  max-height: 150px;
  margin-left: 0em;
  @include breakpoint(mobile) {
    margin-left: 1em;
    margin-right: 1em;
  }
}

.ppl-accordion-action {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  cursor: pointer;
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  div {
    font-weight: normal;
    color: $colorEmphasize;
    white-space: nowrap;
  }
}

.ppl-accordion-icon {
  padding-left: 1em;
  text-align: center;
  width: 15px;
  height: 15px;
  svg {
    width: 15px;
    height: 15px;
    path {
      fill: $black;
    }
  }
}

.ppl-rotate svg{
  transform: rotate(180deg);
}

.ppl-new-project-list {
  padding-top: 1em;
}

.ppl-new-project-list-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1em;

  label {
    font-weight: bold;
  }
  input {
    border-color: $mid-grey;
    @include breakpoint(mobile) {
      max-width: 9em;
    }
    @include breakpoint(tablet) {
      max-width: 10em;
    }
  }
}

.ppl-id-main-ctrls {
  margin-top: 1em;
  text-align: center;
  font-weight: bold;
  button {
    position: relative;
  }
}

.ppl-id-main-ctrls-spinner {
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ppl-cfrm {
  display: none;
}

.ppl-cfrm-lead-text {
  padding-bottom: 2em;
  color: $colorEmphasize;
  font-weight: bold;
}

.ppl-cfrm-product-img {
  cursor: pointer;
  img {
    @include breakpoint(tablet) {
      margin: auto;
    }
  }
}

.ppl-cfrm-product-title {
  @extend .ppl-color-emphasize;
  cursor: pointer;
}

.ppl-id-no-existing-projects {
  @extend .ppl-font-size-default;
  @extend .ppl-font-bold;
  margin-top: 1em;
  display: none;
}
.ppl-id-projects-loading {
  margin-top: 1em;
  @extend .ppl-font-size-default;
  @extend .ppl-font-bold;
  display: flex;
  align-items: center;
}

.ppl-id-projects-loading-spinner {
  margin-left: 1em;
}

.ppl-id-error-message {
  margin-top: 1em;
  min-height: 1em;
  @extend .ppl-font-bold;
  font-size: 0.9em;
  color: $red;
  text-align: center;
}

.ppl-cfrm .grid .column {
  &.column-thumbnail {
    min-width: 9.23077em;
    max-width: 9.23077em;
    width: 9.23077em;
  }
}

