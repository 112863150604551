@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.search-result-product {
	
	border-top:1px dashed $mid-grey;
	padding-top:toBase(20);
	margin-top:toBase(20);
	
	&:first-of-type {
		border-top:0px;	
	}
	
	.column.column-thumbnail {
		@include breakpoint(desktop) {
			min-width: toBase(100);
			max-width: toBase(100);
			width: toBase(100);
		}
	}

	dl {
		margin: 0;

		dt,
		dd {
			display: inline-block;
		}

		dt {
			font-weight: bold;
		}

		dd {
			margin-left: 0;
			margin-right: 1em;
		}
	}

	span.badge {
		display:inline-block;
		padding:0px;
		padding-left: 7px;
    	padding-right: 7px;
		background: #ff6600;
		font-size: 12px;
		color:white;
		border-radius:4px;
		line-height: 20px;
		vertical-align: top;
		margin: 7px;
	}

	.column.column-price {
		font-weight: bold;

		@include breakpoint(desktop) {
			min-width: toBase(265);
			@include leftright(text-align, text-align, right, left);
			padding-top: $base-line-height;
		}
	
		.grid.grid-cart {
			
			@include breakpoint(desktop) {
				justify-content: flex-end;
		
				.column.column-cto {
					width: auto;
				}
			}
		}
	}

	a.add-to-cart {
		color: $shaded-blue;
	}
	.column-find-replacement {
		@include breakpoint(tablet) {
			padding-top: 0.5em;
		}
		@include breakpoint(desktop) {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}
}
.grid.grid-cart {
	.column.column-cto {
		// longer texts inside the Add To Cart Button.
		button {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}
