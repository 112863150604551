@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.form-error {
	display: none;
	padding-bottom: 0.5em;

	.form-input.error + & {
		display: block;
	}
}