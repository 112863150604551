@import 'style/config';
@import 'style/base/10.mixins';


h1 {
	@include fontsize($h1-font-size);
	line-height: $h1-line-height;
	margin: factorLineHeight(0em, $h1-line-height) 0 factorLineHeight(toEm(40, $h1), $h1-line-height);

	* + & {
		margin-top: factorLineHeight(toEm(40, $h1), $h1-line-height);
	}

	&.headline {
		@include fontsize($headline-font-size);
		line-height: $headline-line-height;
		color: $white;

		margin-top: factorLineHeight(0em, $headline-line-height);
		margin-bottom: factorLineHeight(toEm(38, $headline), $headline-line-height);
}
}

h2 {
	@include fontsize($h2-font-size);
	line-height: $h2-line-height;
	margin: factorLineHeight(0em, $h2-line-height) 0 factorLineHeight(toEm(20, $h2), $h2-line-height);

	* + & {
		margin-top: factorLineHeight(toEm(20, $h2), $h2-line-height);
	}
}

h3 {
	@include fontsize($h3-font-size);
	line-height: $h3-line-height;
	margin: -0.4em 0 toEm(10, $h3);

	* + & {
		margin-top: toEm(10, $h3);
	}
}

.page-headline {
	@include breakpoint(desktop) {
		font-size: 2em;
	}
	font-size: 1.625em;
}
