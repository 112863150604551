@import 'style/config';
@import 'node_modules/sensible/mediaqueries';

.jobs {

	* + & {
		margin-top: 1em;
	}

	.column.column-text {
		@include breakpoint(desktop) {
			min-width: 65%;
		}
	}


	h3 {
		margin-top: 0.5em;
		@include breakpoint(desktop) {
			margin: 0;
		}
	}

	dl {
		margin: 0 0;
	}

	dt,
	dd {
		display: inline;
		margin: 0;
	}

	dd:after {
		content: "";
		width: 100%;
		display: block;
	}

	dt {
		font-weight: bold;
	}

	.job-url {
		display: block;
	}
	>.job {
		@include breakpoint(desktop) {
			
			&:first-child {
				margin-top: 2em;
			}
			&:not(:last-child) {
				margin-bottom: 1em;
			}
		}
	}
}

.job-details {
	.job {
		margin-top: toMobile(-40) + 0.875em;

		@include breakpoint(desktop) {
			margin-top: toBase(-40) + 0.875em;
		}
	}
}

.list-content-jobs {
	min-height: 100px;
}

