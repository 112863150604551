//@import 'config';
//@import 'base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

@include breakpoint(tablet) {
  .applied-filters {
    .facet-list-margins {
      margin-right: 1em;
    }
  }
}
.facet-nav-applied-filters-container {
  margin-top: 1em;
  margin-bottom: 0.2em;
  .facet {
    margin-top: 0;
    margin-bottom: 0;
    .facet__name {
      flex-shrink: 0
    }
  }
}