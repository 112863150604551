.bel-geoLocationBanner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 37, 37, 0.5);
  z-index: 10001;
  display: none;
}

.bel-geoLocationBanner {
  background-color: white;
  padding: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  border: lightgrey 1px solid;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10002;
  display: block;

  width: 700px;
  top: -100%;
  transition: top 0.5s ease-in-out;

  @include breakpoint(mobile) {
    width: 100%;
    left: 0;
    transform: none;
    margin: 0;
    padding: 1rem;
  }

  .bel-glb_closeIcon {
    float: right;
    cursor: pointer;
    display: inline-block;
  }

  img {
    margin: 0.5rem 3rem 2rem 3rem;
    width: 96px;
    display: inline-block;
  }

  .bel-glb_headline {
    font-size: 22px;
    font-weight: bold;
    margin: 0rem 3rem 1rem 3rem;
  }

  .bel-glb_text {
    margin: 0rem 3rem 2rem 3rem;
    line-height: 1.25;
  }

  .bel-glb_text strong {
    font-weight: bold;
  }

  .bel-glb_button {
    margin: 0 3rem 1rem 3rem;
    font-weight: bold;
    outline: 1px solid lightgray;
    border-radius: 3000px;
    padding: 1rem;
    color: black;
    display: flex;
    background-color: white;
    font-size: 16px;
    align-items: center;
    width: 572px;

    @include breakpoint(mobile) {
      width: 100%;
      margin: 0.5rem 0;
      padding: 0.5rem 1rem;
    }

    .bel-glb_button-flag {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 1rem 0 0;

      @include breakpoint(mobile) {
        width: 24px;
        height: 24px;
        min-width: 24px;
        margin: 0 0.5rem 0 0;
      }
    }
  }

  a {
    text-decoration: none;
  }
}

.bel-geoLocationBanner.show {
  top: 0;

  @include breakpoint(mobile) {
    left: 0;
  }
}

.bel-geoLocationBanner-overlay.show {
  display: block;
}
