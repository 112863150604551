@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';


.facet {
	margin-top: 60px;	
	margin-bottom: -35px;
	display: block;
	@include breakpoint(desktop) {
		margin-top: 53px;
		display: flex;
	}
	
	&:after {
    	clear: both;
    	content: " ";
	    display: block;
	}
	
	.facet__name {
		width: 200px;
		font-size: 1.53846em;
    	font-weight: bold;
		margin: 15px 0;
		@include breakpoint(desktop) {
			margin: 0px;
		}
	}
	
	ul {
		list-style: none;
		margin: 0;
		padding:0px;
		
		@include breakpoint(desktop) {
			padding: 0px 0 0 10px;
		}
		
		li {
			float: left;
			margin-bottom: 10px;
			
			@include breakpoint(desktop) {
				margin-left: 10px;
			}
			
			a,button {
				color:#fff;
				text-decoration:none;
				background: #437fa3;
				padding: 5px 10px;
				border-radius: 4px;
				
				span {
					margin-right: 7px;
    				font-size: 17px;
				}
				
				&:hover {
					background-color:#6fa4c4;
				}
			}
			button {
				height: auto;
			}
		}
	}
}

.list {
	position: relative;

	.list-settings {
		border-bottom: 1px solid $light-grey;

		@include breakpoint(desktop) {
			&.grid {
				max-width: $grid-max-width;
				width: 100vw;
				margin-left: toBase(-40);
				margin-right: toBase(-40);
			}
		}
		.panel {
			border: 0;
		}

		.panel-trigger {
			height: toMobile(60);
			line-height: toMobile(34);

			@include breakpoint(desktop) {
				height: toBase(60);
				line-height: toBase(34);
			}
		}
		.panel-trigger,
		.collapsable-trigger {
			span {
				display: block;
			}
			svg {
				height: 1em;
				display: inline-block;
			}
		}
		
		.list-filter {
			.panel-trigger {
				span {
					position:relative;
				}
				svg {
					position:absolute;
					right: -6px;
					top: 6px;
					
					@include breakpoint(desktop) {
						right: 7px;
						top: 6px;
					}
				}
			}
			.list-filter-form-items.magnolia > div > div,
			.magnolia > div {
				> span {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: auto;
					svg {
						width: auto;
						@include breakpoint(desktop) {
							margin-right: 0.55em;
							margin-inline-end: 0.55em;
						}
						position: initial;
												
					}
				}				
			}
			.list-filter-form-items.magnolia > div > div {
				span.collapsable-icon {
					position: initial;
				}
				> span svg {
					margin-right: 0.0em;
					margin-inline-end: 0.0em;
				}
			}
		}
		
		.list-pagination {
			@include breakpoint(desktop) {
				margin-left: 120px;
			}
		} 
	}

	.list-sort,
	.list-show,
	.list-mode {
		display: none;
		
		@include breakpoint(desktop) {
			white-space: nowrap;
			display: block;
		}

		ul {
			padding: 0;
			margin: 0;
			list-style: none;
		}
	}

	.list-sort,
	.list-show {
		position: relative;

		.panel-content {
			padding: 1em;
			width: 75%;
			border: 1px solid $light-grey;
			border-top: 0;
		}
	}

	.list-sort {
		@include leftright(text-align, text-align, right, left);

		@include breakpoint(desktop) {
			white-space: nowrap;
			min-width: toBase(130);
			display: block;

			.panel-content {
				right: 20px;
			}
		}
	}
	.list-sort > .panel.toggle-on > .panel-trigger svg {
		transform: rotate(180deg);
	}

	.list-pagination .list-footer,
	.list-count,
	.list-mode {
		padding-top: 1em * $mobile-desktop-factor;
		padding-bottom: 1em * $mobile-desktop-factor;
		line-height: toMobile(34);

		@include breakpoint(desktop) {
			line-height: toBase(34);
		}
	}

	.list-count {
		span {
			color: $orange;
			font-weight: bold;
		}

		@include breakpoint(desktop) {
			white-space: nowrap;
		}
		&.reset-line-height {
			line-height: initial;
		}
	}

	.list-info {
		display: flex;
		align-items: center;
		width: 100%;
		flex-direction: column;
		@include breakpoint(desktop) {
			flex-direction: row;
		}

		.list-documents-info {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: nowrap;
			align-items: center;
			
			@include breakpoint(desktop) {
				justify-content: flex-start;
			}
		}

		.list-download {
			margin-left: 1em;
			button {
				padding-top: 0;
				padding-bottom: 0;
			}
		}
		.list-pagination {
			@include breakpoint(desktop) {
				margin-left: 0px;
			}
		} 
	}
	
	.list-pagination {
		@include breakpoint(desktop) {
			white-space: nowrap;
			min-width: toBase(200);
		}
	}

	.list-mode {
		@include leftright(text-align, text-align, right, left);
		display: none;
		
		@include breakpoint(desktop) {
			@include leftright(padding-right, padding-left, toBase(40));
			white-space: nowrap;
			min-width: toBase(130);
			display: block;
		}

		li,
		a {
			display: inline-block;
		}

		svg {
			height: 1.5em;
			width: 1.5em;
			@include leftright(margin-left, margin-right, 1em);
			display: inline-block;
			fill: $base-font-color;
			vertical-align: middle;
		}

		a:hover,
		li.active {
			svg {
				fill: $orange;
			}
		}
	}

	.list-footer {
		text-align: center;
	}

	&.with-filter {
		.list-content {
			@include breakpoint(desktop) {
				@include leftright(padding-left, padding-right, toBase(240));
			}
		}
	}

	&.without-filter {
		.list-filter {
			@include breakpoint(desktop) {
				display: none;
			}
		}
		@include breakpoint(desktop) {
			.list-count {
				padding-left: 3em;
			}
		}
		.list-pagination {
			@include breakpoint(desktop) {
				margin-left: 0;
			}
		}
	}

}