@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.message-error {
	border: 1px solid $red;
	background: rgba($red, 0.15);

	svg {
		fill: $red;
	}
}