@import 'style/config';
@import 'style/base/00.setup';
@import 'node_modules/sensible/mediaqueries';


.product-cart {

	.data-list {
		text-align: right;

		@include breakpoint(desktop) {
			display: inline-block;
		}

		dt,
		dd {
			font-weight: bold;
		}

		dt {
			@include leftright(float, float, left, right);
			@include leftright(padding-right, padding-left, 0.5em);
		}

		dt.list-price {
			&,
			& + dd {
				font-weight: normal;
			}
		}
	}

	.grid.grid-cart {
		align-items: center;
		@include flexbox();

		@include breakpoint(tablet) {
			flex-wrap: wrap;
		}
		.column.column-quantity {
			max-width: 40%;

			@include breakpoint(desktop) {
				width: toBase(130);
				max-width: toBase(130);
			}
		}
		.column.column-cto {
			width: toBase(165);

			@include breakpoint(desktop) {
				width: toBase(135);
			}
		}
		.column.column-add-to-project-link {
			width: toBase(150);
		}

		&.long-texts {
			@include breakpoint(desktop) {
				display: flex;

				.column.column-cto {
					width: auto;
					button {
						max-width: 12em;
					}
				}
			}
		}

	}
}