.intro {
	padding: toMobile(40) 0 0 0;

	@include breakpoint(desktop) {
		padding: toBase(40) 0 0 0;
		position: relative;
	}

	&.grid + .with-border-top {
		margin-top: toMobile($content-padding);

		@include breakpoint(desktop) {
			margin-top: toBase($content-padding);
		}
	}

	&.grid.without-abstract {
		h2 {
			margin-bottom: factorLineHeight(0em, $h2-line-height)
		}
	}

	.article & {
		padding: 0;
	}

	.intro-title {
		margin-bottom: toEm(40, $h2);
	}
}