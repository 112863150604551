@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

header .navigation li.level-1 .panel-cart .panel-content {
	width: 625px;
}

.cart-widget {
	@include leftright(text-align, text-align, left, right);
	background:#fff;

	.cart-heading {
		padding: toEm(10, $h3) toEm(20, $h3);
		margin: 0;
	}

	.cart-entries {
		padding: toMobile(15) toMobile(20);
		
		&::-webkit-scrollbar {
		    -webkit-appearance: none;
		}
		
		&::-webkit-scrollbar:vertical {
		    width: 12px;
		}
		
		&::-webkit-scrollbar-thumb {
		    background-color: rgba(0, 0, 0, .5);
		    border-radius: 10px;
		    border: 2px solid #ffffff;
		}
		
		&::-webkit-scrollbar-track {
		    border-radius: 10px;  
		    background-color: #ffffff; 
		}

		@include breakpoint(desktop) {
			padding: toBase(15) toBase(20);
			overflow: auto;
			max-height: 45vh;
			
			&::-webkit-scrollbar {
		    	-webkit-appearance: auto;
			}
		}
		>.cart-entry {
			&.cart-empty {
				white-space: nowrap;
			}
		}
	}

	.cart-actions {
		padding: toMobile(15) toMobile(20);

		@include breakpoint(desktop) {
			padding: toBase(15) toBase(20);
		}
		
		.grid {
			display: flex;
			@include breakpoint(mobile) {
				display: block;
			}
		}

		button, a {
			font-weight: bold;
			&.openProjectList:hover,
			&.link:hover {
				text-decoration: underline;
			}
		}
	}


	.cart-entries,
	.cart-actions {
		border-top: 1px dashed $light-grey;
	}

	.cart-actions-import {
		@include leftright(text-align, text-align, right, left);
		
		@include breakpoint(mobile) {
			text-align: left;
		}
		
		a.fake-button {
			padding-right: 0px;
			display: block;
			padding-top: 0;
			font-size:13px;
			
			&:last-child {
				padding-bottom:0px;
			}
		}
	}

	.cart-entry {
	
		padding: 15px 0 0 0;
		display: grid;
		grid-template-columns: 100px auto auto;
		
		.column {
			padding: 0 1em;
		}

		.column.column-thumbnail {
			@include breakpoint(desktop) {
				min-width: toBase(100);
				max-width: toBase(100);
				width: toBase(100);
			}
			padding-right:0px;
		}

		.column.column-price {
			font-weight: bold;
			font-size: 13px;
			text-align: right;

			@include breakpoint(desktop) {
				min-width: toBase(165);
				max-width: toBase(165);
				@include leftright(text-align, text-align, right, left);
			}
		}

		em {
			font-style: normal;
			font-weight: bold;
			color: $orange;
		}

		h3 {
			margin: 0;

			a {
				color: $base-font-color;
			}
		}
		a h3 {
			color: $base-font-color;
		}

		dl {
			margin: 0;

			dt,
			dd {
				display: inline;
			}

			dt {
				font-weight: bold;
			}

			dd {
				margin-left: 0;

				&:after {
					content: "";
					display: block;
				}
			}
		}
	}

	.toggle-on & {
		@include breakpoint(desktop) {
			width: toBase(625);
		}
	}
}
