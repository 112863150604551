@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.orders {
	.order-item {
		dl {
			margin: 0;
		}

		dt {
			font-weight: bold;
		}
	}

	.button-list {
		width: 180px;
		a {
			width: 195px;
			@include breakpoint(desktop) {
				width: 160px;
			}
			margin-bottom: 0.2em;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
