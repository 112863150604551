.form-input-text,
.form-input-password {
	padding: 0.5em 0;

	label {
		span {
			display: inline-block;
		}
	}

	input {
		width: 100%;
		
		&[disabled] {
			background-color: #eee;		
		}
	
		&[readonly] {
			background-color: $light-grey;
		}
	}

	textarea {
		width: 100%;
		display: block;

		&[disabled] {
			background-color: #eee;		
		}
	
		&[readonly] {
			background-color: $light-grey;
		}
	}

	+ .form-info,
	+ .form-error {
		margin-top: calc(-1 * (0.5em + 1px));

		.message {
			border-top: 0;
		}
	}
}