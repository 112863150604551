@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.cart-line-item-note {
  width: 100%;
  margin-top: 1em;
  input {
    width: 100%;
  }
}