@import 'style/config';

.link {
	display: inline-block;
	font-size: 1.2em;
	@include leftright(padding-left, padding-right, ($base-line-height + 0.5em));

	a,
	em {
		vertical-align: bottom;
	}

	svg,
	em {
		display: inline-block;
	}

	svg {
		height: $base-line-height;
		width: $base-line-height;
		fill: $base-font-color;
		vertical-align: middle;
		@include leftright(margin-left, margin-right, (-1 * ($base-line-height + 0.5em)));
		@include leftright(margin-right, margin-left, 0.25em);
	}

	em {
		font-style: normal;
		@include leftright(margin-left, margin-right, 0.25em);
	}
}