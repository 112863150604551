@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.cart-entries-list {
	margin: 0;
	padding: 0;
	list-style: none;

	@include breakpoint(desktop) {
		margin-top: 1em;
	}

	.cart-entry {
        @include negateGrid();
		margin-bottom: 2em;

		&.cart-empty {
			font-style: italic;
			text-align: center;
		}

		+ .cart-entry {
			padding-top: 2em;
			border-top: 1px dashed $light-grey;
			@include breakpoint(mobile) {
				margin-top: 3.5em;
			}
		}

		dl {
			margin: 0;
		}

		dt {
			font-weight: bold;
			display: inline-block;

			&:after {
				content: " ";
			}

		}

		dd {
			margin: 0;
			display: inline;

			&:after {
				content: "";
				display: block;
			}
		}

		.form-input {
			padding: 0;
		}

		div[data-label]:before {
			content: attr(data-label);
			font-weight: bold;

			@include breakpoint(desktop) {
				display: none;
			}
		}
	
		.column-quantity {
			
			.form-input-number {
				width: auto;
			}
			
			button {
				svg {
					display: inline-block;
					width: 1em;
					height: 1em;
					fill: $white;
				}
			}
		}

		.column-price {
			dl {
				@include leftright(text-align, text-align, right, left);
				min-height: 76px;
			}

			dt {
				font-weight: normal;
				@include leftright(float, float, left, right);
			}
			
			dt:not(.list-price) {
				&,
				+ dd {
					font-weight: bold;
				}
			}
			
			.tagging-fee ~ .tagging-fee {
			    width: 100%;
			    text-align: left;
			}
		}

		.column-total {
			font-weight: bold;
		}

		.item-tags {
			white-space: nowrap;
		}

		.column-details {
			overflow: hidden;
		}
	}

	.message {
		margin-top: 1em;
	}

	.cart-entry-extras {
		padding-top: 1em;
		margin-bottom: 3em;

		.cart-tab-label {
			padding: 1em;
			
			@include breakpoint(desktop) {
				display: inline-block;
				padding: 0;

				+ .cart-tab-label {
					margin-left: 1em;
				}
			}

			a {
				color: $base-font-color;
				text-decoration: none;
				cursor: pointer;				
			}

			svg {
				fill: $base-font-color;
				width: 1.5em * $mobile-desktop-factor;
				height: 1.5em * $mobile-desktop-factor;
				vertical-align: bottom;

				@include breakpoint(desktop) {
					width: 1.5em;
					height: 1.5em;
				}
			}
		
			&.not-available {
				color: $mid-grey;
				
				svg {
					fill: $mid-grey;
				}
			}

			&:hover,
			&.toggle-on {
				
				color: $orange;
				
				a {
					color: $orange;
				}
			
				svg {
					fill: $orange;
				}
				
				&.not-available {
					color: $mid-grey;
					
					svg {
						fill: $mid-grey;
					}
				}
			}
		
			
		}
		

		.tab-contents {
			.cart-tab-label {
				font-weight: bold;

				@include breakpoint(desktop) {
					display: none;
				}
			}

			* + .cart-tab-label {
				border-top: 1px dashed $light-grey;
			}
		}
	}

	.documents {
		margin-top: 1em;
		//margin-bottom: -2em; removed this 11.10.19, Don't see the purpose + it causes trouble
	}

	.image {
		max-width: toMobile(180);
		min-width: toMobile(180);
		width: toMobile(180);
	}
}