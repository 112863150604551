
@include breakpoint(tablet) {	
	header > .secondary {
		display:none;
	}
}

.navigation {

	li.level-1 {
		@include breakpoint(desktop) {
			display: inline-block
		}
	}

	@include breakpoint(desktop) {
		height: toBase(70);
	}

	.panel {
		border: 0;

		.panel-trigger {
			@include fontsize($nav-title-font-size);
			color: $base-font-color;
			line-height: $nav-title-line-height;
			font-weight: bold;
			padding: 0;
			white-space: nowrap;
			padding: 0 0.5em;

			&:hover {
				color: $orange;
				text-decoration: none;
			}

			svg {
				width: 1.25em;
				height: 1.25em;
				visibility: hidden;
				margin-bottom: -0.25em;
				margin-left: -1.25em;
			}

			span {
				display: inline-block;
				@include leftright(padding-left, padding-right, 0.5em);
			}
		}
		
		&.sublevel {
			.panel-trigger span svg {
				visibility: hidden;
			}
		
			span {
				@include leftright(padding-left, padding-right, 1.25em);
			}
		}

		&.toggle-on > .panel-trigger {
			color: $orange;
			@include breakpoint(desktop) {
				svg {
					fill: $orange;
					visibility: visible;
				}
			}
		}

		.panel-content {
			background-color: transparent;
			left: 0;
			top: 100%;
			width: 100%;
			@include leftright(text-align, text-align, left, right);

			@include breakpoint(desktop) {
				border-top: 1px solid $light-grey;
			}
		}
	}
		
	.navigation-teaser {
		img {
			width:100%;
		}
	}

	.navigation-row {
		@include breakpoint(desktop) {
			width: 100%;
			max-width: 100%;
		}
		
		&.right-align {
			justify-content: flex-end;
			
			.navigation-column {
				@include breakpoint(desktop) {
					&:first-child {
						border-left: 1px solid $light-grey;
						padding-left: .76923em !important;
					}
				}
			}
		}

		&:not(:first-child) {
			@include breakpoint(desktop) {
				border-top: 1px solid $light-grey;
			}
		}

		.column &.grid {
			@include breakpoint(desktop) {
				margin-left: 0;
				margin-right: 0;

				.column:first-child {
					@include leftright(padding-left, padding-right, toBase(40));
				}

				.column:last-child {
					@include leftright(padding-right, padding-left, toBase(40));
				}
			}
		}
	}
	
	@include breakpoint(tablet) {
	
		.collapsable .collapsable-trigger {
			margin-bottom:0px !important;
			position: relative;
			cursor:pointer;
		}
		
		
		.collapsable:not(.no-children) .collapsable-trigger {				

			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-right: 1.2em;
			&:after {
				height:20px;
				width:20px;
				content: " ";
				background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 15 15"><path d="M15,3.75l-7.5,7.5-7.5-7.5Z"/></svg>');
				display:inline-block;
			}
		}
		
		.collapsable.toggle-on .collapsable-trigger {
			margin-bottom:0.5em !important;
			&:after { 
				transform:rotateX(180deg);
				filter: invert(100%);
			}
		}
		
		.collapsable.meta-list {
			padding: 0.5em 1.5em 0.5em 3.5em;
		}
	}

	.navigation-column {
		background-color: #fff;

		@include breakpoint(desktop) {

			&:not(:first-child) {
				border-left: 1px solid $light-grey;
			}

			&:hover:not(.navigation-teaser) {
				color: $white;
				background-color: $orange;

				a,
				small,
				.collapsable-trigger {
					color: $white;
				}
			}
		}
	
		.navigation-list {
			&.with-icon > .collapsable-trigger {				
				background-repeat: no-repeat;
				background-size: initial;
				background-position-y: center;
				padding-left:0px;
				
				h3 {				
					a {
						@include leftright(background-position, background-position, left 7px, right 7px);
						@include leftright(padding-left, padding-right, toMobile(55));
						padding-top: 20px;
						padding-bottom: 10px;
						
						@include breakpoint(desktop) {
							@include leftright(background-position, background-position, left top, right top);
							@include leftright(padding-left, padding-right, toBase(35));
						}
						white-space: nowrap;
					}
				}
			}
			

			&.toggle-on svg {
				fill: $white;
			}

			.collapsable-trigger {
				cursor:default;
				margin-bottom: 1em;
				padding-top: 1em;

				.collapsable-icon {
					@include leftright(float, float, right, left);

					@include breakpoint(desktop) {
						display: none;
					}
				}
			}
		}
	
		&.navigation-teaser {
			padding: 0px !important;

			.image {
				@include breakpoint(tablet) {
					width: 100%;
					
					img {
						width: 100%;
					}
				}
			}
		}
	}

	ul.list-level-1 {
		@include leftright(text-align, text-align, right, left);

		@include breakpoint(tablet) {
			margin-left: (-2 * $column-base-padding);
			margin-right: (-2 * $column-base-padding);
			padding-left: (2 * $column-base-padding);
			padding-right: (2 * $column-base-padding);
			width: auto;
		}
	}
	
	.icons {
		display:none;
		padding: 0px;
	
		@include breakpoint(desktop) {
			display:block;
			z-index: 10000;
			@include leftright(margin-right, margin-left, 0);

			.panel { 
				.panel-trigger {				
					padding: 0px !important;
					
					svg {
						display: block;
						visibility: visible;
						transition: all .5s;
						margin:0px;
					}
				}			
			}
			
			.panel-content {
				z-index: 1000000;
				margin-left: -560px;
				width: 625px;
				margin-top: 18px;
				left: initial;
				top: initial;
			}			
			
			.panel-download {
				margin:0 20px 0 0;
				
				.panel-trigger {
					svg {
						width: 1.5em;
						height: 1.5em;
						margin-top: -0.25em; 
					}
				}					
			}
			
			.panel-download,
			.panel-cart {
				height: 69px;
				
				.panel-trigger {
					position: relative;
					
					span {
						padding:0px !important;
					}

					span[data-bind] {
						position: absolute;
						background-color: $orange;
						display: block;
						min-width: 2em;
						width: auto;
						height: 2em;
						line-height: 2em;
						font-size: 0.60em;
						color: $white;
						font-weight: bold;
						border-radius: 1em;
						top: 0.75px;
						right: -1.25em;
						z-index: 2;
						text-align:center;

						padding-left: 2px !important;
						padding-right: 2px !important;
					}
					
					svg {
						position: relative;
						z-index: 1;
						left: 0px;
						top: 10px;
					}
				}
				
				.panel-content {
					top: 51px;
				}

				.download,
				.cart & {
					display: none;
				}
			}

			&.invert:not(:hover) {
				border-color: transparent;

				.panel {
					background-color: #fff;
					border-color: transparent;

					+ .panel {
						border-top: 1px solid $white;
					}

					.panel-trigger svg {
						fill: $white;
					}
				}
			}
		}
	}
}

html[dir=rtl], header[dir=rtl] {
	@include breakpoint(desktop) {
		.navigation {
			.navigation-column {
				border-left: 1px solid $light-grey;
				&:last-child {
					border-left: 0px solid $light-grey;
				}
			}
		}
		.icons {
			.panel-download {
				margin:0 0 0 20px;
			}
		}
	}
}