@import 'style/config';


.cta-button-group-wrapper {
  display: flex;
  flex-direction: column;
  .cta-button-group {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &.column-left {
      align-items: flex-start;
    }
    &.column-right {
      align-items: flex-end;
      align-self:flex-end;
    }
    &.column-center {
      align-items: center;
      align-self: center;
    }
    &.column-stretch {
      align-items: stretch;
      .cta-button-group-item {
        width: 100%;
        .cta-button {
          width: 100%;
          .fake-button {
            width: 100%;
          }
        }
      }
    }
    &.column-align:not(.column-stretch) {
      width: fit-content;
      .cta-button-group-item {

          width: 100%;
          @include breakpoint(tablet) {
            width: 100% !important;
          }
        
        .cta-button {

          .fake-button {
            width: 100%;
          }
        }
      }
    }
    .cta-button-group-item {
      &:not(:first-child) {
        margin-top: 1em;
      }
    }

    &.column-v-margin-n > .cta-button-group-item:not(:first-child) {
      margin-top: 0;
    }
    &.column-v-margin-s > .cta-button-group-item:not(:first-child) {
      margin-top: 0.5em;
    }
    &.column-v-margin-m > .cta-button-group-item:not(:first-child) {
      margin-top: 1em;
    }
    &.column-v-margin-l > .cta-button-group-item:not(:first-child) {
      margin-top: 1.5em;
    }      
    &.column-v-margin-xl > .cta-button-group-item:not(:first-child) {
      margin-top: 2em;
    }             
    &.column-v-margin-xxl > .cta-button-group-item:not(:first-child) {
      margin-top: 3em;
    } 
    .fake-button {
      display: flex;
      white-space: nowrap;
      overflow: hidden;

    }
    &.column-text-center .fake-button {
      justify-content: center;
      text-align: center
    }
    &.column-text-left .fake-button {
      justify-content: flex-start;
    }
    &.column-text-right .fake-button {
      justify-content: flex-end;
      text-align: right;
    } 
  }
}

@include breakpoint(tablet) {
  .cta-button-group-wrapper {
    .cta-button-group {
      &.cta-row {
          .cta-button-group-item {
            width: auto !important;
          }
      }
    }
  }
}

@include breakpoint(desktop) {
  .cta-button-group-wrapper {
    .cta-button-group {
      &.cta-row {
        width: 100% !important;
        flex-direction: row;
        
        &:not(.row-align) {
          .cta-button-group-item {
            width: auto !important;
          }
        }

        .cta-button-group-item {
          margin-right: 0.5em;
          width: auto;
          &:not(:first-child) {
            margin-top: 0;
            margin-right: 0;
            margin-left: 0.5em;
          }
          .cta-button {
            width: auto;
            .fake-button {
              width: auto;
            }
          }       
        }
        
        &.row-left {
          justify-content: flex-start;
        }
        &.row-right {
          justify-content: flex-end;
        }
        &.row-space-around {
          justify-content: space-around;
        }
        &.row-space-between {
          justify-content: space-between;
        }
        
        &.row-align {
          .cta-button {
            width: 100%;
            .fake-button {
              width: 100%;
            }
          } 
        }

        &.row-text-center .fake-button {
          justify-content: center;
          text-align: center
        }
        &.row-text-left .fake-button {
          justify-content: flex-start;
          text-align: left;
        }
        &.row-text-right .fake-button {
          justify-content: flex-end;
          text-align: right;
        } 
      }
    }
  }
}
