@import 'style/config';
@import "node_modules/sensible/mediaqueries";

.stage-article {
	width: 100%;
	position: relative;
	height: 350px;


	.image:before {
		top: 0;
		left: 0;
		content: "";
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		//background-image: linear-gradient(to bottom, $shadow-color 0%, transparent 50%);
		//background: transparent url('../image/fade_top.png') repeat-x left top;
		z-index: 1;
	}

	.grid,
	.column {
		height: 100%;
	}

	.stage-article-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.stage-article-text {
		position: relative;
		z-index: 1;
	}

	.stage-image-headline {
		font-size: 2.5em;
		@include breakpoint(desktop) {
			font-size: 4em;
		}
		line-height: $headline-line-height;
		color: $white;
		text-shadow: 0 0 43px $shadow-color;

		box-sizing : border-box;
		display: block;
		font-weight: 700;
		height: 36px;
		margin-bottom: 28px;
	}


}


