@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.list-filter {
	padding-left: 0;
	padding-right: 0;

	&.column {
		@include breakpoint(desktop) {
			max-width: toBase(270);
			min-width: toBase(270);
			width: toBase(270);
		}
	}

	.panel {
		position: relative;
		background-color: $shaded-blue;

		@include breakpoint(desktop) {
			position: static;
			width: toBase(260);
		}

		&.toggle-on .panel-trigger {
			svg {
				transform: rotate(180deg);
			}
		}

		.panel-trigger {
			color: $white;
			padding-left: 2 * $column-base-padding;
			padding-right: 2 * $column-base-padding;

			@include breakpoint(desktop) {
				padding: toBase(12) 0;
				@include leftright(padding-left, padding-right, toBase(40));
			}

			svg {
				fill: $white;
			}

			&:hover svg {
				fill: $white;
			}
		}

		.panel-content {
			width: 100%;
			border-bottom: 1px solid $light-grey;
			position: relative;

			@include breakpoint(desktop) {
				position: absolute;
				padding: 0;
				@include leftright(left, right, toBase(-40));
				top: toBase(61);
				width: toBase(260);
				@include leftright(border-right, border-left, 1px solid $light-grey);
			}
		}
	}

	.collapsable {
		padding: toMobile(10);
		padding-left: 2 * $column-base-padding;
		padding-right: 2 * $column-base-padding;

		@include breakpoint(desktop) {
			padding: toBase(10);
			@include leftright(padding-left, padding-right, toBase(40));
			border-top: 0;
		}

		&.list-filter-form {
			border-top: 1px solid $light-grey;
		}

		&.list-filter-form+.collapsable.list-filter-form,
		&.list-show-form {
			border-top: 1px dashed $light-grey;
		}

		&.toggle-on .collapsable-trigger {
			svg {
				transform: rotate(180deg);
			}
		}

		.collapsable-trigger {
			font-weight: bold;
		}
	}

	.panel-trigger,
	.collapsable-trigger {

		span:not(.collapsable-icon) {
			@include fontsize($h3-font-size);
			line-height: toEm(34, $h3);
		}

		.collapsable-icon {
			@include leftright(float, float, right, left);
		}
	}

	.panel-trigger svg {
		@include leftright(float, float, right, left);
	}

	.list-sort-form,
	.list-show-form {
		@include breakpoint(desktop) {
			position: absolute;
			@include leftright(left, right, -200vw);
		}
	}

	.form,
	form {
		height: 100%;

		.form-input-checkbox {
			clear: both;
		}
	}

	.list-filter-form-items {
		overflow-y: auto;

		@include breakpoint(desktop) {
			.collapsable-trigger {
				span {
					position: relative;
					width: 180px;

					span.collapsable-icon {
						position: absolute;
						right: -24px;
						top: 0px;
						display: block;
						width: auto;
					}
					
				}
			}
		}

		.button-with-icon-container {
			width: 100%;
			height: 3.0em;
			padding: 0.0em 0.76923em 0.0em 1.53846em;
			margin: 1em 0;

			@include breakpoint(tablet) {
				padding: 0.0em 1.53846em;
			}

			button {
				display: flex;
				align-items: center;
				width: 100%;
				background-color: $orange;
				padding: 0.5em 0.5em;

				div {
					&:first-child {
						width: 1.5em;
						height: 1.5em;
						margin-left: 0.5em;

						svg {
							width: 1.4em;
							height: 1.4em;
							fill: $white;
						}
					}

					&:last-child {
						font-weight: bold;
						width: 100%;
					}
				}

				&:hover {
					background-color: $orange-light;
				}
			}
		}
	}

	.list-filter-form-actions {
		.form-button {
			display: block;
			padding: 0;
			border-top: 1px solid $light-grey;
			overflow-y: hidden; // Added this, because without this, this caused the parent diff from displaying a scrollbar on Safari?!

			a,
			button {
				padding-left: 1.24em;
				padding-right: 1.24em;
				width: 100%;
				@include fontsize($h3-font-size);
				line-height: $h3-line-height;
				height: toEm(60, $h3);
				display: inline-block;
				text-align: left;

				@include breakpoint(desktop) {
					padding: 0.5em;
					@include leftright(padding-left, padding-right, 2em);
					border-top: 0;
				}


				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	.filter-subcategories {
		border-left: 1px solid $mid-grey;
		.form-input.form-input-checkbox {
			padding: 0.15em 0;
		}
	}

}

.generic-list-wrapper {
	.form-input-checkbox {
		label {
			display: flex;
			justify-content: space-between;
			.generic-list-item-count {
				padding-left: 0.5em;
				&::before, &::after {
					content:none;
				}
				word-break: normal;
			}
			span {
				word-break: break-word;
			}
		}
	}
}