.social-links {
	position: absolute;
	z-index: 10000;
	@include leftright(right, left, 200vw);

	.with-overlay & {
		z-index: 9999;
	}

	&.toggle-on {
		@include leftright(right, left, auto);
		padding: 1em;
		width: auto;
		transform: translate(0, -110%);
		border: 1px solid $light-grey;
		box-shadow: 0 0 5px $light-grey;
		
		@include no-flexbox(inline-block);
	}

	.article & {
		position: static;
		@include content();
		z-index: 0;
	}
	
	a {
		color: $base-font-color;
		text-decoration: none;
		display: inline-block;
		width: 2em;
		height: 2em;
		@include leftright(margin-right, margin-left, toMobile(20));

		&:hover {
			color: $orange;

			svg {
				fill: $orange;
				
				path,polygon {
					fill: $orange !important;
				}
			}
		}
	}

	svg {
		width: 100%;
		fill: $base-font-color;
	}
}