@import 'style/config';

@include breakpoint(mobile) {
  .page-accountAdmin .column.content.three-fourth.content-column {
    padding-top: 0;
  }
}

#belimoAdminNewCustomerForm {
  .role-select-info-text {
    margin-top: .875em;
  }
}

.account-admin-form-ctrls.form-control.form-button {

  $default-height: 2em;
  @mixin ctrls {
    width: 100%;
    .form-search-ctrls {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .new-user-ctrls {
      display: flex;
      justify-content: flex-end;
      margin-top: 1em;
    }
  }

  @include breakpoint(mobile) {
    @include ctrls;
    button {
      display: flex;
      align-items: center;
      height: $default-height;
    }
  }
  @include breakpoint(not-mobile) {
    @include ctrls;
    .new-user-ctrls {
      height: 100%;
    }
    button {
      display: flex;
      align-items: center;
      height: $default-height;
    }
  }
}


#belimoAccountAdministratorForm {

  $default-height: 2em;

  @mixin form-select-box {
    > .form-select {
      @include breakpoint(not-mobile) {
        width: 20%;
      }
      .select2-container--default .select2-selection--single {
        height: $default-height;
        line-height: $default-height;
      }
      .select2-container--default .select2-selection--single .select2-selection__rendered {
        line-height: $default-height;
      }
    }
  }

  @include breakpoint(mobile) {

    .admin-search-inputs {
      @include form-select-box;
      input {
        height: $default-height;
      }
      .form-input-text, .form-select {
        padding: 0.5em 0 0 0;
        flex-grow: 1;
        margin-right: 0;
      }
      .form-input > label > span {
        &:first-child {
          font-size: 0.8em;
          line-height: 0.8em
        }
      }
    }
    button {
      display: flex;
      align-items: center;
      height: $default-height;
    }
  }
  @include breakpoint(not-mobile) {
    .admin-search-inputs {
      display: flex;
      flex-wrap: wrap;
      .form-row, > .form-select {
        width: 35%;
        margin-right: 1em;
      }
      @include form-select-box;
      input {
        height: $default-height;
      }
      .form-input-text, .form-select {
        padding: 0.5em 0 0 0;
        flex-grow: 1;
        margin-right: 0;
      }

      .form-input > label > span {
        &:first-child {
          font-size: 0.9em;
        }
      }
    }
  }
}

.account-administration {

  $default-height: 2em;

  .center-content {
    display: flex;
    justify-content: center;
  }

  @mixin user {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
  }
  @mixin user-info {
    width: calc(50% - 0.5rem);
    overflow: hidden;
    margin-right: 0.5rem;
  }
  @mixin user-details {
    display: flex;
    overflow: hidden;
    align-items: center;
    width: 50%;
  }
  @mixin user-permissions {
    width: 50%;
  }
  @mixin user-account-state {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0.5rem 1rem;
    width: 50%;
    flex-shrink: 1;
    overflow: hidden;
    button {
      display: flex;
      align-items: center;
      min-height: $default-height;
      padding-top: 0;
      padding-bottom: 0;
      margin: 0.25em 0;
    }
  }
  @mixin user-bg-colors {
    &:nth-child(even) {
      background-color: $light-grey-blue;
      .user-permissions > div:nth-child(odd) {
        background-color: $light-grey-blue-t-solid;
      }
    }
    &:nth-child(odd) {
      background-color: $light-grey-blue-t;
      .user-permissions > div:nth-child(odd) {
        background-color: $light-grey-blue;
      }
    }
  }

  @include breakpoint(mobile) {

    .user-heading {
      display: none;
    }
    .user {
      padding: 0.5em 0.2em;
      margin-bottom: 1em;
      @include user-bg-colors;

      .user-info {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        > div {
          overflow: hidden;
          max-width: 95%;
          .user-info-title {
            font-size: 0.8em;
          }
          .user-info-value {
            font-weight: bold;
          }
        }
      }
      .user-details {
        padding-top: 0.5em;
        .user-permissions {
          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            > span {
              max-width: 80%;
              overflow: hidden;
            }
            form {
              padding-left: 1em;
              cursor: pointer;
              transform: translateY(3px);

              label {
                cursor: pointer;
              }
              .form-input-checkbox {
                padding: 0;
              }
            }
          }
        }
        .user-account-state {
          padding-top: 0.8em;
          display: flex;
          justify-content: flex-start;
          span {
            font-weight: bold
          }
          button {
            min-height: $default-height;
            padding-top: 0;
            padding-bottom: 0;
            height: initial;
            margin: 0.25em 0.5em 0.25em 0;
          }
        }
      }
    }
  }
  @include breakpoint(not-mobile) {

    .user-heading {
      @include user;
      background-color: $shaded-blue;
      color: $white;
      font-weight: bold;
      height: 2.5em;

      .user-info-heading {
        @include user-info;
      }
      .user-details-heading {
        @include user-details;
        .user-permissions-heading {
          @include user-permissions;
        }
        .user-account-state-heading {
          @include user-account-state;
        }
      }
    }
    .user {
      @include user;
      @include user-bg-colors;
      .user-info {
        @include user-info;
          .user-info-title {
        }
        .user-info-value {
          font-weight: bold;
        }
      }

      .user-details {
        @include user-details;
        .user-permissions {
          @include user-permissions;
          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            > span {
              overflow: hidden;
              max-width: 75%;
            }
            form {
              padding-left: 1em;
              cursor: pointer;
              transform: translateY(1px);
              @include breakpoint(tablet) {
                transform: translateY(3px);
              }
              label {
                cursor: pointer;
              }
              .form-input-checkbox {
                padding: 0;
              }
            }
          }
        }
        .user-account-state {
          @include user-account-state;
          flex-direction: column;
          align-items: flex-end;
          .user-pending {
            font-weight: bold;
          }
          button {
            height: initial;
          }
        }
      }

    }
  }
}