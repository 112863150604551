@import 'style/config';
@import "node_modules/sensible/mediaqueries";

.grid {

	@include breakpoint(desktop) {
		@include flexbox();

		max-width: $grid-width;

		&.container {
			width: 100vw;
			max-width: $grid-max-width;
			margin: 0 auto;
			padding-left: $grid-base-padding;
			padding-right: $grid-base-padding;
		}
	}
}