@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.newsletter {
	@include content();

	label {
		@include fontsize($h3-font-size);
		display: block;
		padding-bottom: 2em;
		width: 100%;
	}

	.newsletter-form {
		@include flexbox();
		flex-wrap: nowrap;
		flex-direction: column;
		@include breakpoint(desktop) {
			flex-direction: row;
		}
		
		.newsletter-input {
			@include leftright(text-align, text-align, right, left);
		}
		

		input {
			@include fontsize($h3-font-size);
			width: 100%;

			@include breakpoint(desktop) {
				width: toEm(430, $h3);
			}
		}

		button {
			@include fontsize($h3-font-size);
			@include breakpoint(mobile) {
				width:100%;
				margin-top: 10px;
			}
		}

		.newsletter-button {
			@include leftright(text-align, text-align, left, right);
		}
	}
}