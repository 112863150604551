@use 'sass:math';
@import 'style/config';
@import 'node_modules/sensible/mediaqueries';

@mixin margins($margin, $default: 0) {
	margin: $default;
	@include margin-before($margin);
	@include margin-after($margin);
}

@mixin margin-before($margin) {
	&:not(:first-child) {
		margin-top: $margin;
	}
}

@mixin margin-after($margin) {
	&:not(:last-child) {
		margin-bottom: $margin;
	}
}

@mixin content($padding: $content-padding) {
	@include contentTop($padding);
	@include contentBottom($padding);
}

@mixin contentTop($padding: $content-padding) {
	padding-top: toMobile($padding);

	@include breakpoint(desktop) {
		padding-top: toBase($padding);
	}
}

@mixin contentBottom($padding: $content-padding) {
	padding-bottom: toMobile($padding);

	@include breakpoint(desktop) {
		padding-bottom: toBase($padding);
	}
}

@mixin column($type, $columns: 1) {
	// Get the width from the keyword `$grid-sizes`
	// Or `null` if the keyword doesn't exist in `$grid-sizes` map
	$value: map-get($grid-sizes, $type);

	// If `$type` exists as a key in `$grid-sizes`
	@if $value != null {
		// output width
		width: #{$columns * $value};
	}
	// If `$type` doesn't exist in `$grid-sizes`,
	// Warn the user and do nothing
	@else {
		@warn "Invalid column type `#{$type}`.";
	}
}

@mixin skipColumn($type, $columns: 1) {
	// Get the width from the keyword `$grid-sizes`
	// Or `null` if the keyword doesn't exist in `$grid-sizes` map
	$value: map-get($grid-sizes, $type);

	// If `$type` exists as a key in `$grid-sizes`
	@if $value != null {
		// output margin
		@include leftright(margin-left, margin-right, #{$columns * $value});
	}
	// If `$type` doesn't exist in `$grid-sizes`,
	// Warn the user and do nothing
	@else {
		@warn "Invalid column type `#{$type}`.";
	}
}

@mixin fontsize($size) {
	// Multiply the `$size` with `$mobile-desktop-factor`
	// to get a mobile friendly font-size
	font-size: $mobile-desktop-factor * $size;

	@include breakpoint(desktop) {
		font-size: $size;
	}
}

// @mixin without-flexbox() {
// }

@mixin flexbox() {

	display: -webkit-box;
	display: -moz-box;
	
	display: -ms-flexbox;
	display: flex;
}

@mixin no-flexbox($value: block) {
	display: $value;
}

@mixin flex($grow: 0, $shrink: 1, $size: auto) {
	
	flex: $grow $shrink $size;
}

@mixin no-flex($value: block) {
		display: $value;
}

@mixin leftright($left, $right, $value, $flipvalue: null) {

	html:not([dir=rtl]) & {
		#{$left}: $value;
	}
	html[dir=rtl] & {
		@if $flipvalue != null {
			#{$right}: $flipvalue;
		} @else {
			#{$right}: $value;
		}
	}
	header & {
		#{$left}: $value;
	}
	header[dir=rtl] & {
		@if $flipvalue != null {
			#{$right}: $flipvalue;
		} @else {
			#{$right}: $value;
		}
	}
	footer:not([dir=rtl]) & {
		#{$left}: $value;
	}
	footer[dir=rtl] & {
		@if $flipvalue != null {
			#{$right}: $flipvalue;
		} @else {
			#{$right}: $value;
		}
	}
}

@mixin negateGrid() {
	margin-left: -2 * $column-base-padding;
    margin-right: -2 * $column-base-padding;
    padding-left: 2 * $column-base-padding;
    padding-right: 2 * $column-base-padding;
    
    @include breakpoint(desktop) {
        margin-left: -1 * ($column-base-padding + $grid-base-padding);
        margin-right: -1 * ($column-base-padding + $grid-base-padding);
        padding-left: $column-base-padding + $grid-base-padding;
        padding-right: $column-base-padding + $grid-base-padding;
    }
}

@function toEm($size, $base: $default) {
	@return (math.div($size, $base) * 1em);
}

@function toBase($size) {
	@return toEm($size, $base);
}

@function toMobile($size) {
	@return toEm($size, $mobile);
}

@function factorLineHeight($target-size, $line-height) {
	@return $target-size - (0.13em + math.div(($line-height - 1em), 2));
}

@function listDot($color) {
	@return "%3Csvg%20xmlns%3D%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2010%2010%22%20width%3D%2210%22%20height%3D%2210%22%3E%3Ccircle%20fill%3D%22" +
		"rgb(#{red($color)},#{green($color)},#{blue($color)})" +
		"%22%20cx%3D%225%22%20cy%3D%225%22%20r%3D%222%22%2F%3E%3C%2Fsvg%3E";
}

@function searchIcon($color) {
	@return "%3Csvg%20xmlns%3D%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20500%20500%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20fill%3D%22" + 
		"rgb(#{red($color)},#{green($color)},#{blue($color)})" +
		"%22%20d%3D%22M479.57,415.4L337.46,273.77a192.91,192.91,0,0,1-59.66,62L418.62,476.14a35.91,35.91,0,0,0,50.55,0l10.4-10.31A35.65,35.65,0,0,0,479.57,415.4Z%22%2F%3E%3Cpath%20fill%3D%22"
		"rgb(#{red($color)},#{green($color)},#{blue($color)})" +
		"%22%20d%3D%22M333.67,174.78c0-89.08-72.49-161.29-161.86-161.29S10,85.7,10,174.78,82.42,336,171.81,336,333.67,263.85,333.67,174.78ZM171.81,298.35C103.33,298.18,48,243,47.79,174.78c0.19-68.23,55.53-123.42,124-123.54,68.54,0.12,123.87,55.31,124,123.54C295.67,243,240.35,298.18,171.81,298.35Z%22%2F%3E%3C%2Fsvg%3E"
}