@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';


.search-field {
	.search-widget & {
		margin: 0;
		padding: toMobile(5);
		background-color: $light-grey;
		
		@include breakpoint(desktop) {
			padding: 1px;
		}

		input {
			width: 100%;
			border: 0;

			@include breakpoint(desktop) {
				width: 200px;
				height: #{3.5em - toBase(5)};
			}
		}

		.search-field-clear {
			right: 0.5em;
			top: toMobile(5) + 0.25em;

			@include breakpoint(desktop) {
				height: #{3.5em - toBase(5)};
				width: #{3.5em - toBase(5)};
				top: toBase(5);
				right: toBase(5);
			}

			svg {
				fill: $light-grey;
				height: 100%;
			}
		}
	}
}