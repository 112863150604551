@import 'style/config';
@import 'style/base/10.mixins';


.downloadcenter {
	padding: 40px 0;

	select,
	.select2 {
		width:100% !important;
	}
	button {
		width:100%;
		@include breakpoint(desktop) {
			width: auto;
		}
	}

	.seperator {
		margin:15px 0;
		@include breakpoint(desktop) {
			margin:40px 0;
		}

		position:relative;
		text-align:center;

		span {
			background:#fff;
			padding: 3px 10px;
			position: relative;
			z-index: 2;
			font-size: 18px;
			font-weight: bold;
		}

		&:after {
			content:" ";
			border-top:1px solid #000;
			width:100%;
			height:1px;
			display: inline-block;
			position: absolute;
			left:0px;
			top:11px;
			@include breakpoint(desktop) {
				top:9px;
			}
		}
	}

	.greybackground {
		background: $light-grey;
		padding:40px 0;

			.one-third {
				border-bottom: 1px solid $light-grey;

				@include breakpoint(desktop) {
					border-bottom: 0px;
					margin-top: 20px;
				}
			}

		.category {
			@include fontsize($h3-font-size);
			background: #fff;
			font-weight:bold;
			cursor: pointer;

			.collapsable-trigger {
				svg {
					fill: $base-font-color;
					width: 1.25em;
					height: 1.25em;
					margin-top: -0.25em;
					float: right;
				}
				&:hover svg {
					fill: $orange;
				}
			}

			span {
				background-repeat: no-repeat;
				background-size: initial;
				background-position-y: center;
				background-position-x: 20px;
				padding: 30px 0 30px 80px;
				display: block;
			}

			.subcategories {
				position: absolute;
				background: #fff;
				display: none;
				padding: 10px 20px;
				margin-top: -82px;
				@include breakpoint(desktop) {
					margin-top:-78px;
				}
				box-shadow: 0 0 16px 0 rgba(0,0,0,.16);

				span {
					background-position-x: 0px;
					display: block;
					padding: 20px 0 20px 60px;
				}

				ul {
					margin: 10px 0 0 0;
					padding: 0;

					li {
						list-style: none;
						a {
							color: $black;
							font-size:12px;
							font-weight:normal;
						}
					}
				}
			}
		}

	}
	
	.search-field {
		background: transparent;
		padding:0px;
		margin:0px;
		
		input {
			border: 1px solid #626262;
			height: auto;
			padding:0.5em 1em;
			
			@include breakpoint(desktop) {
				height: 33px;
			}
		}
	}

	.search-autosuggest {
		position:relative;

		.search-content {
			left: 0px;
			margin-left: 0px;
			margin-top: -8px;
			width: inherit;
			@include breakpoint(desktop) {
				padding-right: 14px;
				margin-top: -6px;
			}
			background: transparent;

			.search-result-group {
				background: #fff;
			}
			a {
				outline: 0;
			}
		}
	}

	.search-field-submit {
		background:$orange;
		margin-top: 0.5em;
		padding: 0.5em 1em;
		svg {
			fill: #fff;
			height: 100%;			
			max-width:20px;
		}
	}



	dl svg {
		vertical-align: text-bottom;
		margin-right: 4px;
	}

	.data-list dt {
		padding: 6px;
	}

	dl svg path, .column-remove svg path {
		fill: #000;
	}

	.column input[type="checkbox"] {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.cart-entries-header .column-select label {
		border: 1px solid white;
		vertical-align: text-bottom;
		margin-right: 12px;
	}

	.column-select {
		min-width: 9.23077em;
		max-width: 9.23077em;
		width: 9.23077em;
	}

	.cart-entry .column.column-remove svg {
		cursor: pointer;
		height: 3em !important;
	}

	.column-fileicon {
		max-width: 6em;
	}

	.column-fileicon svg {
		width: 58px;
		height: 58px;
	}

	.cart-entry {
		border-bottom: 1px solid #bcbdc0;
		padding-bottom: 18px;
		padding-top: 0px;
	}

	span.badge {
		display: inline-block;
		padding: 0px;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 12px;
		line-height: 20px;
		vertical-align: top;
		
		svg {
			width: 23px;
			height: 23px;
		}
	}

	label.checkbox {
		position: relative;
		display: inline-block;
		width: 16px;
		height: 16px;
		border: 1px solid #000; 
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	label.checkbox:hover input ~ .checkmark {
		background-color: transparent;
	}

	label.checkbox input:checked ~ .checkmark:after {
		display: block;
	}
	
	.cart-entries-header {
		display: block;
	
		background-color: $shaded-blue;
		color: $white;

		margin: 0 (-1 * ($grid-base-padding + $column-base-padding));
		padding: toBase(10) ($grid-base-padding + $column-base-padding);
		
		@include breakpoint(tablet) {
			.column-select {
				float: right;
				width: 14em;
				max-width: 14em;
				text-align: right;
				
				label {
					float: right;
					margin-right:0px;
					margin-top: 3px;
					margin-left: 10px;
				}
			}	
		}
	}

	.cart-entries-header .column-select label.checkbox input:checked ~
		.checkmark:after {
		background-color: #437fa3;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 16px;
		width: 16px;
	}

	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	label.checkbox .checkmark:after {
		left: 3px;
		top: 3px;
		width: 8px;
		height: 8px;
		/* border: solid #437fa3; */
		background: #000;
	}

	.cart-entries-header .column-select label.checkbox .checkmark:after {
		left: 3px;
		top: 3px;
		width: 8px;
		height: 8px;
		background: #FFF !important;
		/* border: solid #437fa3; */
	}

	.cart-entries-list .cart-entry dt {
		margin-right: 12px;
		font-weight: normal !important;
	}

	.column-remove {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.cart-entries .column-remove svg {
		height: auto !important;
	}

	.cart-entries-heading {
		margin-bottom: 12px;
	}

	.column-details p {
		margin-top: 0px !important;
	}

	.column-details h3 {
		margin-bottom: 0px;
	}

	.text-right {
		text-align: right;
	}

	.cart-entries-list .cart-entry+.cart-entry {
		padding-top: 0px !important;
		border-top: none !important;
	}

	.cart-entries-list .cart-entry:last-child {
		border-bottom: none;
		margin-bottom: 0;
	}

	.box {
		border-bottom: 1px solid #bcbdc0;
	}

	.cart-footer .column {
		padding-top: 1em;
		padding-bottom: 1em;
	}

	.cart-footer .grid {
		align-items: center;
	}

	.cart-footer a.link {
		font-size: 1em;
	}

	.cart-footer a.link svg {
		height: 1.05em;
		vertical-align: text-bottom;
	}

	.cart-footer a.link.mail-selected svg {

	}

	.cart-footer a.link.delete svg {
		height: 1.4em;
	}

	.cart.content-top {
		margin-bottom:36px;
	}

	.col-footer {
		justify-content: space-between;
		margin-top: 4em !important;
		margin-bottom: 24px !important;
		align-items: center;
		font-size: 1em;
	}

	.col-footer .link {
		font-size: 1em;
	}

	.col-footer .col {
		min-width: 20%;
	}

	.col-footer .col-middle {
		text-align: center;
	}

	.col-footer .fake-button {
		font-size: 1.5em;
		min-width: 60%;
	}

	.data-list a.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	.column-remove a span {
		display:none;
	}


	@media only screen and (max-width: 1020px) {
		.cart-entry .column.column-remove .deleteSingle svg {
				height: 1.4em !important;
		}

		.cart-footer dl {
			padding-left: 0;
			padding-right: 0;
		}

		.cart-footer dt {
			display:block;
		}

		.cart-footer .column.column-actions.content.cart-actions {
			text-align:center;
		}

		.col-footer .col {
				text-align:center;
				margin-bottom:1em;
		}

		.column-remove {
			float: right;
			width: 70px;
			margin-top: -55px;

			a.link {
				margin-top: 0;
			}
		}

		.search-result-download .link {
			display: inline-block;
			font-size: 14px;
		}

		.search-result-download .secondLink {
			display: inline-block !important;
			margin-left: 10px;
		}

		.cart-entry .column-select {
			margin-bottom: 1em;
			float: right;
			text-align: right;
		}
	}

	@include breakpoint(desktop) {
		.grid.container.with-border-top {

			.column.balanced.content {
				width: 100%; // This is needed for IE11.
				.data-list .column {
					display: flex;
					flex-wrap: wrap;
				}

			}



		}
	}

}
