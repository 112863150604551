.form-input-date,
.form-input-date-range {
	padding: 0.5em 0;

	label {
		.required &:before {
			content: "*";
			display: inline-block;
			margin-left: 0.1em;
		}

		span {
			display: inline-block;
		}
	}

	input {
		width: 100%;

		&[disabled] {
			background-color: $light-grey;
		}
	}
	
	.date-range-fake-field {
		display: inline-block;
	    border: 1px solid #626262;
	    padding: 0.5em 1em;
	    line-height: 1.5em;
	    color: #3d3d3d;
	    outline-color: #437fa3;
		width: 100%;
		min-height: 35px;
	}
	input[name=toDate],
	input[name=fromDate] {
		height: 0px;
	    width: 0px;
	    padding: 0px;
	    position: absolute;
	    top: 0px;
	    border: 0px;
	}
}