
@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.individual-tagging-content {

  .tagging-table {
    padding-right: 1em;

    .tagging-table-title {
      padding-bottom: 1em;
    }

    .tagging-table-title, .tagging-table-row {
      display: flex;
      align-items: center;
      padding-right: 1em;
      width: 100%;
      > * {
        &:nth-child(1) {
          width: 25%;
        }
        &:nth-child(2) {
          width: 75%;
        }
      }
    }

    .tagging-table-row {
      padding-top: 1em;
    }
  }

  textarea {
    resize: vertical;
    max-height: 300px;
  }
  .tagging-table-content {
    max-height: 300px;
    overflow: auto;
  }

  button {
    &:not(:first-child) {
      margin-left: 1em;
    }
  }

  .tagging-reset {
    margin-left: 1em;
  }

  a {
    cursor: pointer;
  }
  a.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .tagging-info {
    margin-top: 2em;
  }

  .tag-list-update {
    display: flex;
    align-items: center;

    span {
      color: $red;
      padding-left: 1em;
      flex: 1;
    }
  }

  @include breakpoint(tablet) {
    .tag-list-instructions {
      padding-top: 3em;
    }
    .tagging-table {
      padding-right: 0em;
    }
    .tagging-controls {
      margin-top: 2em;
    }
    .tag-list-update {
      min-height: 4em;
    }

  }

}