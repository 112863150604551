
.cart-entry {

  .cart-calibration-certificate {
    .form-column div {
      display: flex;
      align-items: center;

      label {
        padding-left: 0.5em;
      }
    }
  }

  .column-price .calibration-certificate-display {
    white-space: nowrap;
    text-align: left;
  }

}