@import 'style/config';

.error {
  .title {
    color: $orange;
    margin-bottom: 1 * $h3-line-height;
  }
  .text {
    font-size: $base-font-size * 1.5;
  }
}