.form-input-file {
	padding: 0.5em;
	border: 1px dashed $light-grey;
	text-align: center;

	label {
		.required &:before {
			content: "*";
			display: inline-block;
			margin-left: 0.1em;
		}

		.form-control-select {
			display: inline-block;
		}

		.form-label-selected-file {
			padding: 0.5em;
		}
	}

	input {
		visibility: hidden;
		position: absolute;
		@include leftright(left, right, -200vw);
	}
}