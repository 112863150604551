@import 'style/config';

input[type=text],
input[type=password],
input[type=number],
textarea {
	display: inline-block;
	border: 1px solid $input-border-color;
	padding: 0.5em 1em;
	line-height: 1.5em;
	color: $base-font-color;
	outline-color: $shaded-blue;
}

input[type=text],
input[type=password],
input[type=number] {
	height: 2.5em;
}