@import 'style/config';

.collapsible2 {
  >.collapsible2-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    > .collapsible2-icon {
      width: 1em;
      height: 1em;
    }
  }
  &.collapsible2-content-hidden {
    >.collapsible2-content {
      display: none;
    }
  }
  &.collapsible2-content-visible {
    >.collapsible2-content {
      display: block;
    }
    >.collapsible2-nav {
      > .collapsible2-icon {
        > svg {
          transform: rotate(180deg);
          fill: $orange;
        }
      }
    }
  }
}
