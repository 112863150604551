$bg-color: #f2f2f2;
$radio-input-color: #437fa3;
$radioInputColor: #437fa3;
$radioTextBgColor: #ccccda;

.rb-flex-row-v-normal {
  display: flex;
  align-items: normal;
}
.rb-mb-02 {
  margin-bottom: 0.2em;
}
.rb-pl-1 {
  padding-left: 1em;
}
.rb-width-100p {
  width: 100%;
  div { // IE11 for whatever reason needs this.
    width: 100%;
  }
}

.rb-font-bold {
  font-weight: bold;
}

.rb-radio {
  position: relative;
  background-color: $bg-color;
  &:hover {
    background-color: $radioTextBgColor;
  }
  .rb-radio-box {

    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 3em;
    min-height: 3em;
    cursor: pointer;
    .radio-icon {
      width: 1.2em;
      height: 1.2em;
      svg {
        circle {
          stroke: $radio-input-color;
          stroke-width: 5;
          fill: $bg-color;
        }
        polyline {
          stroke-width: 4;
          fill: $bg-color;
        }
      }
    }
  }
  label {
    cursor: pointer;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div:last-child {
      font-size: 0.8em;
      font-weight: normal;
    }
    div:first-child {
      font-size: 1.0em;
    }
  }

  input {
    position: absolute;
    visibility:hidden;
    &:checked + .rb-radio-box  {
      cursor: default;
      background-color: $radio-input-color;
      .radio-icon svg {
        circle {
          stroke-width: 2;
          fill: $bg-color;
        }
        polyline {
          stroke: $radio-input-color;
        }
      }
    }
    &:checked ~ label {
      background-color: $radioTextBgColor;
      cursor: default;
      color: $white;
    }
  }
}