@import 'style/config';
@import 'style/base/10.mixins';

.form-input-hp {
    position: absolute;
    @include leftright(right, left, 200vw);

	label {
		span {
			display: inline-block;
		}
	}

	input {
		width: 100%;
	}
}