@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.order-confirmation {
    dl {
        margin: 0;
    }

    dt,
    dd {
        display: block;
        margin: 0;
    }

    dt {
        font-weight: bold;
    }

    address {
        font-style: normal;
    }

    .highlight {
        font-weight: bold;
    }

    .summary-row + .summary-row {
        @include breakpoint(desktop) {
            margin-top: 1em;
        }
    }

    .row-subtotal .column,
    .row-total .column {
        padding-top: 1em;
        padding-bottom: 1em;

        &.column-price {
            @include leftright(text-align, text-align, right, left);
        }
    }

    .row-subtotal span {
        font-weight: bold;
    }

    .row-total span {
        font-weight: bold;
    }

    .row-summary {
        .column:not(:first-child) h3 {
            @include breakpoint(tablet) {
                margin-top: 1em;
            }
        }
    }

    .column-action {
        @include leftright(text-align, text-align, right, left);
        padding-top: 1em;
    }

    .column-details {
        overflow: hidden;
    }

    @include breakpoint(desktop) {
        .cart-entry .orderEntry .grid {
            width: 100%;
            .column-details {
                width: 350px;
            }
            .column-price {
                min-width: auto;
            }
        }
    }
}