@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.breadcrumbs {
	margin-top: toMobile(-20);
	margin-left: -2 * $column-base-padding;
	margin-right: -2 * $column-base-padding;
	padding-left: 2 * $column-base-padding;
	padding-right: 2 * $column-base-padding;

	@include breakpoint(desktop) {
		margin-top: toBase(-20);
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
		display:block;
	}

	ol {
		padding: 0;
		white-space: nowrap;

		li {
			display: inline-block;

			&:not(:last-of-type) {
				a:after {
					content: "\00a0>\00a0";
				}
				@include breakpoint(desktop) {
					a:after {
						content: " > ";
					}
				}
			}
		}

		a {
			display: block;
			margin: 10px 0;
			text-decoration: none;
			
			&.disabled {
				pointer-events: none;
				color: $base-font-color;
			}
			
		}
	}
}