@import 'style/config';

.overlay {
	background-color: rgba(37,37,37,0.5);
	cursor: default;
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 100000;

	.with-burger-overlay &,
	.with-overlay & {
		display: block;
	}
}