@import 'style/config';

.cart-item-detail-column-row {
  display: flex;
  .cidcr-title {
    font-weight: bold;
    white-space: nowrap;
    padding-right: 0.5em;
  }

  .cidcr-title-for-summary {
    width: auto;
    min-width: auto;
    max-width: none;
    padding-right: 0.5em;
    white-space: nowrap;
  }

  .cidcr-display {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .cidcr-display-for-summary,
  .cidcr-display {
    overflow: hidden;
    text-overflow: ellipsis;
    @include breakpoint(mobile) {
      justify-content: flex-end;
    }

    .cidcr-text-for-summary,
    .cidcr-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .cidcr-display .cidcr-text {
    @include breakpoint(desktop) {
      width: 170px;
    }
  }

  .cidcr-display {

    .cidcr-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .cidcr-title-mobile-active {
    @include breakpoint(mobile) {
      display: none;
    }
  }
}
.order-confirmation {
  .cart-item-detail-column-row {
    @include breakpoint(desktop) {
      display: block;
      .cidcr-display .cidcr-text {
        width: auto;
      }
    }
  }
}