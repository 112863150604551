@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.intro-home {
	@include breakpoint(desktop) {
		padding-right: 25%;

		.social-links {
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			padding: toBase(40) toBase(40) toBase(40) toBase(20);
			width: calc(25% - #{toBase(40)});
			border-left: 1px solid $light-grey;

			a {
				width: toBase(36);
				margin: 0 toBase(15) toBase(15) 0;
			}
		}
	}
}