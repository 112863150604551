@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.cart-entries-header {
	display: none;

	@include breakpoint(desktop) {
		display: block;
		background-color: $shaded-blue;
		color: $white;

		margin: 0 (-1 * ($grid-base-padding + $column-base-padding));
		padding: toBase(10) ($grid-base-padding + $column-base-padding);
	}
}