
$defaultSpacingXS: 0.25em;
$defaultSpacingS: 0.5em;
$defaultSpacingM: 1em;
$defaultSpacingL: 1.5em;
$defaultSpacingXL: 2em;
$sliderHandleRadius: 0.8em;

$colorBorder: $mid-grey;
$colorBackgroundDark: $light-grey;
$colorBackgroundLight: $white;
$colorBackgroundMiddle: #f9f9f9;

$colorProductOdd: #fbfbfb;
$colorProductEven: #f2f2f2;
$rangeInputBackgroundColor: #f2f2f2;



@mixin acs-default-margin {
  margin: 0.5em;
}

@mixin acs-font-bold {
  font-weight: bold;
}

@mixin border-with-title {
  @include acs-font-bold;
  border: 1px solid $colorBorder;
  padding: $defaultSpacingM;
  padding-top: 2 * $defaultSpacingM;
  @include breakpoint(tablet) {
    padding-top: 1 * $defaultSpacingM;
    padding-left: 0;
    padding-right: 0;
    border: none;
    border-bottom: 1px solid $colorBorder;
  }
  position: relative;
  background-color: $colorBackgroundLight;
  >span {
    position: absolute;
    padding: 0 $defaultSpacingM;
    background-color: $colorBackgroundLight;
    font-size: $defaultSpacingM;
    font-size: $defaultSpacingM;
    transform: translateY(-2.7 * $defaultSpacingM);
    @include breakpoint(tablet) {
      transform: translateY(-1.7 * $defaultSpacingM);
      margin-top: $defaultSpacingM;
      padding-left: 0;
    }
  }
}
@mixin disabled {
  opacity: 0.5;
  pointer-events: none;
}


.acs-mode-selection-wrap {

  &.disabled {
    @include disabled;
  }

  &.disabled-light {
    pointer-events: none;
  }

  padding: 0 0 $defaultSpacingM 0;
  @include breakpoint(desktop) {
    width: 100%;
    padding: 0 0 $defaultSpacingXL 0;
  }
  .acs-mode-selection {
    &.disabled {
      pointer-events: none;
    }
    .rb-radio-box {
      min-height: 2.5em;
      @include breakpoint(tablet) {
        min-height: 2em;
      }
    }
    .rb-radio {

      label div {
        @include acs-font-bold;
      }

      width: 100%;
      @include breakpoint(desktop) {
        &:not(:first-of-type) {
          margin-left: $defaultSpacingXS;
        }
      }
    }
    .common-rb-buttons {
      display: flex;
      @include breakpoint(tablet) {
        flex-direction: column;
      }
    }
    .rb-radio input:checked ~label {
      background-color: #437fa3;
    }
  }
}

.acs-main-wrap {

  @include breakpoint(desktop) {
    --is-mobile: false;
  }
  @include breakpoint(tablet) {
    --is-mobile: true;
  }

  &.disabled {
    @include disabled;
  }

  &.disabled-light {
    pointer-events: none;
  }

  display: flex;
  @include breakpoint(tablet) {
    flex-direction: column;
  }

  >div {
    @include breakpoint(desktop) {
      &:first-child {
        margin-right: $defaultSpacingM;
      }
      &:last-child {
        margin-left: $defaultSpacingM;
      }
      width: calc(50% - 1 * #{$defaultSpacingM});
    }
    @include breakpoint(tablet) {
      &:first-child {
        margin-bottom: $defaultSpacingM;
      }
    }
  }
  &.manual {
    flex-direction: column;
    align-items: center;
    .acs-product-selections-wrap {
      display: none;
    }
    .acs-products-wrap {
      @include breakpoint(desktop) {
        min-height: 500px;
        width: 100%;
        transition: width 0.15s linear;
        margin-left: 0;
        .acs-products {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          > div:nth-child(4n+0),
          > div:nth-child(4n+1) {
            background-color: $colorProductEven;
          }
          > div:nth-child(4n+2),
          > div:nth-child(4n+3) {
            background-color: $colorProductOdd;
          }
          .acs-product {
            // IE11 , calc is not always working, hardcoding it therefore to 436px;
            //width: calc(50% - 2.0 * #{$defaultSpacingXS});
            width: 436px;
            padding-left: $defaultSpacingM;
            padding-right: $defaultSpacingM;
            margin: $defaultSpacingXS;
            &:hover {
              background-color: $colorBackgroundDark;
            }
          }

          &.disabled-light {
            pointer-events: none;
          }
        }

      }
      @include breakpoint(tablet) {
        width: 100%;
      }
    }
  }
  .acs-product-selections-wrap {
    @include border-with-title;
    @include breakpoint(desktop) {
      min-height: 500px;
    }

    .acs-product-selections {
      font-weight: normal;
    }

  }
  .acs-products-wrap {
    @include border-with-title;

    .acs-products-count {
      @include acs-font-bold;
    }
    .acs-products-text {
      font-weight: normal;
    }
    .acs-products-query-text {
      display: none;
    }

    .acs-product-name {
      @include acs-font-bold;
    }
    .acs-products-spinner {
      &.disabled {
        display: none;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      div {
        width: 64px;
        height: 64px;
        @include breakpoint(tablet){
          width: 40px;
          height: 40px;
        }
      }
      transition: opacity 0.5s linear;
    }
  }
}

.acs-common-ctrls-wrap {
  padding: $defaultSpacingM 0 0 0;
  button {
    padding: 0 $defaultSpacingXL;
    @include acs-font-bold;
    &.disabled-light {
      pointer-events: none;
    }
  }
}

.acs-id-select-boxes {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  .acs-id-select-prototype {
    display: none;
  }
}

.acs-select-box {
  display: flex;
  flex-direction: column;
  width: 50%;

  @include breakpoint(tablet) {
    width: 100%;
    margin-top: $defaultSpacingM;
  }

  @include breakpoint(desktop) {

    &:nth-child(even) {
      padding-left: $defaultSpacingS;
    }
    &:nth-child(odd) {
      padding-right: $defaultSpacingS;
    }
    &:nth-child(n+3) {
      margin-top: $defaultSpacingM;
    }
  }

  label {
    @include acs-font-bold;
    &.disabled {
      opacity: 0.5;
    }
    transition: opacity 0.2s linear;
  }
  select {
    height: 3em;
    transition: all 0.3s linear;
    .disabled-light {
      pointer-events: none;
    }
  }
}

.acs-id-ranges {

  .acs-id-range-prototype {
    display: none;
  }

  .acs-range-input {

    &.disabled {
      @include disabled;
    }

    &.disabled-light {
      pointer-events: none;
    }

    margin-top: $defaultSpacingM;
    label {
      @include acs-font-bold;
    }

    .acs-range-input-ctrls {
      background-color: $rangeInputBackgroundColor;

      display: flex;
      flex-direction: column;
      width: 100%;

      label {
        @include acs-font-bold;
      }

      .acs-range-input-fields {
        margin-top: $defaultSpacingM;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .acs-range-input-field {
          display: flex;
          align-items: center;

          label {
            font-weight: normal;
            word-break: break-all;
          }

          input {
            text-align: center;
            height: 3em;
            width: 9em;
          }
          @include breakpoint(desktop) {

            &:first-child label {
              padding-left: 2em;
            }

            &:last-child input {
              margin-right: 2em;
            }

            label {
              padding-right: 2em;
              padding-left: 0.2em;
            }
          }


          @include breakpoint(tablet) {
            label {
              padding-right: 1em;
            }
            input {
              width: 8em;
              padding-left: 0.2em;
              padding-right: 0.2em;
            }
            &:first-child label {
              padding-left: 0.3em;
              margin-right: 0.3em;
            }

            &:last-child input {
              margin-right: 0.3em;
            }
          }
          @include breakpoint(mobile-portrait) {
            label {
              padding-right: 0.3em;
            }
            input {
              width: 6em;
              padding-left: 0.1em;
              padding-right: 0.1em;
            }
          }
        }
      }

      .acs-slider-wrap {
        margin: 0px $sliderHandleRadius;
        padding-top: $defaultSpacingL;
        height: 5em;
      }
    }
  }
}

.acs-product-template {
  display: none;
}

.acs-products {

  width: 100%;

  &.disabled {
    @include disabled;
  }

  &.disabled-light {
    pointer-events: none;
  }

  font-weight: normal;
  overflow-y: auto;
  @include breakpoint(tablet){
    margin-top: $defaultSpacingM;
  }

  >div:not(:last-child) {
    border-bottom: 1px dashed $light-grey;
    padding-bottom: $defaultSpacingM;
  }

  @include breakpoint(desktop) {

    >div:last-child {
      border-bottom: 1px dashed $light-grey;
      padding-bottom: $defaultSpacingM;
    }

    > div:nth-child(odd) {
      background-color: $colorProductOdd;
    }
    > div:nth-child(even) {
      background-color: $colorProductEven;
    }
  }
  .acs-product {

    display: flex;
    flex-direction: column;
    padding-left: $defaultSpacingM;
    padding-top: $defaultSpacingS;
    @include breakpoint(mobile-portrait) {
      padding-top: $defaultSpacingL;
    }
    padding-right: $defaultSpacingS;
    margin-right: $defaultSpacingM;
    @include breakpoint(tablet) {
      margin-right: $defaultSpacingS;
      padding-left: 0;
      margin-right: 0;
    }

    .acs-action-select-actuator {
      margin-right: $defaultSpacingS;
      padding-top: $defaultSpacingS;
      padding-bottom: $defaultSpacingS;
      height: auto;
      position: relative;
      @include acs-font-bold;

      .acs-product-spinner {
        display: none;
        &.enabled {
          display: block;
        }
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

    }
    >.acs-product-bottom-ctrls >.acs-action-select-actuator {
      @include breakpoint(tablet) {
        margin-top: $defaultSpacingM;
      }
      @include breakpoint(desktop) {
        display: none;
      }
    }

    .acs-product-ctrls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $defaultSpacingS;
      .acs-product-link {
        @include acs-font-bold;
        text-decoration: none;
        @include breakpoint(tablet) {
          margin-bottom: $defaultSpacingM;
        }
        @include breakpoint(desktop) {
          max-width: 65%;
        }
      }
      .acs-action-select-actuator {
        @include breakpoint(tablet) {
          display: none;
        }
      }
      @include breakpoint(tablet) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: $defaultSpacingS;
        margin-bottom: $defaultSpacingS;
      }
    }
    @include breakpoint(desktop) {
      &:hover {
        background-color: $colorBackgroundDark;
      }
    }


    .acs-product-detail {
      display: flex;
      align-items: center;
      .acs-product-image-wrap {
        width: 30%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          max-height: 80px;
          min-height: 80px;
          border: 2px solid $colorBorder;
        }
      }
      .acs-product-info {
        width: 70%;
        .acs-product-configurable {
          padding-bottom: $defaultSpacingS;
        }
        .acs-product-desc {
        }
      }
      @include breakpoint(tablet) {
        flex-direction: column;
        align-items: flex-start;
        .acs-product-info {
          padding-top: $defaultSpacingS;
          width: auto;
          max-width: 600px;
        }
      }
    }
  }
}

.acs-id-combo-view {
  .acs-id-combo-option {
    .acs-id-combo-connector-text {
      @include acs-font-bold;
    }
    div {
      display: flex;
      button {
        height: auto;
        min-width: 8em;
        padding: $defaultSpacingXS 0;
        border-radius: 0.4em;
        &:not(:last-child) {
          margin-right: $defaultSpacingS;
        }
        &:enabled {
          background-color: $light-grey;
          color: $black;
          cursor: pointer;
          &:hover {
            background-color: #BDD0DB;
          }
        }
        &:disabled {
          cursor: default;
          background-color: #437fa3;
          color: $white;
          opacity: 1.0;
        }
      }
    }
  }
  .asc-id-combo-separator {
    margin: $defaultSpacingM 0;
    border-bottom: 2px solid $light-grey;
  }
  .acs-id-combo-ctrls {
    margin-top: $defaultSpacingXL;
    button {
      min-width: 8em;
    }
  }
}


.acs-view {

  input[type=number]::-ms-clear {
    display: none;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance:textfield;
    appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance:textfield;
  }
}
