@import 'style/config';
@import 'style/base/10.mixins';
@import 'style/base/20.grid';
@import 'node_modules/sensible/mediaqueries';

header {
	background-color: $white;
	text-align: right;
	position: relative;
	@media screen and ( min-height: 800px ) and ( min-width:1020px ) {
		position: sticky;
		top: 0px;
	}
	z-index: 10000;

	.wrapper {
		position: relative;
	}

	.with-burger-overlay.with-navigation &,
	.with-burger-overlay.with-meta &,
	.with-overlay.with-navigation &,
	.with-overlay.with-meta & {
		z-index: 100001;

		@include breakpoint(desktop) {

		}
	}

	.secondary {
		text-align: left;
		background-color: $white;
		border-top: 1px solid $light-grey;

		@include breakpoint(desktop) {
			border-top: 0;
		}

		.with-overlay.with-navigation:not(.with-language) & {
			@include breakpoint(desktop) {
				position: relative;
				z-index: 100001;
			}
		}

		.contains-logo {
			position: absolute;
			@include leftright(right, left, 100vw);
			@include leftright(text-align, text-align, left, right);

			@include breakpoint(desktop) {
				position: static;
			}
		}
	}

	.collapsable {
		@extend .align-to-grid;
		padding: 1em (4 * $column-base-padding);
		border-top: 1px solid $light-grey;

		@include breakpoint(desktop) {
			margin: 0;
			padding: 1em;
			border-top: 0;
		}

		&:not(.no-children).toggle-on {
			color: $white;
			background-color: $orange;

			@include breakpoint(desktop) {
				color: $base-font-color;
				background-color: transparent;
			}

			a {
				color: $white;

				@include breakpoint(desktop) {
					color: $base-font-color;
				}
			}
		}

		.collapsable-content {

			@include breakpoint(desktop) {
				max-height: 10000px;
			}
		}
	}

	ul.list-level-1 {
		@include flexbox();

		text-align: left;
		padding: toMobile(10) 0;
		margin: 0;
		list-style: none;
		width: 100%;

		@include breakpoint(desktop) {
			@include no-flexbox(inline-block);
			padding: toMobile(20) 0;
		}
	}
	
	@include breakpoint(desktop) {
		.navigation ul.list-level-1 {
			padding: 0px;
			
			.level-1 .panel {
				padding: 1.20em 0 1.2em;
				
				&.sublevel {
				
					&:first-child {
						margin-left: 2.25em;
					}
					padding-right: 1.25em;
				
					span {
						padding-left: 0;
					}
					
					&.toggle-on {
						border-bottom: 5px solid #ff6600;
					}
				}
				
			}
		} 
	}

	ul.list-level-2 {
		padding: 0;
		margin: 0;
	}

	li.level-1 {
		@include flex(1);
		@include leftright(text-align, text-align, left, right);

		@include breakpoint(desktop) {
			//display: inline-block !important;
			@include no-flexbox(inline-block);
		}

		.panel {
			@include leftright(text-align, text-align, left, right);
			
			.panel-trigger {
				@include leftright(padding-left, padding-right, 0);
				@include leftright(padding-right, padding-left, 1em);
				@include leftright(text-align, text-align, left, right);
				
				@include breakpoint(desktop) {
					@include leftright(padding-left, padding-right, 0.5em);
					@include leftright(padding-right, padding-left, 0);
					@include leftright(text-align, text-align, right, left);
				}
			}
		}
	}

	.navigation li.level-1 {
		.panel {
			text-align: center;
		}

		.panel-content {
			left: auto;
			right:0px;
			background:#fff;
		}
	}
	li.level-1:empty {
		display: none !important;
	}

	li.level-2 {
		display: block;
		padding: 0.25em 0;
	}

	a {
		color: $base-font-color;
		
		&.selected {
			font-weight:bold;
		}	
	}
	
	.cookies.grid {
	
		display: none;
	
		.row {
			text-align: left;
			padding: 10px 0;
			
			span {
				@include breakpoint(desktop) {
					display: inline-block;
					width: 80%;
				}
			
				a {
					text-decoration:underline !important; 
				}
			}
			
			.fake-button {
				@include breakpoint(desktop) {
					float:right;
				}
			}
		}
	}
	
	.intlanguage.grid {
		display: none;
	
		.row {
			text-align: left;
			padding: 10px 0;
			
			.close {
				cursor:pointer;
				margin-right:1.69231em;
				svg {
					width: 15px;
    				float: right;
    				height: 15px;
    				
    				path {
    					fill:$dark-grey;
    				}
				}
			}
			
			span {
				@include breakpoint(desktop) {
					display: inline-block;
					width: 80%;
				}
			
				a { 
					background: $shaded-blue;
					padding:5px 10px;
					color: white;
					margin-top: 10px;
					display: inline-block;
					text-decoration:none;
					
					svg {
						width: 10px;
						height: 10px;
						margin-left: 5px;
						path {
							fill: #fff;
						}
					}
				}
			}
			
			
		}
	}
}