@import 'style/config';
@import 'node_modules/sensible/mediaqueries';

.event {

	.column.column-image {
		@include breakpoint(desktop) {
			min-width: toBase(100);
			max-width: toBase(100);
		}
	}

	.column.column-text {
		@include breakpoint(desktop) {
			min-width: calc(100% - #{toBase(100)});
			max-width: calc(100% - #{toBase(100)});
		}
	}

	.column.column-overview {
		@include breakpoint(desktop) {
			min-width: calc(100% - #{toBase(160)});
			max-width: calc(100% - #{toBase(160)});
		}
	}

	.column.column-details {
		margin-top: 1em;

		@include breakpoint(desktop) {
			min-width: toBase(600);
		}
	}

	.column.column-ics {
		text-align: right;
		min-width: toBase(160);
	}

	h3 {
		margin-top: 0.5em;

		@include breakpoint(desktop) {
			margin-top: -0.4em;
		}
	}

	dl {
		margin: 0 0;

		& + * {
			margin-top: 2em;
		}
	}

	dt,
	dd {
		display: inline;
		margin: 0;
	}

	dt {
		font-weight: bold;
	}

	dd:after {
		content: "";
		width: 100%;
		display: block;
	}
}