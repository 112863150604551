@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.table {
	table {
		border: 0;
		border-collapse: collapse;
	}

	th,
	td {
		padding: 1em;
		min-width: toMobile(80);

		@include breakpoint(desktop) {
			min-width: toBase(80);
		}
	}

	td {
		vertical-align: top;
	}

	&.themed {
		border-top: 1px solid $light-grey;
		margin-left: -2 * $column-base-padding;
		margin-right: -2 * $column-base-padding;

		@include breakpoint(desktop) {
			margin-left:  -($column-base-padding + $grid-base-padding);
			margin-right: -($column-base-padding + $grid-base-padding);
		}

		table {
		}

		thead {
			background-color: $shaded-blue;
			color: $white;

			th {
				border-top: 0;
			}
		}

		tbody:last-child,
		tfoot {
			td {
				border-bottom: 0;
			}
		}

		tr {
			padding: 1em 0;
			&:nth-child(2n) {
				background-color: rgba($light-grey, 0.5);
			}
		}

		th:first-child,
		th:last-child,
		td:first-child,
		td:last-child {
			min-width: #{toMobile(80) + 2 * $column-base-padding};

			@include breakpoint(desktop) {
				min-width: #{toBase(80) + $column-base-padding + $grid-base-padding};
			}
		}

		th:first-child,
		td:first-child {
			padding-left: 2 * $column-base-padding;
			@include leftright(border-left, border-right, 0);

			@include breakpoint(desktop) {
				padding-left:  $column-base-padding + $grid-base-padding;
			}
		}

		th:last-child,
		td:last-child {
			padding-right: 2 * $column-base-padding;
			@include leftright(border-right, border-left, 0);

			@include breakpoint(desktop) {
				padding-right: $column-base-padding + $grid-base-padding;
			}
		}

		th:nth-child(2n),
		td:nth-child(2n) {
			background-color: rgba($light-grey, 0.5);
		}
	}

	&.default {
		table {
			display: block;
			@include breakpoint(desktop) {
				display: table;
				min-width: 100%;
			}
		}

		thead {
			display: none;

			@include breakpoint(desktop) {
				display: table-header-group;
			}
		}

		tbody {
			display: block;

			@include breakpoint(desktop) {
				display: table-row-group;
			}
		}

		tr {
			display: block;
			padding: 0.5em;

			&:nth-child(2n+1) {
				background: rgba($light-grey, 0.5);
			}

			@include breakpoint(desktop) {
				display: table-row;
			}
		}

		th,
		td {
			@include leftright(padding-left, padding-right, 10%);
			display: block;

			@include breakpoint(desktop) {
				display: table-cell;
				@include leftright(padding-left, padding-right, 0);
			}

			&:before {
				content: attr(data-label) ": ";
				display: inline-block;
				font-weight: bold;
				@include leftright(margin-right, margin-left, 2em);
				@include leftright(margin-left, margin-right, -10%);

				@include breakpoint(desktop) {
					display: none;
				}
			}
		}
	}

	.horizontal-scroll & {
		&,
		& > table {
			width: toMobile(940);
			@include breakpoint(desktop) {
				width: auto;
				min-width: 100%;
			}
		}
	}
}

.table .excelDefaults td {
	padding: inherit;
}