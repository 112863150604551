@import 'style/config';
@import 'node_modules/sensible/mediaqueries';

.history {
	position: relative;
	margin: 0;

	* + & {
		margin-top: 1em;
	}

	&:before {
		@include fontsize($h3-font-size);
		content: attr(data-year);
		font-weight: bold;

		@include breakpoint(desktop) {
			position: absolute;
			left: 0;
		}
	}

	.image {
		margin-top: 0.5em;
		width: 100%;

		img {
			width: 100%;
		}
		
		@include breakpoint(desktop) {
			margin-top: 0;
			padding-left: toBase(80);
		}
	}

	h3 {
		margin-top: 0.5em;
		@include breakpoint(desktop) {
			margin: 0;
		}
	}
}