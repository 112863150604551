@import 'style/config';
@import 'node_modules/sensible/mediaqueries';

.reference {

	h3 {
		margin-top: 0.5em;
		@include breakpoint(desktop) {
			margin-top: -0.4em;
			margin-bottom: 0;
		}
	}

	dl {
		margin: 0;

		& + * {
			margin-top: 1em;
		}

		* + & {
			margin-top: 1em;
		}
	}

	dt,
	dd {
		display: inline;
		margin: 0;
	}

	dd:after {
		content: "";
		width: 100%;
		display: block;
	}

	dt {
		font-weight: bold;
	}

	.text {
		& + * {
			margin-top: 1em;
		}

		* + & {
			margin-top: 1em;
		}
	}

	.reference-url {
		display: block;
			& + * {
				margin-top: 1em;
			}

			* + & {
				margin-top: 1em;
			}
	}

	li.reference-url {
		& + * {
			margin-top: 0;
		}

		* + & {
			margin-top: 0;
		}
	}

	.image {
		width: 100%;

		img {
			width: 100%;
		}
	}

	.column.column-image {
		@include breakpoint(desktop) {
			width: 25%;
		}
	}

	.column.column-text {
		@include breakpoint(desktop) {
			width: 65%;
		}
	}

	.column.column-print {
		@include breakpoint(desktop) {
			@include leftright(text-align, text-align, right, left);
			width: 10%;
		}
	}
}