@import 'style/config';
@import "node_modules/sensible/mediaqueries";

.modal {
	width: 100%;
	left: 0; 
	bottom: 100%;
	position: fixed;
	transition: transform 0.5s, opacity 0.5s;
	opacity: 0;

	.modal-content {
		overflow: auto;
		overflow-x: hidden;
		max-height: 50vh;
	}

	.modal-actions {
		margin-top: 2em;

		button:first-child {
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}

		button {

			font-weight: bold;
			:enabled {
				cursor: pointer;
			}
			
			width: 100%;

			+ button {
				margin-top: 1em;
			}

			@include breakpoint(desktop) {
				width: auto;

				+ button {
					margin-top: 0;
					@include leftright(margin-left, margin-right, 1em);
				}
			}
			:disabled {
				cursor: default;
			}
		}
	}

	&.toggle-on {
		opacity: 1;
		transform: translate(0, 100%);
		
		.with-overlay & {
			z-index: 100001;
		}
	}
}