@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.search-result-page {
	
	border-top:1px dashed $mid-grey;
	padding-top:toBase(10);
	margin-top:toBase(20);
	
	&:first-of-type {
		border-top:0px;	
		padding-top:0px;
	}
	
	.column.column-image {
		@include breakpoint(desktop) {
			min-width: toBase(100);
			max-width: toBase(100);
		}
	}
}