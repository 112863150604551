@import 'style/config';
@import 'style/base/10.mixins';
@import "node_modules/sensible/mediaqueries";

// grid alignment
.left-align-to-grid {
	margin-left: -2 * $column-base-padding;
	
	@include breakpoint(desktop) {
		margin-left: -($grid-base-padding + $column-base-padding);
	}
}

.right-align-to-grid {
	margin-right: -2 * $column-base-padding;
	@include breakpoint(desktop) {
		margin-right: -($grid-base-padding + $column-base-padding);
	}
}

.align-to-grid {
	@extend .left-align-to-grid,
		.right-align-to-grid;
}

// column alignment
.left-align-to-column {
	margin-left: -2 * $column-base-padding;
	@include breakpoint(desktop) {
		margin-left: -$column-base-padding;
	}
}

.right-align-to-column {
	margin-right: -2 * $column-base-padding;
	@include breakpoint(desktop) {
		margin-right: -$column-base-padding;
	}
}

.align-to-column {
	@extend .left-align-to-column,
		.right-align-to-column;
}

.columns {
	@include flexbox();
}