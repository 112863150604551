
.video-external-wrap {

  @mixin video-style {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @mixin video-iframe {
    iframe {
      @include video-style;
    }
  }

  @include breakpoint(desktop) {
    max-width: 100%;
    width: 400px;
    left: 0;
    right: 0;
    margin: 0 auto auto auto;
  }
  .video-external-container.video-youtube {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    @include video-iframe;
  }
  .video-unknown-type {
    text-align: center;
    vertical-align: middle;
  }
}