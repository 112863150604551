.joblist-pagination {
  display: flex;
  align-items: center;

  button.joblist-pagination__control-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    width: 40px;
    height: 40px;
    border: 1px solid $black;
    background-color: transparent;

    &:hover {
      svg {
        fill: $orange;
      }
    }

    &:disabled {
      cursor: auto;

      svg {
        fill: $black;
      }
    }
  }

  &__pages {
    margin: 0 1rem;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;

    .joblist-pagination__page-item {
      button {
        font-size: 1rem;
        padding: 0.5rem;
        background-color: transparent;
        color: $black;

        &:hover {
          color: $orange;
        }
      }

      &--active {
        button {
          color: $orange;
          cursor: auto;
          pointer-events: none;
        }
      }
    }
  }
}
