.testimonial {
	.testimonial-background {
		background: $testimonial-grey;
	}

	.testimonial-content {
		position: relative;
		padding-top: toMobile(20);
		padding-bottom: toMobile(20);
	}

	q {
		font-size: toMobile($mobile);
		font-weight: bold;
		display: block;
		padding: 2.5em 0;
		
		quotes: "\201C""\201D";
		&:before { content: open-quote }
		&:after  { content: close-quote }

		@include breakpoint(desktop) {
			font-size: toBase($h3);
			line-height: 1.3em;
		}
	
		a {
			color: $black;
			text-decoration: none;
		}
	}

	small {
		//font-size: toMobile($mobile);
		margin-top: toMobile(10);
		display: block;

		@include breakpoint(desktop) {
			position: absolute;
			bottom: 1em;
			font-size: toBase($base);
			padding-right: 20px;
		}
	}
}