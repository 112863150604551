@import 'style/config';
@import 'style/base/10.mixins';


.sizing-and-selection {

  .sas-layout {

    display: flex;
    justify-content: center;

    .sas-container-iframe {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
      background-color: $page-color;
      height: 785px;
      display: flex;
      justify-content: center;

      .sas-iframe {
        height: 100%;
        width: 100%;
        @include breakpoint(desktop) {
          width: 1020px;
        }
        border: 0;
        overflow: hidden;
      }
    }
  }

  .sas-cfrm-view-container {
    display: none;
  }
}

.sas-cfrm-view-content {

  .sas-cfrm-view-content-info {

    display: flex;
    align-items: center;

    svg {
      min-width: 2em;
      width: 2em;
      height: 2em;
      fill: green;
      @include breakpoint(mobile) {
        min-width: 3em;
        width: 3em;
        height: 3em;
      }
    }

    .sas-cfrm-view-content-lead-pl,
    .sas-cfrm-view-content-lead-cart {
      font-weight: bold;
      margin: 0;
      padding: 0 1em;

      span {
        &:last-child {
          color: $dark-grey-blue;
          font-size: 0.9em;
          padding-left: 1em;
        }
      }
      ul {
        margin-top: 0;
        padding-left: 2em;
        color: $dark-grey-blue;
        font-size: 1.0em;
      }
    }
  }

  .sas-cfrm-view-content-products {

    @include breakpoint(desktop) {
      width: 50%;
    }
    @include breakpoint(tablet) {
      width: 100%;
    }
    margin-top: 1em;

    .sas-cfrm-view-content-product {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 0;
      padding-left: 3em;
      @include breakpoint(tablet) {
        padding: 0;
      }
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @include breakpoint(mobile) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0.2em;
        &:not(:last-of-type) {
          margin-bottom: 0.6em;
        }
      }

      .sas-cfrm-view-content-product-name {
        color: $dark-grey-blue;;
        padding: 0;
        margin: 0;
      }

      .sas-cfrm-view-content-product-qty {
        span {
          font-size: 0.9em;
        }
        strong {
          padding-left: 0.2em;
          color: $dark-grey-blue;
        }
      }
    }
  }
}
.sas-cfrm-view-ctrls {
  display: none;
}


// TODO: For Testing only, to be removed
#testAddToButtons {
  display: none;
  position: absolute;
  justify-content: space-around;
  align-items: center;
  left: 100%;
  top: 50%;
  transform: translate(-100%, -50%);
  white-space: nowrap;

  button {
    margin: 1em;
    cursor: pointer;
  }
}
.sizing-and-selection-disclaimer {
  ul {
    list-style: none;
    li {
      padding: 0.5em;
      &::before {
        content: "▪";
        color: $orange;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
}
