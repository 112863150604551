@import 'style/config';
@import "node_modules/sensible/mediaqueries";

.stage-main {
	width: 100%;
	position: relative;
	background-color: $orange;

	.grid,
	.column {
		height: 100%;
	}

	.breadcrumbs {
		margin-top: 0;

		ol {
			margin: 0;
		}

		a {
			color: $white;
		}
	}

	.stage-article-text {
		position: relative;
		z-index: 1;
	}

	h1 {
		@include fontsize($nav-title-font-size);		
		line-height: $h1-line-height;
		color: $white;
		margin: 0 0 0.75em;
		padding-top: 0.5em; 
		@include breakpoint(desktop) {
			padding-top: 0em;
			@include fontsize($h1-font-size);
			white-space: nowrap;
		}
	
		.page-productList & {
			margin: 0;	
		}
	}
	
	&.withtext h1 {
		margin:0px;
	}

	.stage-main-text {
		position: relative;
		z-index: 1;
		color: $white;
		padding: 0 0 0.5em;
		@include breakpoint(desktop) {
			padding: 0.75em 0 0.5em; 
		}
		
		a {
			color: $white;
			text-decoration: underline;
			
			&.fake-button {
				text-decoration: none;	
				margin-right: 3px;
				@include breakpoint(mobile) {
					margin-bottom:4px;
					margin-right:0px;
					width:98%;
					text-align:center;		
				}		
			}
		}
		.stage-buttons-container {
			display: block;
			@include breakpoint(desktop) {
				display: flex
			}
		}
		
	}

	.pageType-ProductPage & .breadcrumbs + .grid,
	.pageType-ProductConfigPage & .breadcrumbs + .grid,
	.page-multiStepCheckoutSummaryPage & .breadcrumbs + .grid {
		display:none;
	}
}