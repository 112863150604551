//@import 'style/config';
//@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.icons {
		
		li.level-2 {
			&.customer,
			&.admin,
			&.history-role {
				display: none;
			}
		}
}

.meta {
	height: toMobile(60);
	
	.tab {
		padding-top:0px;
	}

	@include breakpoint(desktop) {
		height: auto;
	}

	.with-burger-overlay.with-meta &,
	.with-overlay.with-meta & {
		position: relative;
		z-index: 100002;

		@include breakpoint(desktop) {
			position: static;
		}
	}

	.meta-content {
		@include breakpoint(desktop) {
			@include no-flexbox(block);
		}
	}

	.logo {
		@include flex(1);
		text-align: left;

		@include breakpoint(desktop) {
			@include no-flex(none);
		}
	}

	.panels {
		@include flexbox();

		@include breakpoint(desktop) {
			@include no-flexbox(block);
		}
	}
	.panel-burger, 
	.panel {
		@include flex(1);

		@include breakpoint(tablet) {
			margin-top: 0;
			border-top: 0;
			border-bottom: 0;

			+ .panel {
				margin-left: -1px;
			}
		}

		.panel-trigger {
			padding: toMobile(14);
			text-align: center;

			@include breakpoint(desktop) {
				padding: toBase(11) 1.25em;
			}
		}

		.panel-content {
			right: 0;
			width: 100%;
			margin-top: 1px;

			@include breakpoint(desktop) {
				margin-top: 0;
			}
		}
	}
	
	.panel-burger {
		.tab {
			padding-top:0px;
			
			.grid {
				margin:0px !important;				
			}
			
			.list-level-1 {
				padding:0px;
				flex-direction: column;
				background: #fff;
				
				.level-1 {
				
					.grid {
						> .column {
							padding:0px;
						}
					}
				
				
					.sublevel {
					
						.panel-trigger {
							padding: 0.3em 1.5em;
							background: #e6ebed;
					
							svg {
								visibility: hidden;
							}
							
							&:after {
								right:30px;
							}
						}
					}
					.tab-trigger,
					.panel-trigger {
						padding-left:1em;
						padding-top: 0.3em;
						padding-bottom: 0.3em;
						border-bottom:1px dashed $light-grey;
						position:relative;
						display:block;
						
						svg {
							display: none;
						}
						
						span {
							padding:0px;
						}
						
					}
					.tab-trigger {
						padding-right: 1em;
					}
					
					.tab-trigger,
					div.panel-trigger {
						span {
							padding:0px;
							@include breakpoint(tablet) {
								padding-right: 0;
								display: flex;
								justify-content: space-between;
								align-items: center;
							}
							display: block;
							
							&:after {
								height:20px;
								width:20px;
								content: " ";
								background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 15 15"><path d="M15,3.75l-7.5,7.5-7.5-7.5Z"/></svg>');
								display:block;
								display: inline-block;
							}
						}
						
						&.toggle-on { 
							span:after {
								transform:rotateX(180deg);
							}
						}
					}
					
					.toggle-on > .panel-trigger span:after {
						transform:rotateX(180deg);
					}
				
					.panel-content {
						position: relative;
						margin: 0px;
						
						.navigation-list {
							padding:0.5em 1.5em 0.5em 3.5em;
							
							.collapsable-trigger {
								padding-top:0px;
								margin-bottom:0.5em;
							}
						}
					}
				}
			}
			
			.secondary {
				border-top:0px;
			}
		}
	}

	.settings-panel {
		display: none;
		@include leftright(text-align, text-align, right, left);
		background-color: $light-grey;
		
		@include breakpoint(desktop) {
			display: block;
		}
		
		.languages {
			padding-left: 10px;
			
			a {
				margin-left:9px;
				&.current {
					font-weight:bold;
				}
			}
		}

		.with-overlay.with-navigation & {
			@include breakpoint(desktop) {
				position: relative;
				z-index: 100002;

				> .grid {
					position: relative;
				}
			}
		}

		ul.list-level-1 {
			padding: 0;
			@include no-flexbox(inline-block);
			width: auto;
		}

		li.level-1 {
			display: inline-block;
			@include leftright(text-align, text-align, left, right);
			vertical-align: middle;
			@include leftright(padding-left, padding-right, 0);

			.choose-location {
				display: flex;
				align-items: center;
				.choose-location-image-before {
					display: flex;
					align-items: center;
					position: relative;
					top: -1.5px;
					padding-left: 1em;
					svg {
						width: 21px;
						height: 21px;
						path {
							fill: $dark-grey;
						}
						&:hover {
							path {
								fill: $orange;
								cursor: pointer;
							}
						}
					}
				}

			}
		}

		li.level-2 {
			display: block;
			padding: 0.25em 0;
			
			&.customer,
			&.admin,
			&.history-role {
				display: none;
			}
		}

		@include breakpoint(desktop) {
			.country-europe ul.list-level-2 {
				column-count: 2;
			}

			// .no-csscolumns & .country-europe li.level-2 {
			// 	float: left;
			// 	width: 50%;
			// }
		}

		a {
			color: $base-font-color;

			&.login {
				padding: toBase(11) toBase(22);
				@include leftright(padding-right, padding-left, 0);
				@include leftright(padding-right, padding-left, 0);
				display: block;

				span {
					@include fontsize(11px);
					height: toEm(20, 11);
					display: inline-block;
				}
			}
		}

		.panel {
			border: 0;
			background-color: transparent;

			&:hover .panel-trigger,
			&.toggle-on .panel-trigger {
				span {
					color: $orange;
				}

				svg {
					fill: $orange;
					transform: rotate(180deg);
				}
			}
		}

		.panel-trigger {
			padding: toBase(11) toBase(22);

			span {
				@include fontsize(11px);
			}

			svg {
				width: 0.75em;
				vertical-align: middle;
				margin-left: 0.25em;
			}

		}

		.panel-content{
			background-color: transparent;

			.grid {
				@include breakpoint(desktop) {
					margin-left: 0;
					margin-right: 0;
					max-width: $grid-max-width;

					.column:first-child {
						@include leftright(padding-left, padding-right, toBase(40));
					}

					.column:last-child {
						@include leftright(padding-right, padding-left, toBase(40));
					}
				}
			}
		}
		
		.country-column,
		.settings-column {
			border-top: 1px solid $light-grey;

			@include breakpoint(desktop) {
				border-top: 0;
			}

			&:not(:first-child) {
				border-left: 1px solid $light-grey;
			}
		
			.js-trigger {
				cursor: default;	
			}

			&:hover {
				color: $white;
				background-color: $orange;

				a,
				.collapsable-trigger {
					color: $white;
				}
			}
		}
		
		.country-column {
			width:25%;
			&.two-columns {
				width:50%;
			} 
		}

		.grid.language-grid {
			justify-content: flex-end;
			flex-pack: end;
		}

		.collapsable {
			padding: 1em;

			.collapsable-content {

				@include breakpoint(desktop) {
					max-height: 10000px;
				}
			}
		}
		.country-grid {
			.column:first-child {
				padding-left: $column-base-padding !important;
			}
		}
	}

	.panel-settings {
		@include leftright(text-align, text-align, left, right);

		.tab-label {
			padding-left: (0.8 * 2 * $column-base-padding);
			padding-right: (0.8 * 2 * $column-base-padding);
		}
	}

	.icons {
		z-index: 1000;
		@include flex(1);
		display: inline-block;
		@include leftright(margin-right, margin-left, calc(#{-2 * $column-base-padding} - 1px));

		&.below-overlay {
			z-index: 999;
			display: none;
		}

		@include breakpoint(desktop) {
			display: none;
		}

		.panel {
			@include breakpoint(desktop) {
				@include no-flex(block);
				position: relative;
			}

			&.panel-settings {
				display: block;

				@include breakpoint(desktop) {
					@include no-flex(none);
					display: none;
				}
			}

			&.panel-search {
				.search & {
					display: none;
				}
			}

			&.panel-download,
			&.panel-cart {
				background:transparent;
				&.toggle-on .panel-trigger span[data-bind] {
					color: $white;
    				background-color: $orange;
				}

				.panel-trigger {
					position: relative;

					span[data-bind] {
						position: absolute;
						background-color: $orange;
						display: block;
						min-width: 2em;
						width: auto;
						height: 2em;
						line-height: 2em;
						font-size: 0.75em;
						color: $white;
						font-weight: bold;
						border-radius: 1em;
						top: 0.5em;
						right: 0.5em;

						padding-left: 2px !important;
						padding-right: 2px !important;
					}
				}

				.download &,
				.cart & {
					display: none;
				}
			}

			.panel-content {
				@include breakpoint(desktop) {
					top: -1px;
					right: calc(100% + 1px);
					width: auto;
				}
			}
		}
	}

	.loggedin {
		display: none !important;
	}
	
	.loggedout {
		display: block !important;
	}

	@include breakpoint(desktop) {
		.loggedout {
			background: $orange;
			padding:5px 15px 3px;
			transition: background .5s;
		
			&:hover,
			&:active {
				background-color: lighten($orange, 15%);
			}
			
			.login {
				padding:0px !important;
				color:#fff;
			}
		}
	}
	
	.navigation .collapsable.no-arrow .collapsable-trigger:after {
		display:none;	
	}
	
	.panel-burger .tab .list-level-1 .level-1 .panel-trigger .login span:after {
		display: none;
	}
}

html[dir=rtl], header[dir=rtl] {
	.meta .panel-burger .tab .list-level-1 .level-1 {
		div.panel span {
			@include breakpoint(tablet) {
				padding-right: 1em;
			}
		}
		@include breakpoint(tablet) {
			.country-column .collapsable.meta-list,
			.panel-content .navigation-list {
				padding-left: 2.8em;
				padding-right: 3em;
			}
			.country-column .collapsable-content {
				padding-right: 1.5em;
			}
		}
	}
}

html[dir=rtl] .meta .logo {
	text-align: initial;
}