@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.teaser-list {
    & + & {
        margin-top: toMobile(40);

        @include breakpoint(desktop) {
            margin-top: toBase(40);
        }
    }

    .teaser-column + .teaser-column {
        margin-top: toMobile(40);

        @include breakpoint(desktop) {
            margin-top: 0;
        }
    }
}