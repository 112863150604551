@import 'style/config';
@import 'node_modules/sensible/mediaqueries';


.contact-card-wrapper {
	border: 1px solid $light-grey;

	.google-map {
		position: relative;
		padding-bottom: 50%; // This is the aspect ratio
		height: 0;
		overflow: hidden;
		margin: toBase(30);
		@include breakpoint(tablet) {
			margin: toMobile(30);
			margin-top: 0;
		}
		margin-top: 0;
		border: 1px solid $light-grey;
	  }
	  .google-map iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
		border: none;
	  }
}

.contact-card {
	padding: toMobile(30);
	display: inline-block;

	@include breakpoint(desktop) {
		width: 50%;
		padding: toBase(30);
		display: flex;
		>.grid {
			align-items: flex-start;
		}
	}

	* + & {
		margin-top: 1em;
	}

	.column.column-image {
		@include breakpoint(desktop) {
			flex: 0 toBase(160);
			width: toBase(160);
		}
	}
	
	.column.column-text {
		@include breakpoint(tablet) {
			padding-top: 1em;
		}
	}
	
	h4 {
		margin-top: 0;
		margin-bottom: 0;
	}

	h3 {
		margin-top: 0;
		margin-bottom: 0;
		padding-bottom: 0.5em;
		line-height: initial;

		@include breakpoint(desktop) {
			margin: 0;
		}
	}

	em {
		font-style: normal;
	}

	strong {
		display: block;
		color: $orange;
		margin: 0.75em 0;
	}

	dl {
		margin: 0.75em 0;
	}

	dt,
	dd {
		display: inline;
		margin: 0;
	}

	dd:after {
		content: "";
		width: 100%;
		display: block;
	}

	dt {
		font-weight: bold;
	}

	.contact-card-url {
		display: block;
	}

	&.with-map {
		padding-bottom: 0.5em;
	}
}