@import 'style/config';
@import 'node_modules/sensible/mediaqueries';

.contacts {
	.contacts-group {
		padding: 1em 0;

		+ .contacts-group {
			border-top: 1px dashed $light-grey;
		}
	}

	.collapsable {
		.collapsable-trigger  svg {
			float: right;
		}

		&.toggle-on,
		&:hover {
			.collapsable-trigger {
				color: $orange;

				svg {
					fill: $orange;
					transform: rotate(180deg);
				}
			}
		}
	}

	.contacts-grid {
		width: 100%;
		margin-top: 1em;
	}

	.contacts-column {
		padding-bottom: 1em;
		vertical-align: middle;
	}

	.contact-card {
		@include breakpoint(desktop) {
			width: 100%;
			margin-left: 1px;
		}
	}
}