@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.cart-entries {
	.column-remove {
		svg {
			fill: $base-font-color;
			height: 1.5em;
		}
	}
	
	.column-quantity {

		.control {
			width: 2em;
		}

		button {
			padding: 0.75em;
		}

		@include breakpoint(desktop) {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			button,
			.form-input-number {
				margin: 0.1em;
				flex-grow: 1;
				justify-content: center;
			}
		}
	}

	.cart-entries-heading {
		position: relative;

		h3 {
			margin: 0;
		}

		.with-overlay.with-cart-actions & {
			z-index: 100001;
		}
	}

	.cart-entries-heading-actions-column {
	
		position: absolute;
		right: 0px;
		top: 0px;
		text-align: right;
	
		@include breakpoint(desktop) {
			position: static;
			@include leftright(text-align, text-align, right, left);
		}

		.panel {
			border: none;
			display: inline-block;

			&.toggle-on .panel-trigger {
				color: $orange;

				svg {
					fill: $orange;
					transform: rotate(180deg);
				}
			}
		}

		.panel-trigger {
			vertical-align: top;
			background-color: $white;
			margin: 0 -1em * $mobile-desktop-factor;
			padding: 0 1em;

			@include breakpoint(desktop) {
				margin: 0 -1em;
			}

			span {
				@include leftright(padding-right, padding-left, 1.5em);
				@include fontsize($h3-font-size);
				line-height: $h3-line-height;
				display: inline-block;
			}

			svg {
				@include leftright(float, float, right, left);
				margin-top: 0.25em;
				width: 1em;
				height: 1em;
				@include leftright(margin-right, margin-left, -1.5em);
			}

			@include breakpoint(desktop) {
				&:hover {
					color: $orange;
	
					svg {
						transform: rotate(180deg);
					}
				}
			}
		}

		.panel-content {
			@include breakpoint(desktop) {
				right: 0;
			}
			left: 30px;
			width: 100%;
			padding-right: 60px;
			background-color: transparent;
			@include leftright(text-align, text-align, left, right);
		}
	}
}