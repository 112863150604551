@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

body.search .main .content {
	padding-top:0px;	
}

.search {
	@include leftright(text-align, text-align, left, right);
	
	.search-content {
		overflow: auto;
		max-height: 50vh;
	}

	.search-result-group {
		padding: toMobile(15) toMobile(20);

		@include breakpoint(desktop) {
			padding: toBase(15) toBase(20);
		}

		+ .search-result-group {
			border-top: 1px dashed $light-grey;
		}
	}

	.search-result-counter {
		a,
		span {
			color: $orange;
			font-weight: bold;
		}
		
		+ .search-result {
			margin-top: toMobile(15);

			@include breakpoint(desktop) {
				margin-top: toBase(15);
			}
		}
	}
	
	.search-result {

		&.no-result {
			font-style: italic;
			text-align: center;
		}

		em {
			font-style: normal;
			font-weight: bold;
			color: $orange;
		}

		h3 {
			margin: 0;

			a {
				color: $base-font-color;
			}
		}
		a h3 {
			color: $base-font-color;
		}

		p {
			margin: 0;
		}

		+ .search-result {
			margin-top: toMobile(15);

			@include breakpoint(desktop) {
				margin-top: toBase(15);
			}
		}
	}

	.toggle-on & {
		@include breakpoint(desktop) {
			width: toBase(625);
		}
	}
}