@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.search-field {
	margin-left: -2 * $column-base-padding;
	margin-right: -2 * $column-base-padding;
	padding: 2 * $column-base-padding;
	background-color: $light-grey;
	position: relative;
	margin-bottom: toBase(-40);

	@include breakpoint(desktop) {
		margin-left: toBase(-40);
		margin-right: toBase(-40);
		padding: toBase(20) 0;
		text-align: center;
	}

	input {
		width: 100%;
		border: 1px solid $mid-grey;
		outline: none;
		padding-right: 100px;

		@include breakpoint(desktop) {
			max-width: toBase(820);
			height: toBase(45);
			padding-right: 120px;
		}

		&::-ms-clear {
			display: none;
		}


		&:valid + .search-field-clear{
			display: block;
		}
	}

	.search-field-clear {
		display: none;
		position: absolute;
		width: 2em;
		top: 2 * $column-base-padding + 0.25em;
		right: 2 * $column-base-padding + 4em;
		cursor: pointer;
		z-index: 1;
		padding: 0.25em;

		@include breakpoint(desktop) {
			padding: 0.5em;
			top: toBase(20);
			height: toBase(45);
			width: toBase(45);
			right: toBase(170);
		}

		svg {
			fill: $light-grey;
			height: 100%;
		}
	}

	.search-field-submit {
		position: absolute;
		background-color: $orange;
		padding: 0.5em 1em;
		top: 2 * $column-base-padding;
		right: 2 * $column-base-padding;
		cursor: pointer;
		height: 2.5em;
		width: 4em;
		z-index: 1;

		@include breakpoint(desktop) {
			top: toBase(20);
			right: toBase(100);
			height: toBase(45);
			padding: 0.75em 1.5em;
			width: toBase(65);
		}

		svg {
			fill: $white;
			height: 100%;
		}
	}
}

.search-widget { 

	.search-field-submit {
		top: 5px;
		right: 5px;
		
		@include breakpoint(desktop) {
		
			background-color: transparent;
			
			top: 0px;
			height: calc(100% - 2px);
			right: 1px;
			padding: 0.75em 1em 0.75em 1em;
			width: 3.5em;
			
			svg path {
				fill: $mid-grey;
			}	
			
			&:hover {
				background-color: $orange;
				
				svg path {
					fill: #fff;
				}	
			}
		}
	}
	
	input {		
		padding: 0.5em 4em 0.5em 1em;
		@include breakpoint(desktop) {
			padding: 0.5em 4em 0.5em 3em;
		}
	}
	
	input:focus + .search-field-submit {
		background-color: $orange;
				
		svg path {
			fill: #fff;
		}	
	}
}

.level-1.icons {
	.search-show-toggle {
		.search-open-close {
			display: none;
		}
	}
}

@include breakpoint(desktop) {

	.navigation {
		nav {
			height: 100%;
			ul {
				&.list-level-1 {
					height: 100%;
					li {
						&.link-component {
							height: 100%;
							
							.panel-trigger {
								&.js-trigger {
									@include leftright(padding-left, padding-right, 0.5em);
								}
							}
							
						}
						&.level-1.icons {
							height: 100%;
							.search-show-toggle {
								display: inline-block;
								position: relative;
								max-height: 100%;
								margin-top: 13px;
								
								&.open {
									border: 1px solid #bcbdc0;
								}
								min-width: 73px;
								min-height: 28px;
								
								.search-opened {
									display: none;
									height: 100%;

									.search-field {
										background-color: transparent;
										input {
											width: 570px;
										}
										button {
											transform: translateX(-547px);
											height: calc(100% + 2px);
											margin-top:-1px;
											svg {
												background-color: transparent;
												height: 21px;
												width: 21px;
											}
											&:hover {
												background-color: transparent;
												svg path {
													fill: $orange;
												}
											}
										}
										input:focus + .search-field-submit {
											background-color: $orange;
											svg path {
												fill: #fff;
											}
										}
									}
								}
								.search-open-close {
									display: inline-block;
									position: absolute;
									right: 0px;
									top: 0px;

									button {
										display: inline-block;
										position: relative;
										height: 100%;
										margin-left: 2em;
										margin-right: 1em;

										background-color: $white;

										svg {
											height: 21px;
											width: 21px;
											path {
												fill: $dark-grey;
											}
										}

										&:hover {
											svg path {
												fill: $orange;
											}
										}
										&.search-close {
											display: none;
											margin-left: 1em;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
html[dir=rtl], header[dir=rtl] {
	.search-widget {
		input {
			@include breakpoint(tablet) {
				padding: 0.0em 6em 0.0em 0em;
			}
		}
	}
	@include breakpoint(desktop) {
		.navigation {
			.list-level-1 {
				.level-1.icons {
					.search-show-toggle {
						&.open {
							margin-left: 2em;
						}
						.search-open-close {
							right: -1em;
						}
					}
					.search-show-toggle.open {
						margin-left: 2em;
					}
				}
			}
		}
	}
}
