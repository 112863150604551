.form-select {
	padding: 0.5em 0;

	label {
		
	}

	select {
		width: 100%;
	}

	+ .form-info,
	+ .form-error {
		margin-top: calc(-1 * (0.5em + 1px));

		.message {
			border-top: 0;
		}
	}
}