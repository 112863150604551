@use 'sass:math';
@import 'style/config';
@import 'style/base/10.mixins';
@import "node_modules/sensible/mediaqueries";

.teaser-application {
	
	a {
		text-decoration: none;	
		color: $base-font-color;
	}

	.teaser-application-title {
		
		em {
			font-style: normal;
			display: block;
			font-size: toEm($mobile, $h3);
			line-height: $base-line-height;
			color: $orange;

			@include breakpoint(desktop) {
				font-size: toEm($base, $h3);
			}
		}

		margin: #{toMobile(20) - 0.1em - math.div(($base-line-height - 1em), 2)} 0 0;

		@include breakpoint(desktop) {
			margin: 0;
		}
	}

	p {
		margin: 0;
	}
}