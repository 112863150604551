@import 'style/config';

.accessories-collapsible {
  .search-result-product {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .accessories-collapsible-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    padding-top: 1.53846em;
  }
  &:first-of-type {
    .accessories-collapsible-header {
      padding-top: 0em;
    }
  }

  .accessories-collapsible-header-button {
    text-align: center;
    width: 15px;
    height: 15px;
    transform: translateX(-50%);
    svg {
      width: 15px;
      height: 15px;

      path {
        fill: $black;
      }
    }
  }
  .accessories-collapsible-header-button-active {
    svg {
      transform: rotate(180deg);
      path {
        fill: $orange;
      }
    }
  }
  .accessories-collapsible-content {
    display: none;
    padding-bottom: 1.53846em;
  }
}
.accessories-collapsible-border-bottom {
  &:not(:last-of-type) {
    border-bottom: 1px solid #bcbdc0;
  }
}
