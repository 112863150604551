@import 'style/config';

.info-msgs-wrapper {
  display: none;
}

.info-msgs-content {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .info-icon {
    min-width: 40px;
    min-height: 40px;
    max-height: 40px;
    max-width: 40px;
    svg {
      min-width: 40px;
      min-height: 40px;
      max-height: 40px;
      max-width: 40px;
    }
    &.info-icon-error {
      svg {
        fill: $red;
      }
    }
    &.info-icon-info {
      svg {
        fill: $shaded-blue;
      }
    }
  }

  .info-msgs-view {
    display: flex;
    justify-content: flex-start;
    padding-left: 2em;
    .info-msgs {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      font-weight: bold;
      list-style-type: none;
      list-style-image: none;
      margin: 0;
      padding: 0;
      li:not(:last-of-type) {
        padding-bottom: 0.3em;
      }
    }
  }
}


