.editMode {
	.navigation {
		.mgnlEditorBar.area,
		.mgnlEditorBar.end {
			display:none;
		}
		
		.mgnlEditorBar.focus + nav,
		.mgnlEditorBar.childFocus + nav {
			margin-top: 0px;
		}	
	
		.mgnlEditor .status-indicator {
		    display: none;
		}
	}
	
	.tab {
		> .mgnlEditorBar.area,
		> .mgnlEditorBar.end {
			display:none;
		}		
	}

	div.mgnlEditor {
		width: 100% !important;
	}

	.grid.mgnl-component {
		flex-wrap: wrap;	
	}

	div.mgnlEditorBar.area.mgnlEditorBarStatusIndicator, div.mgnlEditorBar.component.mgnlEditorBarStatusIndicator {
    	margin-left: 0px !important;
	}

	.form-input-hp {
		position: static;
	}

	.redirect-page {
		background-color: $shaded-blue;
		color: #ffffff;
		display: none;
		position: relative;
		text-align: center;
		text-transform: uppercase;
		padding-top: 30px;
		padding-bottom: 30px;
	
		p {
			font-size: 12px;
			line-height: 12px;
			margin: 0;
			a {
				color:#ffffff;
			}
		}


	}
}
