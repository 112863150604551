@import 'style/config';
@import "10.mixins";

a {
	color: $shaded-blue;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

p {
	@include margins(0.875em);
}

span.orange {
	color: $orange;
}

ul {
	list-style-image: url('data:image/svg+xml,#{listDot($base-font-color)}');
}

li.orange {
	list-style-image: url('data:image/svg+xml,#{listDot($orange)}');
}


