@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.social-links-trigger {
    display: inline-block;
    
    a {
        font-weight: bold;
    }

    svg {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        fill: $shaded-blue;
		vertical-align: bottom;
    }
}