@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.column {
	width: 100%;
	padding-left: 2 * $column-base-padding;
	padding-right: 2 * $column-base-padding;

	@include breakpoint(desktop) {
		@include flex();
		padding-left:  $column-base-padding;
		padding-right: $column-base-padding;
	}

	.horizontal-scroll > .grid > & {
		@include flex(1);
	}

	:not(.break-grid) > .grid,
	> .grid {
		margin-left: -2 * $column-base-padding;
		margin-right: -2 * $column-base-padding;

		@include breakpoint(desktop) {
			margin-left:  -$column-base-padding;
			margin-right: -$column-base-padding;
		}
	}

	&.half {
		@include breakpoint(desktop) {
			@include column("half");
			@include flex();
		}
	}

	&.one-third {
		@include breakpoint(desktop) {
			@include column("third");
		}
	}

	&.two-third {
		@include breakpoint(desktop) {
			@include column("third", 2);

			@include flex();
		}
	}

	&.one-fourth {
		@include breakpoint(desktop) {
			@include column("fourth");
			@include flex();
		}
	}

	&.three-fourth {
		@include breakpoint(desktop) {
			@include column("fourth", 3);
			@include flex();
		}
	}

	&.balanced {
		@include breakpoint(desktop) {
			width: auto;
			@include flex(1);
		}
	}

	&.skip-half {
		@include breakpoint(desktop) {
			@include skipColumn("half");
		}
	}

	&.skip-one-third {
		@include breakpoint(desktop) {
			@include skipColumn("third");
		}
	}

	&.skip-two-third {
		@include breakpoint(desktop) {
			@include skipColumn("third", 2);
		}
	}

	&.skip-one-fourth {
		@include breakpoint(desktop) {
			@include skipColumn("fourth");
		}
	}

	&.skip-three-fourth {
		@include breakpoint(desktop) {
			@include skipColumn("fourth", 3);
		}
	}
}