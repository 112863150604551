.joblist {
  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;

    @include breakpoint(not-mobile) {
      display: grid;
      grid-gap: 5%;
      grid-template-columns: 40% 55%;
    }

    @include breakpoint(desktop) {
      grid-template-columns: 30% 65%;
    }
  }

  &__results {
    margin-top: 0.5rem;
  }

  &__item {
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px dashed $dark-grey;

    h3 {
      line-height: 1.5rem;
      margin-bottom: 0;
    }

    a {
      color: $orange;
      font-weight: 400;
    }

    a:hover {
      color: $black;
      text-decoration: none;
    }

    p {
      margin-top: 0.25rem;
    }
  }

  &-filters {
    &__toggle {
      display: block;
      margin-bottom: 1rem;

      @include breakpoint(not-mobile) {
        display: none;
      }
    }

    &__list {
      @include breakpoint(not-mobile) {
        display: block !important;
      }
    }
  }
}
