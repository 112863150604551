@use 'sass:math';
@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

$input-width: 16;
$input-dot-width: 8;

.form-input-checkbox {
	padding: 0.5em 0;

	&.horizontal {
		@include breakpoint(desktop) {
			display: inline-block;

			& + & {
				@include leftright(margin-left, margin-right, 1em);
			}
		}
	}

	label {
		display: block;
		position: relative;
		overflow: hidden;
		
		.count {
			float: right;
			font-style: italic;
			padding: 0px !important;
			
			& ~ span {
				max-width: 135px;
				overflow: hidden;
				text-overflow: ellipsis;
				@include breakpoint(tablet) {
					max-width: 600px;
				}
				@include breakpoint(mobile) {
					max-width: 350px;
				}
				@include breakpoint(mobile-portrait) {
					max-width: 240px;
				}
			}
		}

		span {
			display: inline-block;
			@include leftright(margin-left, margin-right, 0.25em);
			vertical-align: middle;
			@include leftright(padding-left, padding-right, 1.5em);
			
			&.secondLevelSpan {
				@include leftright(margin-left, margin-right, 0.75em);
			}

			&:before {
				content: "";
				display: block;
				position: absolute;
				top: #{toMobile(math.div(($input-width - $input-dot-width), 2))};
				@include leftright(left, right, toMobile(math.div(($input-width - $input-dot-width), 2)));
				width: toMobile($input-dot-width);
				height: toMobile($input-dot-width);

				@include breakpoint(desktop) {
					top: #{toBase(math.div(($input-width - $input-dot-width), 2))};
					@include leftright(left, right, toBase(math.div(($input-width - $input-dot-width), 2)));
					width: toBase($input-dot-width);
					height: toBase($input-dot-width);
				}				
			}
			&.secondLevelSpan:before {
				@include leftright(left, right, toMobile(math.div(($input-width - $input-dot-width), 2)) + 0.5);
				@include breakpoint(desktop) {
					@include leftright(left, right, toBase(math.div(($input-width - $input-dot-width), 2)) + 0.5);
				}
			}

			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				@include leftright(left, right, 0);
				width: toMobile($input-width);
				height: toMobile($input-width);
				border: 1px solid $input-border-color;

				@include breakpoint(desktop) {
					width: toBase($input-width);
					height: toBase($input-width);
				}
			}
			&.secondLevelSpan:after {
				@include leftright(left, right, 0.5em);
			}
		}
		span.facet-nav-overflow-handling:hover,
		span.pivot-overflow-handling:hover {
			overflow: visible;
			text-overflow: initial;
			position: relative;
		}
		span.facet-nav-overflow-handling:hover:after,
		span.pivot-overflow-handling:hover:after {
			display: none !important;
		}
		span.facet-nav-overflow-handling:hover:before,
		span.pivot-overflow-handling:hover:before {
			display: none !important;
		}

	}

	input {
		visibility: hidden;
		position: absolute;
		@include leftright(left, right, -200vw);

		&:checked + input + span:before,
		&:checked + span:before {
			background-color: $black;
		}

		&[disabled] + input + span,
		&[disabled] + span {
			font-style: italic;
			color: $mid-grey;

			&:before {
				background-color: $mid-grey;
			}

			&:after {
				border-color: $mid-grey;
			}
		}
	}

	&.error {
		label {
			&:before {
				border-color: $red;
			}

			span:before {
				color: $red;
			}
		}
	}
}