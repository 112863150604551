@import 'style/config';
@import 'style/base/10.mixins';
@import "node_modules/sensible/mediaqueries";

.text-image {
	@include margins(toMobile($mobile));

	@include breakpoint(desktop) {
		@include margins(toBase($base));
	}

	&.top .text,
	&.right .image,
	&.bottom .image,
	&.left .text {
		margin-top: toMobile($mobile);
	}

	&.right .image,
	&.left .text {
		@include breakpoint(desktop) {
			margin-top: 0;
		}
	}

	&.left,
	&.right {
		.text > ul {
			margin-top: 0;
		}
	}

	&.bottom .image,
	&.top .text {
		@include breakpoint(desktop) {
			margin-top: toBase($base);
		}
	}
	
	&.above .image {	
		@include breakpoint(desktop) {
			margin-bottom: toBase($base);
		}
	}
	
	h3 {
		margin: toEm(10, $h3) 0 toEm(10, $h3);
	}

	.video {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.link-list ul {
		margin-bottom: 0;
		list-style-image: url(data:0);

		li {
			list-style-image: url(data:0);;
		}
	}


	@include breakpoint(tablet) {

		table.responsive {
			display: block;
			width: initial !important;
			> tbody tr {
				display: flex;
				flex-direction: column;
				> td:not(:last-child) {
					padding-bottom: 1.5em;
				}
			}
		}
		.text {
			overflow-x: auto;
		}
	}

}