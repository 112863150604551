@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

html {
	box-sizing: border-box;
	min-height: 100%;
	margin: 0;
	padding: 0;
	@include breakpoint(tablet) {
		overflow-x: hidden;
	}
	
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

.clearfix::after {
	content: " ";
	display: table;
	clear: both;
}

.left-align-to-page {
	margin-left: calc(50% - 50vw);
}

.right-align-to-page {
	margin-right: calc(50% - 50vw);
}

.align-to-page {
	@extend .left-align-to-page,
		.right-align-to-page;
}

.with-background {
	background-color: $background-color;
}

.with-border-top,
.following-border-top + * {
	border-top: 1px solid $mid-grey;
}

.with-border-left,
.following-border-left + * {
	border-left: 1px solid $mid-grey;
}

.above-overlay {
	z-index: 100001;
}

.flex-list-wrapper {
	display: inline-block;
	width: 100%;
	vertical-align: middle;
}

body {
	font-family: Arial, "Microsoft Yahei","微软雅黑", "Hiragino Sans GB", "冬青黑体", STXihei, "华文细黑", "YuMincho", "を指定", sans-serif;
	background-color: $white;
	color: $base-font-color;
	line-height: $base-line-height;
	overflow-x: hidden;
	width: 100%;
	margin:0;
	padding:0;
	min-height: 100vh;
	position: relative !important;
	left: 0px !important;
	//padding-bottom: toMobile(200);
	display: flex;
	flex-direction: column;

	@include breakpoint(desktop) {
		background-color: $page-color;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=);
		background-position: center;
		background-repeat: repeat-y;
		background-size: $grid-max-width;
		font-size: $base-font-size;
		//overflow-y: scroll;
		//padding-bottom: toBase(85);
	}
}

@include breakpoint(tablet) {
	.hideOnMobile {
		display:none;
	}
}

.header-main-footer-layout {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: space-between;
}
