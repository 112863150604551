@import 'style/config';

.vertical-align {
	@include flexbox();

	align-items: center;
	height: 100%;
	vertical-align: middle;

	.vertical-align-content {
		width: 100%;
		
		// @include without-flexbox(){
		// 	display: table-cell;
		// 	vertical-align: middle;
		// }
	}
}