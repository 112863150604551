@import 'style/config';
@import 'node_modules/sensible/mediaqueries';

.google-map-wrapper {

	.google-map {
		position: relative;
		padding-bottom: 75%; // This is the aspect ratio
		overflow: hidden;
		@include breakpoint(tablet) {
		}
		border: 1px solid $light-grey;
	  }
	  .google-map iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
		border: none;
	  }
}