.logo {
	display: inline-block;
	height: toMobile(60);
	padding: toMobile(11) 0;

	@include breakpoint(desktop) {
		
		padding: toBase(12.5) 0;
		height: toBase(70);
	}

	a {
		display: inline-block;
		height: 100%;

		img {
			height: 100%;
		}
	}
}