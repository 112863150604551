.joblistform {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include breakpoint(not-mobile) {
    border: 2px solid $dark-grey;
    flex-direction: row;
  }

  input,
  select {
    width: 100%;
    border: 0;
  }

  input {
    padding-left: 3rem;
    padding-right: 2rem;
    height: 3.3em;
    outline: none;
  }

  select {
    height: 2.5rem;
  }

  &__item {
    &--search {
      flex: 1;
      position: relative;

      @include breakpoint(mobile) {
        border: 2px solid $dark-grey;
        width: 100%;
        margin-top: 1rem;
      }
    }

    &--country {
      width: 30%;
      border-left: 2px solid $orange;
      border-right: 2px solid $orange;

      @include breakpoint(mobile) {
        border: 2px solid $dark-grey;
        width: 100%;
        margin-top: 1rem;
      }
    }

    &--button {
      width: 11rem;
      display: flex;
      align-self: stretch;

      @include breakpoint(mobile) {
        align-self: end;
        margin-top: 1rem;
      }

      button {
        width: 100%;
        background-color: $orange;
        height: unset;
      }
    }
  }

  &__item-icon {
    display: block;
    position: absolute;
    top: 0.9rem;
    width: 1.5rem;
    left: 1rem;

    @media screen and (min-width: 1020px) {
      top: 0.6rem;
    }

    &--close {
      left: auto;
      right: 0.5rem;
    }

    svg {
      width: 100%;
    }
  }

  .panel {
    position: relative;

    &.toggle-on {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .panel-trigger {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .panel-content {
    z-index: 1;
    width: calc(100% + 6px);
    border: 2px solid $dark-grey;
    margin-left: -3px;

    ul {
      padding: 0;
      width: 100%;
      margin: 0;
    }

    li {
      list-style: none;
      display: grid;
      grid-template-columns: 10% 85%;
      grid-gap: 5%;
      padding: 0.5rem;
    }
  }
}
