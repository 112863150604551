@import 'style/config';

// my heart bleeds, bye semantics :'(
button:not([class^=Cybot]),
a.fake-button,
span.fake-button {
	display: inline-block;
	border: 0;
	cursor: pointer;
	color: $white;
	background-color: $shaded-blue;
	padding: 0.75em 1em;
	transition: background .5s;
	height: 2.5em;
	line-height: 1em;

	&:hover,
	&:active {
		text-decoration: none;
		background-color: lighten($shaded-blue, 15%);
	}

	&.variable-height {
		height: auto;
		min-height: 2.5em;
		text-align: left;
	}
}

button[type=reset],
button.inverse,
a.fake-button.inverse {
	cursor: pointer;
	background-color: $white;
	color: $shaded-blue;
}

button.fake-text {
	background-color:transparent;
	color: $shaded-blue;
	padding: 0;
	height:auto;
	cursor: pointer;
}

button:disabled {
	cursor: default;
	opacity: 0.5;
	
	&:hover,
	&:active {
		background-color: $shaded-blue;
	}
}
