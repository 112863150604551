@import 'style/config';
@import 'style/base/10.mixins';
@import 'node_modules/sensible/mediaqueries';

.message-success {
	border: 1px solid $green;
	background: rgba($green, 0.15);

	svg {
		fill: $green;
	}
}