@use 'sass:math';
@import 'style/config';
@import 'node_modules/sensible/mediaqueries';

$input-width: 16;
$input-dot-width: 8;

.form-input-radio {
	padding: 0.5em 0;

	&.horizontal {
		@include breakpoint(desktop) {
			display: inline-block;

			& + & {
				@include leftright(margin-left, margin-right, 1em);
			}
		}
	}

	label {
		position: relative;
		display: inline-block;

		span {
			display: inline-block;
			@include leftright(margin-left, margin-right, 0.25em);
			vertical-align: middle;
			@include leftright(padding-left, padding-right, 1.5em);

			&:before {
				content: "";
				display: block;
				position: absolute;
				top: #{toMobile(math.div(($input-width - $input-dot-width), 2))};
				@include leftright(left, right, toMobile(math.div(($input-width - $input-dot-width), 2)));
				width: toMobile($input-dot-width);
				height: toMobile($input-dot-width);
				border-radius: toMobile(math.div($input-dot-width, 2));

				@include breakpoint(desktop) {
					top: #{toBase(math.div(($input-width - $input-dot-width), 2))};
					@include leftright(left, right, toBase(math.div(($input-width - $input-dot-width), 2)));
					width: toBase($input-dot-width);
					height: toBase($input-dot-width);
					border-radius: toBase(math.div($input-dot-width, 2));
				}
			}

			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				@include leftright(left, right, 0);
				width: toMobile($input-width);
				height: toMobile($input-width);
				border: 1px solid $input-border-color;
				border-radius: toMobile(math.div($input-width, 2));

				@include breakpoint(desktop) {
					width: toBase($input-width);
					height: toBase($input-width);
					border-radius: toBase(math.div($input-width, 2));
				}
			}
		}
	}

	input {
		visibility: hidden;
		position: absolute;
		@include leftright(left, right, -200vw);

		&:checked + span:before {
			background-color: $black;
		}

		&[disabled] + span {
			font-style: italic;
			color: $mid-grey;

			&:before {
				background-color: $mid-grey;
			}

			&:after {
				border-color: $mid-grey;
			}
		}
	}
}